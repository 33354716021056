import { Box, Button, Grid, Typography, IconButton, Dialog, DialogContent, DialogTitle, DialogActions, FormControl, InputLabel, Select, MenuItem, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import axios from "axios";
import { useState, useEffect } from "react";
import { API_URL, IMG_URL } from "../Components/Constants";
import { Close, Add } from "@mui/icons-material";
import TextfieldNormal from "../Components/TextfieldNormal";
import ImageCapture from "./ImageCapture";
import ImageCaptureModal from "../Components/ImageCaptureModal";
import { useMediaQuery, useTheme } from "@mui/material";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Colors from "../Components/Colors";
import React from "react";



const ScannedImages = () => {
    const [groupedImages, setGroupedImages] = useState({}); // Store images grouped by image_id
    const [selectedImages, setSelectedImages] = useState([]); // Images of the clicked row
    const [selectedIndex, setSelectedIndex] = useState(0); // Current image index
    const [open, setOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [sortOption, setSortOption] = useState("");
    const [openCameraModal, setOpenCameraModal] = useState(false);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [promotionalImageId, setPromotionalImageId] = useState();
    const [imageId, setImageId] = useState();
    const [statuses, setStatuses] = useState({});
    const [comments, setComments] = useState({});
    const handleCommentChange = (imageId, value) => {
        setComments(prev => ({
            ...prev,
            [imageId]: value
        }));
    };

    const handleStatusChange = (imageId, value) => {
        setStatuses(prev => ({
            ...prev,
            [imageId]: value
        }));
    };
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down("sm"));


    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleSortChange = (event) => {
        setSortOption(event.target.value);
        // Apply sorting logic if needed
    };

    // Filter and sort images based on searchQuery and sortOption
    const filteredImages = Object.entries(groupedImages)
    .filter(([image_id, images]) =>
        image_id.includes(searchQuery) // Filter by image_id (modify as per your requirement)
    )
    .sort(([idA, imagesA], [idB, imagesB]) => {
        const statusA = statuses[idA] || "pending";
        const statusB = statuses[idB] || "pending";

        if (sortOption === "status") {
            // Sort by status: "pending" first, "completed" last
            if (statusA === "completed" && statusB !== "completed") return 1;
            if (statusA !== "completed" && statusB === "completed") return -1;
        }

        if (sortOption === "date") {
            // Sort by date (newest first)
            return new Date(imagesB[0]?.created_at) - new Date(imagesA[0]?.created_at);
        }

        return 0; // Default sorting
    });


    useEffect(() => {
        const fetchPromotionalImages = async () => {
            try {
                const response = await axios.get(`${API_URL}/get_promotional_images`);
                if (response.data.success && Array.isArray(response.data.data)) {
                    // Group images by image_id
                    const grouped = response.data.data.reduce((acc, img) => {
                        acc[img.image_id] = acc[img.image_id] || [];
                        acc[img.image_id].push(img);
                        return acc;
                    }, {});
                    setGroupedImages(grouped); const initialStatuses = response.data.data.reduce((acc, img) => {
                        acc[img.image_id] = img.status || "pending"; // Default to "pending" if no status
                        return acc;
                    }, {});
                    console.log("initialStatuses", initialStatuses)
                    setStatuses(initialStatuses);
                } else {
                    console.error("Unexpected API response format:", response.data);
                    setGroupedImages({});
                    setStatuses({});
                }
            } catch (error) {
                console.error("Error fetching promotional images:", error);
                setGroupedImages({});
            }
        };

        fetchPromotionalImages();
    }, []);


    // const handleConfirmDelete = async () => {
    //     if (selectedImage) {
    //         await handleDeleteImage(selectedImage.image_id, selectedImage.image_url);
    //         handleConfirmClose();
    //     }
    // };
    const handleConfirmDelete = async () => {
        console.log(promotionalImageId)
        try {
            // Send DELETE request to backend
            await axios.delete(`${API_URL}/delete_image/${promotionalImageId}`);
            setGroupedImages((prevImages) => {
                // Create a new copy of the grouped images object
                const updatedImages = { ...prevImages };

                if (updatedImages[imageId]) {
                    // Filter out the deleted image
                    updatedImages[imageId] = updatedImages[imageId].filter(
                        (img) => img.promotional_img_id !== promotionalImageId
                    );

                    // If no images remain in the group, remove the group itself
                    if (updatedImages[imageId].length === 0) {
                        delete updatedImages[imageId];
                    }
                }

                return { ...updatedImages }; // Ensure new reference is created
            });
            handleCloseConfirmDelete();
        } catch (error) {
            console.error("Error deleting image:", error);
        }
    };

    const handleOpenCameraModal = (images) => {
        setOpenCameraModal(true)
        setImageId(images.image_id)
    }
    const handleCloseCameraModal = () => {
        setOpenCameraModal(false);
    };
    const handleCloseConfirmDelete = () => {
        setOpenConfirmDelete(false);
    };
    const handleOpenConfirmDelete = (img) => {
        console.log("img", img)
        setImageId(img.image_id)
        setPromotionalImageId(img.promotional_img_id)
        setOpenConfirmDelete(true);
    };
    // Open modal with images from the clicked row
    const handleOpen = (rowImages) => {
        setSelectedImages(rowImages);
        setSelectedIndex(0);
        setOpen(true);
    };

    // Close modal
    const handleClose = () => {
        setOpen(false);
    };

    // Navigate to previous image
    const handlePrev = () => {
        setSelectedIndex((prevIndex) => (prevIndex === 0 ? selectedImages.length - 1 : prevIndex - 1));
    };

    // Navigate to next image
    const handleNext = () => {
        setSelectedIndex((prevIndex) => (prevIndex === selectedImages.length - 1 ? 0 : prevIndex + 1));
    };

    // Click on a thumbnail to set as main image
    const handleThumbnailClick = (index) => {
        setSelectedIndex(index);
    };

    return (

        <Box sx={{ width: "96%", margin: "auto", padding: "2px" }}>
            {/* Top Section: Search & Sort */}
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <TextfieldNormal
                    label="Search"
                    variant="outlined"
                    size="small"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    sx={{ width: "250px", height: 40 }} // Ensuring same height
                />
                <FormControl size="small" sx={{ width: "100px", height: 40 }}>
                    <Select
                        value={sortOption}
                        onChange={(e) => setSortOption(e.target.value)}
                        displayEmpty
                        sx={{
                            height: "40px", // Match height
                            "& .MuiOutlinedInput-notchedOutline": { border: "none" }, // Removes border
                            "& .MuiSelect-select": { padding: "8px 14px" }, // Adjust padding to align text
                        }}
                    >
                        <MenuItem value="" disabled>
                            Sort By
                        </MenuItem>
                        <MenuItem value="date">Date</MenuItem>
                        <MenuItem value="status">Status</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            {/* Image Grid */}
            <TableContainer component={Paper} sx={{ width: "100%", mt: 2 }}>
                <Table sx={{ tableLayout: "auto", width: "100%" }}>
                    <TableBody>
                        {filteredImages.map(([image_id, images]) => {
                            // Get the current status, default to 'pending' if not found
                            const rowStatus = statuses[image_id] || "pending";

                            // Log the status for debugging purposes
                            console.log(`Status for image ${image_id}: ${rowStatus}`);

                            const displayedImages = images.slice(0, isXs ? 2 : 4); // Show max 4 images per row

                            return (
                                <TableRow
                                    key={image_id}
                                    sx={{
                                        backgroundColor: rowStatus === "completed" ? "#f0f0f0" : "#fff", // Set grey background if completed
                                        borderBottom: "1px solid #ddd"
                                    }}
                                >
                                    {/* Image Column */}
                                    <TableCell
                                        sx={{
                                            width: { xs: "40%", sm: "70%" },
                                            padding: "8px",
                                            borderBottom: "none",
                                            display: "flex",
                                            flexWrap: "nowrap",
                                        }}
                                    >
                                        {displayedImages.map((img, index) => (
                                            <div
                                                key={index}
                                                style={{
                                                    position: "relative",
                                                    display: "inline-block",
                                                    marginRight: 8,
                                                    cursor: "pointer",
                                                }}
                                                onMouseEnter={(e) => (e.currentTarget.querySelector(".close-btn").style.visibility = "visible")}
                                                onMouseLeave={(e) => (e.currentTarget.querySelector(".close-btn").style.visibility = "hidden")}
                                            >
                                                <Box
                                                    component="img"
                                                    src={`${IMG_URL}${img.image_url}`}
                                                    alt="Scanned"
                                                    sx={{ width: { xs: 54, sm: 80 }, height: "auto", borderRadius: 2 }}
                                                />
                                                <IconButton
                                                    className="close-btn"
                                                    onClick={() => handleOpenConfirmDelete(img)}
                                                    sx={{
                                                        position: "absolute",
                                                        top: 2,
                                                        right: 2,
                                                        backgroundColor: "rgba(255, 255, 255, 0.8)",
                                                        "&:hover": { backgroundColor: "rgba(255, 0, 0, 0.8)" },
                                                        visibility: "hidden",
                                                        transition: "visibility 0.2s ease-in-out",
                                                        width: "20px",
                                                        height: "20px",
                                                        padding: "2px",
                                                    }}
                                                >
                                                    <Close fontSize="small" />
                                                </IconButton>
                                            </div>
                                        ))}
                                        {/* Add More Images Button */}
                                        <IconButton
                                            onClick={() => handleOpenCameraModal(images)}
                                            sx={{
                                                backgroundColor: "#f0f0f0",
                                                borderRadius: "50%",
                                                width: 30,
                                                height: 30,
                                                "&:hover": { backgroundColor: "#d9d9d9" },
                                            }}
                                        >
                                            <Add />
                                        </IconButton>
                                    </TableCell>

                                    {/* Date Column */}
                                    <TableCell
                                        sx={{
                                            width: { xs: "10%", sm: "20%" },
                                            padding: "6px",
                                            borderBottom: "none",
                                            fontSize: { xs: "0.75rem", sm: "1rem" },
                                        }}
                                    >
                                        <Typography variant="body2" color="textSecondary">
                                            {new Date(images[0]?.created_at).toLocaleString()}
                                        </Typography>
                                    </TableCell>

                                    {/* Status Column */}
                                    <TableCell
                                        sx={{
                                            width: { xs: "20%", sm: "15%" },
                                            padding: "6px",
                                            borderBottom: "none",
                                        }}
                                    >
                                        <Typography variant="body2" color={rowStatus === "completed" ? "textSecondary" : "primary"}>
                                            {rowStatus === "completed" ? "Completed" : "Pending"}
                                        </Typography>
                                    </TableCell>

                                    {/* Actions Column */}
                                    <TableCell
                                        sx={{
                                            width: { xs: "10%", sm: "10%" },
                                            padding: "6px",
                                            borderBottom: "none",
                                        }}
                                    >
                                        <Button
                                            sx={{
                                                width: { xs: "60px", md: "80px", sm: "80px" },
                                                fontSize: "12px",
                                                padding: { md: "6px", sm: "6px", xs: "4px" },
                                            }}
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleOpen(images)}
                                        >
                                            View
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}

                    </TableBody>
                </Table>
            </TableContainer>



            {/* Confirmation Dialog for Image Deletion */}
            <Dialog open={openConfirmDelete} onClose={handleCloseConfirmDelete}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this image?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirmDelete} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            { /*camera modal*/}
            <Dialog open={openCameraModal} onClose={handleCloseCameraModal}>
                <DialogTitle></DialogTitle>
                <DialogContent>
                    <ImageCaptureModal imageId={imageId} ></ImageCaptureModal>
                </DialogContent>

            </Dialog>


            {/* Image Modal */}
            {/* <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>

                <Box
                    sx={{
                        position: "relative", // Allow positioning of the close button and other content
                        width: "100%",
                        maxWidth: "500px",
                        backgroundColor: "white",
                    }}
                >

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            height: "40px", // Height of the header box
                            padding: "8px", // Space for the close button
                            backgroundColor: "white", // Background of the header
                            position: "absolute", // Position on top of the dialog
                            top: 0,
                            left: 0,
                            right: 0,
                            zIndex: 2, // Make sure it's on top of the content
                        }}
                    >
                        <IconButton
                            onClick={handleClose}
                            sx={{
                                color: "black",
                            }}
                        >
                            <Close />
                        </IconButton>
                    </Box>


                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "relative",
                            paddingTop: "40px", // Adds space for the close button header
                            backgroundColor: "white",
                        }}
                    >

                        <IconButton
                            onClick={handlePrev}
                            sx={{
                                position: "absolute",
                                left: 10,
                                top: "50%",
                                transform: "translateY(-50%)",
                                zIndex: 2,
                                color: "blue",
                            }}
                        >
                            <ArrowBackIos />
                        </IconButton>


                        <Box
                            sx={{
                                width: "100%",
                                maxWidth: "600px",
                                height: "auto",
                                maxHeight: "80vh",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                overflow: "hidden",
                            }}
                        >
                            <img
                                key={selectedIndex}
                                src={`${IMG_URL}${selectedImages[selectedIndex]?.image_url}`}
                                alt="Main Scanned"
                                style={{
                                    width: "100%",
                                    height: "auto",
                                    maxHeight: "70vh",
                                    objectFit: "contain",
                                    display: "block",
                                    transition: "opacity 0.3s ease-in-out",
                                }}
                            />
                        </Box>


                        <IconButton
                            onClick={handleNext}
                            sx={{
                                position: "absolute",
                                right: 10,
                                top: "50%",
                                transform: "translateY(-50%)",
                                zIndex: 2,
                                color: "blue",
                            }}
                        >
                            <ArrowForwardIos />
                        </IconButton>


                        <Box sx={{ display: "flex", justifyContent: "center", mt: 2, height: '50px' }}>
                            {selectedImages.map((img, index) => (
                                <img
                                    key={index}
                                    src={`${IMG_URL}${img.image_url}`}
                                    alt="Thumbnail"
                                    style={{
                                        width: selectedIndex === index ? "90px" : "60px",
                                        height: "auto",
                                        margin: "0 5px",
                                        opacity: selectedIndex === index ? 1 : 0.6,
                                        cursor: "pointer",
                                        borderRadius: 4,
                                        transition: "all 0.3s ease-in-out",
                                        border: selectedIndex === index ? "3px solid #5B5555" : "2px solid transparent",
                                    }}
                                    onClick={() => handleThumbnailClick(index)}
                                />
                            ))}
                        </Box>
                    </Box>
                </Box>
            </Dialog> */}

            {open &&
                <Box
                    sx={{
                        position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)',
                        display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 2,
                    }} >
                    <Box sx={{
                        width: '100%', height: '100%', gap: '5px',
                        justifyContent: 'center', alignItems: 'center', position: 'fixed',
                        display: 'flex', flexDirection: 'column',
                    }}>
                        <Box sx={{
                            width: { md: '50%', sm: '50%', xs: '90%' }, height: '36px',
                            justifyContent: 'right', alignItems: 'right', display: 'flex',
                        }}>
                            <IconButton onClick={handleClose} sx={{
                                width: '36px', height: '36px',
                            }} >
                                <CloseOutlinedIcon sx={{
                                    width: '36px', height: '36px', color: Colors.black_60, '&:hover': {
                                        width: '40px', height: '40px', color: Colors.black_60, backgroundColor: 'none',
                                        background: 'none'
                                    },
                                }} ></CloseOutlinedIcon>
                            </IconButton>
                        </Box>
                        <Box
                            sx={{

                                width: { md: '50%', sm: '50%', xs: '90%' },
                                height: 'fit-content',
                                backgroundColor: Colors.grey_20,
                                border: '1px solid #E6E6E6',
                                borderRadius: '4px ',
                                boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                                boxSizing: 'border-box',
                                zIndex: 3,
                                padding: '35px 0px',
                                alignItems: 'center',
                                overflowY: 'auto',
                                '&::-webkit-scrollbar': {
                                    width: '11px',
                                    height: '8px', // Added height for horizontal scrollbars
                                    borderRadius: '16px',

                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: Colors.grey_30,
                                    borderRadius: '16px',
                                    border: '2px solid white', // Adds some padding around the thumb to create the appearance of rounded edges
                                },
                                '&::-webkit-scrollbar-track': {
                                    borderRadius: '16px',
                                    backgroundColor: Colors.white,

                                },
                            }}
                        >

                            <Box sx={{
                                width: '100%', height: '100%', display: 'flex', flexDirection: 'column',

                            }}>


                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        position: "relative",
                                        paddingTop: "40px", // Adds space for the close button header
                                        backgroundColor: "white",
                                    }}
                                >

                                    <IconButton
                                        onClick={handlePrev}
                                        sx={{
                                            position: "absolute",
                                            left: 10,
                                            top: "50%",
                                            transform: "translateY(-50%)",
                                            zIndex: 2,
                                            color: "blue",
                                        }}
                                    >
                                        <ArrowBackIos />
                                    </IconButton>


                                    <Box
                                        sx={{
                                            width: "100%",
                                            maxWidth: "600px",
                                            height: "auto",
                                            maxHeight: "80vh",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            overflow: "hidden",
                                        }}
                                    >
                                        <img
                                            key={selectedIndex}
                                            src={`${IMG_URL}${selectedImages[selectedIndex]?.image_url}`}
                                            alt="Main Scanned"
                                            style={{
                                                width: "100%",
                                                height: "auto",
                                                maxHeight: "70vh",
                                                objectFit: "contain",
                                                display: "block",
                                                transition: "opacity 0.3s ease-in-out",
                                            }}
                                        />
                                    </Box>


                                    <IconButton
                                        onClick={handleNext}
                                        sx={{
                                            position: "absolute",
                                            right: 10,
                                            top: "50%",
                                            transform: "translateY(-50%)",
                                            zIndex: 2,
                                            color: "blue",
                                        }}
                                    >
                                        <ArrowForwardIos />
                                    </IconButton>


                                    <Box sx={{ display: "flex", justifyContent: "center", mt: 2, height: '50px' }}>
                                        {selectedImages.map((img, index) => (
                                            <img
                                                key={index}
                                                src={`${IMG_URL}${img.image_url}`}
                                                alt="Thumbnail"
                                                style={{
                                                    width: selectedIndex === index ? "90px" : "60px",
                                                    height: "auto",
                                                    margin: "0 5px",
                                                    opacity: selectedIndex === index ? 1 : 0.6,
                                                    cursor: "pointer",
                                                    borderRadius: 4,
                                                    transition: "all 0.3s ease-in-out",
                                                    border: selectedIndex === index ? "3px solid #5B5555" : "2px solid transparent",
                                                }}
                                                onClick={() => handleThumbnailClick(index)}
                                            />
                                        ))}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            }





        </Box>

    );
};

export default ScannedImages;
