import { Box, Button, Grid, Typography, IconButton, Dialog, DialogContent, DialogTitle, DialogActions, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import axios from "axios";
import { useState, useEffect } from "react";
import { API_URL, IMG_URL } from "../Components/Constants";
import { Close, Add } from "@mui/icons-material";
import TextfieldNormal from "../Components/TextfieldNormal";
import ImageCapture from "./ImageCapture";
import ImageCaptureModal from "../Components/ImageCaptureModal";
import ButtonWhite from '../Components/ButtonWhite';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ButtonBlue from "../Components/ButtonBlue";

const PrintersAssociateScannedImages = () => {
    const [groupedImages, setGroupedImages] = useState({}); // Store images grouped by image_id
    const [selectedImages, setSelectedImages] = useState([]); // Images of the clicked row
    const [selectedIndex, setSelectedIndex] = useState(0); // Current image index
    const [open, setOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [sortOption, setSortOption] = useState("");
    const [openCameraModal, setOpenCameraModal] = useState(false);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [promotionalImageId, setPromotionalImageId] = useState();
    const [imageId, setImageId] = useState();
    const [comments, setComments] = useState({});
    const [statuses, setStatuses] = useState({});
    const [inProgressImageId, setInProgressImageId] = useState(null);
    const [assossiateName, setAssossiateName] = useState("");
    const [assossiateId, setAssossiateId] = useState("");
    useEffect(() => {
        const savedInProgress = localStorage.getItem("inProgressImageId");
        if (savedInProgress) {
            setInProgressImageId(savedInProgress);
        }
    }, []);
    const handleCommentChange = (imageId, value) => {
        setComments(prev => ({
            ...prev,
            [imageId]: value
        }));
    };
    const handleAddComment = async (e, imgid) => {

        if (e.key === 'Enter' && comments[imgid]?.trim() !== '') {
            console.log(imgid, assossiateId, comments[imgid])
            const printersAssociateToken = localStorage.getItem("printersAssociateToken")
            console.log("PrintersAssociateToken,imgid", printersAssociateToken, imgid)
            try {
                const response = await axios.post(`${API_URL}/printers_associate_add_comment`, {
                    tocken: printersAssociateToken,
                    ImgId: imgid,
                    comment: comments[imgid]
                });

                if (response.data.success) {

                    setComments((prevComments) => ({
                        ...prevComments,
                        [imgid]: '',
                    }));
                    fetchComments();
                }
            } catch (error) {
                console.error('Error adding comment:', error);
            }
        }
    };
    const fetchComments = async () => {
        try {
            const response = await axios.get(`${API_URL}/select_all_printers_associate_comments`);
            console.log(response.data.result)
            if (response.data.success) {
                const fetchedComments = response.data.result.reduce((acc, comment) => {
                    acc[comment.image_id] = comment.printers_associate_comments;
                    return acc;
                }, {});
                setComments(fetchedComments);
            }
        } catch (error) {
            console.error('Error fetching comments:', error);
            // Handle error appropriately in the UI
        }
    };
    useEffect(() => {
        fetchComments();
    }, []);

    useEffect(() => {
        const fetchInProgressImage = async () => {
            try {
                const response = await axios.get(`${API_URL}/get_inprogress_image`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("printersAssociateToken")}`,
                    },
                });
    
                if (response.data.success) {
                    const inProgressImage = response.data.image_id;
                    
                    if (inProgressImage) {
                        setInProgressImageId(inProgressImage);
                        localStorage.setItem("inProgressImageId", inProgressImage);
                    } else {
                        setInProgressImageId(null);
                        localStorage.removeItem("inProgressImageId");
                    }
                } else {
                    console.error("Failed to fetch in-progress image:", response.data.message);
                }
            } catch (error) {
                console.error("Error fetching in-progress image:", error.message);
            }
        };
    
        fetchInProgressImage();
    }, []);
    
    const handleStatusChange = async (image_id, newStatus) => {
        // Fetch current in-progress image ID
        const storedInProgressImageId = localStorage.getItem("inProgressImageId");
    
        if (newStatus === "inprogress") {
            // Prevent selection if another row is already in progress
            if (storedInProgressImageId && storedInProgressImageId !== image_id) {
                alert("Only one row can be marked as In Progress at a time.");
                return;
            }
    
            // Store the new in-progress image_id in state and localStorage
            setInProgressImageId(image_id);
            localStorage.setItem("inProgressImageId", image_id);
        } else if (storedInProgressImageId === image_id && newStatus !== "inprogress") {
            // If changing from "inprogress" to another status, clear localStorage
            setInProgressImageId(null);
            localStorage.removeItem("inProgressImageId");
        }
    
        // Update state
        setStatuses((prev) => ({ ...prev, [image_id]: newStatus }));
    const printersAssociateToken=localStorage.getItem("printersAssociateToken");
    console.log("printersAssociateToken",printersAssociateToken)
        // Backend API call
        try {
            const response = await axios.post(`${API_URL}/printers_associate_update_image_status`, {
                image_id: image_id,
                status: newStatus,
                tocken: printersAssociateToken,
            });
    
            if (response.data.success) {
                console.log(`Image ${image_id} status updated to ${newStatus}`);
            } else {
                console.error("Failed to update image status:", response.data.message);
            }
        } catch (error) {
            console.error("Error updating image status:", error.message);
        }
    };



    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleSortChange = (event) => {
        setSortOption(event.target.value);
        // Apply sorting logic if needed
    };

    // Filter and sort images based on searchQuery and sortOption
    const filteredImages = Object.entries(groupedImages)
        .filter(([image_id, images]) =>
            image_id.includes(searchQuery) // Filter by image_id (modify as per your requirement)
        )
        .sort(([idA, imagesA], [idB, imagesB]) => {
            if (sortOption === "date") {
                return new Date(imagesB[0]?.created_at) - new Date(imagesA[0]?.created_at);
            }
            return 0; // Default sorting
        });

    useEffect(() => {
        const fetchPromotionalImages = async () => {
            try {
                const response = await axios.get(`${API_URL}/get_promotional_images`);

                if (response.data.success && Array.isArray(response.data.data)) {
                    // Group images by image_id
                    const grouped = response.data.data.reduce((acc, img) => {
                        acc[img.image_id] = acc[img.image_id] || [];
                        acc[img.image_id].push(img);
                        return acc;
                    }, {});
                    setGroupedImages(grouped);
                    const initialStatuses = response.data.data.reduce((acc, img) => {
                        acc[img.image_id] = img.status || "pending"; // Default to "pending" if no status
                        return acc;
                    }, {});
console.log("initialStatuses",initialStatuses)
                    setStatuses(initialStatuses);
                } else {
                    console.error("Unexpected API response format:", response.data);
                    setGroupedImages({});
                    setStatuses({});
                }
            } catch (error) {
                console.error("Error fetching promotional images:", error);
                setGroupedImages({});
            }
        };

        fetchPromotionalImages();
    }, []);


    // const handleConfirmDelete = async () => {
    //     if (selectedImage) {
    //         await handleDeleteImage(selectedImage.image_id, selectedImage.image_url);
    //         handleConfirmClose();
    //     }
    // };
    const handleConfirmDelete = async () => {
        console.log(promotionalImageId)
        try {
            // Send DELETE request to backend
            await axios.delete(`${API_URL}/delete_image/${promotionalImageId}`);
            setGroupedImages((prevImages) => {
                // Create a new copy of the grouped images object
                const updatedImages = { ...prevImages };

                if (updatedImages[imageId]) {
                    // Filter out the deleted image
                    updatedImages[imageId] = updatedImages[imageId].filter(
                        (img) => img.promotional_img_id !== promotionalImageId
                    );

                    // If no images remain in the group, remove the group itself
                    if (updatedImages[imageId].length === 0) {
                        delete updatedImages[imageId];
                    }
                }

                return { ...updatedImages }; // Ensure new reference is created
            });
            handleCloseConfirmDelete();
        } catch (error) {
            console.error("Error deleting image:", error);
        }
    };

    const handleOpenCameraModal = (images) => {
        setOpenCameraModal(true)
        setImageId(images.image_id)
    }
    const handleCloseCameraModal = () => {
        setOpenCameraModal(false);
    };
    const handleCloseConfirmDelete = () => {
        setOpenConfirmDelete(false);
    };
    function handleAddServiceProviderClick() {
        localStorage.removeItem('ASPID')
        const isEdit = false;
        window.location.href = `/associate-profile?edit=${isEdit}`;
    }
    const handleOpenConfirmDelete = (img) => {
        console.log("img", img)
        setImageId(img.image_id)
        setPromotionalImageId(img.promotional_img_id)
        setOpenConfirmDelete(true);
    };
    // Open modal with images from the clicked row
    const handleOpen = (rowImages) => {
        setSelectedImages(rowImages);
        setSelectedIndex(0);
        setOpen(true);
    };

    // Close modal
    const handleClose = () => {
        setOpen(false);
    };

    // Navigate to previous image
    const handlePrev = () => {
        setSelectedIndex((prevIndex) => (prevIndex === 0 ? selectedImages.length - 1 : prevIndex - 1));
    };

    // Navigate to next image
    const handleNext = () => {
        setSelectedIndex((prevIndex) => (prevIndex === selectedImages.length - 1 ? 0 : prevIndex + 1));
    };

    // Click on a thumbnail to set as main image
    const handleThumbnailClick = (index) => {
        setSelectedIndex(index);
    };
    const fetchAssossiateDetails = async () => {
        const printersAssociateToken = localStorage.getItem("printersAssociateToken")
        console.log(printersAssociateToken)
        try {
            const response = await axios.post(`${API_URL}/select_printers_associate_details_with_token`,
                { printersAssociateToken: localStorage.getItem("printersAssociateToken") });
            console.log(response.data)
            setAssossiateName(response.data.result.name);
            setAssossiateId(response.data.result.printers_associate_login_id)
        } catch (error) {
            console.error('Error fetching service categories:', error);
        }
    };

    useEffect(() => {
        fetchAssossiateDetails();

    }, []);
    return (

        <Box sx={{ width: "100%", margin: "auto", border: "1px solid #ddd", padding: 2 }}>


            {/* Top Section: Search & Sort */}
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                {/* Search Field (Left) */}
                <TextfieldNormal
                    label="Search"
                    variant="outlined"
                    size="small"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    sx={{ width: "250px" }}
                />

                {/* Sort By Dropdown (Right) */}
                <FormControl variant="outlined" size="small" sx={{ width: "200px" }}>
                    <InputLabel>Sort By</InputLabel>
                    <Select
                        value={sortOption}
                        onChange={(e) => setSortOption(e.target.value)}
                        label="Sort By"
                    >
                        <MenuItem value="date">Date</MenuItem>
                        {/* <MenuItem value="name">Name</MenuItem> */}
                    </Select>
                </FormControl>
            </Box>

            {/* Image Grid */}
            {filteredImages.map(([image_id, images]) => {
                console.log("image_id...", image_id);

                if (!image_id) {
                    console.warn("Skipping row: image_id is null or undefined");
                    return null; // Skip rendering this row if `image_id` is invalid
                }

                const displayedImages = images.slice(0, 4);
                console.log("displayedImages", displayedImages);

                return (
                    <Grid
                        container
                        key={image_id}
                        alignItems="center"
                        spacing={2}
                        sx={{ borderBottom: "1px solid #ddd", padding: 1, display: "flex", justifyContent: "space-between" }}
                    >
                        {/* Images */}
                        <Grid item sx={{ display: "flex", alignItems: "center", flex: 1 }}>
                            {displayedImages.map((img, index) => (
                                <div
                                    key={index}
                                    style={{ position: "relative", display: "inline-block", marginRight: 10, cursor: "pointer" }}
                                    onMouseEnter={(e) => (e.currentTarget.querySelector(".close-btn").style.visibility = "visible")}
                                    onMouseLeave={(e) => (e.currentTarget.querySelector(".close-btn").style.visibility = "hidden")}
                                >
                                    <img
                                        src={`${IMG_URL}${img.image_url}`}
                                        alt="Scanned"
                                        style={{ width: "80px", height: "auto", borderRadius: 4 }}
                                    />
                                    <IconButton
                                        className="close-btn"
                                        onClick={() => handleOpenConfirmDelete(img)}
                                        sx={{
                                            position: "absolute",
                                            top: 2,
                                            right: 2,
                                            backgroundColor: "rgba(255, 255, 255, 0.8)",
                                            "&:hover": { backgroundColor: "rgba(255, 0, 0, 0.8)" },
                                            visibility: "hidden",
                                            transition: "visibility 0.2s ease-in-out",
                                            width: "20px",
                                            height: "20px",
                                            padding: "2px",
                                        }}
                                    >
                                        <Close fontSize="small" />
                                    </IconButton>
                                </div>
                            ))}
                        </Grid>

                        {/* Date, Comment, Status, View */}
                        <Grid item sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", flex: 1, gap: 2 }}>
                            {/* Date */}
                            <Typography variant="body2" color="textSecondary">
                                {new Date(images[0]?.created_at).toLocaleString()}
                            </Typography>

                            {/* Comment Input Field */}
                            <TextfieldNormal
                                variant="outlined"
                                size="small"
                                placeholder="comment here"
                                value={comments[image_id] ?? ""} // Use nullish coalescing for safer handling
                                onChange={(e) => {
                                    console.log("Updating comment for image_id:", image_id, "New Value:", e.target.value);
                                    handleCommentChange(image_id, e.target.value);
                                }}
                                onKeyDown={(e) => handleAddComment(e, image_id)}
                                sx={{ width: 200 }}
                            />

                            {/* Status Dropdown */}
                            <FormControl size="small" sx={{ minWidth: 150 }}>
                                <InputLabel>Status</InputLabel>
                                <Select
                                    value={statuses[image_id] || "pending"}
                                    onChange={(e) => handleStatusChange(image_id, e.target.value)}
                                    label="Status"
                                >
                                    <MenuItem value="pending">Pending</MenuItem>
                                    <MenuItem value="inprogress">Inprogress</MenuItem>
                                    <MenuItem value="completed">Completed</MenuItem>
                                </Select>
                            </FormControl>

                            {/* View Button */}
                            <Button variant="contained" color="primary" onClick={() => handleOpen(images)}>
                                View
                            </Button>
                        </Grid>
                    </Grid>
                );
            })}


            {/* Confirmation Dialog for Image Deletion */}
            <Dialog open={openConfirmDelete} onClose={handleCloseConfirmDelete}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this image?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirmDelete} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            { /*camera modal*/}
            <Dialog open={openCameraModal} onClose={handleCloseCameraModal}>
                <DialogTitle></DialogTitle>
                <DialogContent>
                    <ImageCaptureModal imageId={imageId} ></ImageCaptureModal>
                </DialogContent>

            </Dialog>


            {/* Image Modal */}
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogContent
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                        width: "100%",
                        maxWidth: "500px",
                        height: "auto",
                        overflow: "hidden",
                        backgroundColor: "white",
                        p: 2,
                        margin: "auto"
                    }}
                >
                    {/* Close Button */}
                    <IconButton
                        onClick={handleClose}
                        sx={{
                            position: "absolute",
                            top: 8,
                            right: 8,
                            color: "black",
                        }}
                    >
                        <Close />
                    </IconButton>

                    {/* Left Arrow */}
                    <IconButton
                        onClick={handlePrev}
                        sx={{
                            position: "absolute",
                            left: 10,
                            top: "50%",
                            transform: "translateY(-50%)",
                            zIndex: 2,
                            color: "blue",
                        }}
                    >
                        <ArrowBackIos />
                    </IconButton>

                    {/* Image Container (Responsive) */}
                    <Box
                        sx={{
                            width: "100%",
                            maxWidth: "600px",
                            height: "auto",
                            maxHeight: "80vh",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            overflow: "hidden",
                        }}
                    >
                        <img
                            key={selectedIndex}
                            src={`${IMG_URL}${selectedImages[selectedIndex]?.image_url}`}
                            alt="Main Scanned"
                            style={{
                                width: "100%",
                                height: "auto",
                                maxHeight: "70vh",
                                objectFit: "contain",
                                display: "block",
                                transition: "opacity 0.3s ease-in-out",
                            }}
                        />
                    </Box>

                    {/* Right Arrow */}
                    <IconButton
                        onClick={handleNext}
                        sx={{
                            position: "absolute",
                            right: 10,
                            top: "50%",
                            transform: "translateY(-50%)",
                            zIndex: 2,
                            color: "blue",
                        }}
                    >
                        <ArrowForwardIos />
                    </IconButton>

                    {/* Thumbnails */}
                    <Box sx={{ display: "flex", justifyContent: "center", mt: 2, height: '50px' }}>
                        {selectedImages.map((img, index) => (
                            <img
                                key={index}
                                src={`${IMG_URL}${img.image_url}`}
                                alt="Thumbnail"
                                style={{
                                    width: selectedIndex === index ? "90px" : "60px",
                                    height: "auto",
                                    margin: "0 5px",
                                    opacity: selectedIndex === index ? 1 : 0.6,
                                    cursor: "pointer",
                                    borderRadius: 4,
                                    transition: "all 0.3s ease-in-out",
                                    border: selectedIndex === index ? "3px solid #5B5555" : "2px solid transparent",
                                }}
                                onClick={() => handleThumbnailClick(index)}
                            />
                        ))}
                    </Box>
                </DialogContent>
            </Dialog>

        </Box>

    );
};

export default PrintersAssociateScannedImages;
