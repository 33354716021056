
import React, { useEffect, useState, useRef } from 'react';
import { Grid, Box, Avatar, Button, IconButton, InputAdornment, Breadcrumbs, TextField } from "@mui/material";
import TypographySubHeading from "../Components/TypographySubHeading";
import TypographyHeading from "../Components/TypographyHeading";
import TypographyNormal from "../Components/TypographyNormal";
import Colors from '../Components/Colors';
import TextfieldNormal from '../Components/TextfieldNormal';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ButtonBlue from '../Components/ButtonBlue';
import TypographyError from '../Components/TypographyError';
import axios from "axios";
import { API_URL } from '../Components/Constants';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import ButtonWhite from '../Components/ButtonWhite';
import uploadCamera from '../Images/uploadCamera.png'
import { IMG_URL } from '../Components/Constants';
import AssociateSidebar from '../Components/AssociateSidebar';
import { useLocation } from 'react-router-dom'
import SpChangePassword from './SpChangePassword';
import AssociateHeader from '../Components/AssociateHeader';
import PromotionalHeader from '../Components/PromotionalHeader';
import PrintersAssociateHeader from '../Components/PrintersAssociateHeader';





const colors = ['#503259', '#4C50AE', '#FF7D6A', '#B07CC6', '#20C925'];

function stringToColor(string) {
    let hash = 0;
    for (let i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    const colorIndex = Math.abs(hash) % colors.length;
    return colors[colorIndex];
}

function stringAvatar(name = '') {
    const initial = name.charAt(0).toUpperCase();

    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: initial || '',
    };
}



function AssociateProfile() {

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const isEdit = params.get('edit') === 'true';
    console.log(isEdit);
    const associateToken = localStorage.getItem('associateToken')
    const paToken = localStorage.getItem('printersAssociateToken')
    const [businessName, setBusinessName] = useState("");
    const [businessNameError, setBusinessNameError] = useState("");
    const [contactPerson, setContactPerson] = useState("");
    const [countryCode, setCountryCode] = useState("1");
    const [countryCodeError, setCountryCodeError] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [zipCode, setZipCode] = useState("");
    const [zipCodeError, setZipCodeError] = useState("");
    const [serviceProviderDetails, setServiceProviderDetails] = useState([]);
    const [isChangePassword, setIsChangePassword] = useState(false);
    const [profileImage, setProfileImage] = useState('');
    const [isImageUpload, setIsImageUpload] = useState('');
    const [isImagePopup, setIsImagePopup] = useState(false);
    const [languages, setLanguages] = useState("");
    const [email, setEmail] = useState("");
    const [website, setWebsite] = useState("");
    const [province, setProvince] = useState("");
    const [provinceError, setProvinceError] = useState("");
    const [provinceDetails, setProvinceDetails] = useState([]);
    const [filteredProvinces, setFilteredProvinces] = useState([]);
    const [country, setCountry] = useState("Canada");
    const [countryError, setCountryError] = useState("");
    const [aboutUs, setAboutUs] = useState("");
    const [aboutUsError, setAboutUsError] = useState("");
    const [filterDropDown, setFilterDropDown] = useState(false);

    const fileInputRef = useRef(null);
    const imagePopupRef = useRef(null);
    const dropDownRef = useRef(null);

    const handleBusinessNameChange = (event) => {
        setBusinessName(event.target.value);
        setBusinessNameError("")
    };

    const handlecontactPersonChange = (event) => {
        setContactPerson(event.target.value);
    };

    const handleLanguageChange = (event) => {
        setLanguages(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleWebsiteChange = (event) => {
        setWebsite(event.target.value);
    };

    const handleCountryCodeChange = (event) => {
        setCountryCode(event.target.value);
        setCountryCodeError("");
    };

    const handleKeyDownCountryCode = (event) => {
        const key = event.key;
        if (!/^\d$/.test(key) && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
            event.preventDefault();
        }
    };

    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
        setPhoneNumberError("");

    };

    const handleKeyDownPhone = (event) => {
        const key = event.key;
        if (!/^\d$/.test(key) && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
            event.preventDefault();
        }
    };

    const handleProvinceChange = (event) => {
        setProvince(event.target.value);
        setProvinceError("")
        setFilterDropDown(true)

        const inputValue = event.target.value;
        const filtered = provinceDetails.filter(province =>
            province.province.toLowerCase().startsWith(inputValue.toLowerCase())
        );
        setFilteredProvinces(filtered);
    };

    const handleCountryChange = (event) => {
        setCountry(event.target.value);
        setCountryError("")
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        setPasswordError("");

    };

    const handleZipCodeChange = (event) => {
        const input = event.target.value
        const formattedZipCode = input.replace(/\s+/g, '');
        if (formattedZipCode.length <= 6) {
            setZipCode(event.target.value);
        }
        setZipCodeError("");

    };

    const handleAboutUsChange = (event) => {
        setAboutUs(event.target.value);
        setAboutUsError("");
    };

    const handleSaveButtonClick = async () => {
        if (businessName.trim() === "") {
            setBusinessNameError("Please enter your business name");
        } else if (zipCode.trim() === "") {
            setZipCodeError("Please enter your zip code");
        } else if (zipCode.trim() !== "" && zipCode.replace(/\s+/g, '').length !== 6) {
            setZipCodeError("Zip code must be 6 digits");
        } else if (province.trim() === "") {
            setProvinceError("Please enter your province");
        } else if (province && !provinceDetails.some((detail) => detail.province === province)) {
            setProvinceError("Please select a valid province from the list.");
        } else if (countryCode.trim() === "") {
            setCountryCodeError("Please enter your country code");
        } else if (phoneNumber.trim() === "") {
            setPhoneNumberError("Please enter your phone number");
        } else if (phoneNumber.trim() !== "" && phoneNumber.length !== 10) {
            setPhoneNumberError("Phone number must be 10 digits");
        } else if (country.trim() === "") {
            setCountryError("Please enter your country");
        } else if (aboutUs.trim() === "") {
            setAboutUsError("Please add a description of your skills & experience.");
        } else {
            // Prepare form data
            const inProgressImageId = localStorage.getItem("inProgressImageId");
            const formData = new FormData();
            formData.append('spId', localStorage.getItem("ASPID"));
            formData.append('businessName', businessName);
            formData.append('phoneNumber', phoneNumber);
            formData.append('countryCode', countryCode);
            formData.append('contactPerson', contactPerson);
            formData.append('language', languages);
            formData.append('email', email);
            formData.append('website', website);
            formData.append('zipCode', zipCode);
            formData.append('province', province);
            formData.append('country', country);
            formData.append('aboutus', aboutUs);
            formData.append('tocken', localStorage.getItem("associateToken"))
            formData.append('patocken', localStorage.getItem("printersAssociateToken"))
            if (inProgressImageId) {
                formData.append('inProgressImageId', inProgressImageId);
            }
            if (isImageUpload) {
                try {
                    const response = await fetch(profileImage);
                    if (!response.ok) throw new Error('Image fetch failed');

                    const blob = await response.blob();
                    const file = new File([blob], 'profileImg.jpg', { type: blob.type });
                    formData.append('profileImg', file);
                } catch (error) {
                    console.error("Failed to convert image to file:", error);
                    return;
                }
            } else {
                formData.append('profileImg', '');
            }

            try {
                if (!isEdit) {
                    const response = await axios.post(`${API_URL}/check_phone_number_exist`,
                        {
                            countryCode: countryCode,
                            phone: phoneNumber,
                        });
                    console.log(response.data.error)
                    if (response.data.success !== true) {
                        setPhoneNumberError(response.data.error);
                        return;
                    }

                }
                try {
                    const url = isEdit
                        ? `${API_URL}/update_SP_Profile_details_associate`
                        : `${API_URL}/insert_SP_Profile_details_associate`;

                    const response = await axios.post(url, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });

                    if (response.data.success) {

                        if (!isEdit) {
                            localStorage.setItem("ASPID", response.data.service_provider_id);
                            window.location.href = `/associate-location?edit=false`;
                        } else {
                            if (localStorage.getItem("printersAssociateToken")) {
                                window.location.href = `/printers-associate-landing`;
                            } else if (localStorage.getItem("associateToken")) {
                                window.location.href = `/associate-landing`;
                            } else {
                                window.location.href = `/`;  // Redirect to login if neither token is available
                            }
                        }
                    } else {
                        console.error("An error occurred:", response.data.message);
                    }
                } catch (error) {
                    console.error("Request failed:", error);
                }



            }
            catch (error) {
                console.error("Request failed:", error);
            }
        }
    };





    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };


    const fetchSpData = async () => {
        try {
            const response = await axios.post(`${API_URL}/select_sp_details_with_spId`, {
                spId: localStorage.getItem("ASPID")
            });
            console.log(response.data.result.result);
            console.log(response.data.result.result.business_name);
            setServiceProviderDetails(response.data.result.result)
            setProfileImage(response.data.result.result.profile_image ? IMG_URL + response.data.result.result.profile_image : "");
            setCountryCode(response.data.result.result.country_code ? response.data.result.result.country_code : "");
            setPhoneNumber(response.data.result.result.phone_number ? response.data.result.result.phone_number : "");
            setPassword(response.data.result.result.password ? response.data.result.result.password : "");
            setZipCode(response.data.result.result.postal_code ? response.data.result.result.postal_code : "");
            setProvince(response.data.result.result.province ? response.data.result.result.province : "");
            setContactPerson(response.data.result.result.contact_person ? response.data.result.result.contact_person : "");
            setLanguages(response.data.result.result.languages ? response.data.result.result.languages : "");
            setEmail(response.data.result.result.email ? response.data.result.result.email : "");
            setWebsite(response.data.result.result.website ? response.data.result.result.website : "");
            setAboutUs(response.data.result.result.about_us ? response.data.result.result.about_us : "");
            setBusinessName(response.data.result.result.business_name ? response.data.result.result.business_name : "");
        } catch (error) {
            console.error('Error fetching data:', error);

        }
    };


    useEffect(() => {
        if (localStorage.getItem("ASPID")) {
            fetchSpData();
        }
    }, [localStorage.getItem("ASPID")]);

    useEffect(() => {
        const fetchProvinceData = async () => {
            try {
                const response = await axios.get(`${API_URL}/select_all_province`);
                console.log(response.data.result)
                setProvinceDetails(response.data.result);
            } catch (error) {
                console.error('Error fetching data:', error);
                setProvinceError('Failed to load provinces. Please try again later.');
            }
        };
        fetchProvinceData();

    }, []);


    const handleIconClick = () => {
        setIsImagePopup(!isImagePopup)
    };


    const handleChangeImageClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
        setIsImagePopup(false)
    };


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const newProfileImage = URL.createObjectURL(file);
            setProfileImage(newProfileImage);
            setIsImageUpload(newProfileImage);
        }
    };


    const handleRemoveImageClick = async () => {
        setProfileImage("")
        setIsImagePopup(false)
        setIsImageUpload(false)

    };


    const handleChangePasswordClick = () => {
        setIsChangePassword(true);
    }


    useEffect(() => {
        function handleClickOutside(event) {
            if (imagePopupRef.current && !imagePopupRef.current.contains(event.target)) {
                setIsImagePopup(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [imagePopupRef, setIsImagePopup]);


    useEffect(() => {
        function handleClickOutside(event) {
            if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
                setFilterDropDown(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropDownRef, setFilterDropDown]);


    const handleFilterDrpDownClick = (province) => {
        setProvince(province)
        setFilterDropDown(false)
    };


    const handleCancelButtonClick = () => {
        if (isEdit) {
            if (localStorage.getItem("printersAssociateToken")) {
                window.location.href = `/printers-associate-landing`;
            } else if (localStorage.getItem("associateToken")) {
                window.location.href = `/associate-landing`;
            } else {
                window.location.href = `/`;  // Redirect to login if neither token is available
            }
        }
        else {
            window.location.reload();
        }
    }




    return (
        <Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px' }}>

            {associateToken ? <AssociateHeader /> : paToken ? <PrintersAssociateHeader /> : null}

            <Box sx={{
                display: 'flex', flexDirection: 'column', width: '100%',
                justifyContent: 'center', height: '100%', alignItems: 'center'
            }}>

                <Box sx={{
                    display: 'flex', flexDirection: { xs: 'column ', md: 'row' },
                    width: '100%', paddingBottom: '2%', gap: '32px',
                    justifyContent: 'center', height: '100%', alignItems: 'flex-start', maxWidth: { xs: '90% ', md: '94%' }, paddingTop: '2%',
                }}>

                    <Box sx={{
                        display: 'flex', flexDirection: 'row', width: { xs: '100% ', md: '25%' },
                        justifyContent: 'flex-start', height: '100%', alignItems: 'center'
                    }}>
                        <AssociateSidebar currentPage={"Profile"}></AssociateSidebar>
                    </Box>

                    <Box sx={{
                        display: 'flex', flexDirection: 'column', width: { xs: '100% ', md: 'calc(75% - 32px)' },
                        borderLeft: { xs: '0px solid #E6E6E6', md: '1px solid #E6E6E6' },
                        justifyContent: 'center', height: '100%', alignItems: 'center', paddingLeft: { xs: '0px ', md: '32px' }
                    }}>

                        <Box sx={{
                            display: 'flex', flexDirection: 'column', width: '100%', gap: '8px',
                            justifyContent: 'center', height: '100%', alignItems: 'center',
                        }}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'column', width: '100%', gap: '8px', paddingTop: '2%',
                                height: '96%', justifyContent: 'center', alignItems: 'center', paddingBottom: '2%',
                            }}>

                                <Box sx={{
                                    height: '100%', width: { xs: '60px', md: '300px' }, justifyContent: 'center', alignItems: 'center',
                                    display: 'flex', flexDirection: 'column', position: 'relative', gap: '32px', marginTop: '-8px',
                                }}>
                                    <Box sx={{ position: 'relative', display: 'inline-block', height: { xs: '60px', md: '90px' } }}>
                                        {console.log(profileImage)}
                                        {profileImage ? (
                                            <Avatar alt="Profile"
                                                src={profileImage}
                                                sx={{ width: { xs: '60px', md: '90px' }, height: { xs: '60px', md: '90px' } }} />
                                        ) : (
                                            <Avatar alt="Profile"
                                                sx={{
                                                    ...stringAvatar(serviceProviderDetails.business_name),
                                                    width: { xs: '60px', md: '90px' }, height: { xs: '60px', md: '90px' }, fontSize: { xs: '32px', md: '58px' }, bgcolor: '#503259'
                                                }}>
                                                {stringAvatar(serviceProviderDetails.business_name).children}
                                            </Avatar>
                                        )}

                                        <Avatar sx={{
                                            position: 'absolute', bottom: { xs: 2, md: 0 }, right: { xs: 0, md: 0 }, width: { xs: '20px', md: '32px' }, height: { xs: '20px', md: '32px' },
                                            backgroundColor: Colors.primary_blue, color: 'white', cursor: 'pointer'
                                        }} onClick={handleIconClick} >
                                            <img src={uploadCamera} alt="upload" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
                                        </Avatar>
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                            onChange={handleFileChange}
                                        />
                                    </Box>
                                    {isImagePopup && (<Box ref={imagePopupRef} sx={{
                                        position: 'absolute', top: { xs: '60px', md: '90px' },
                                        width: { xs: '108px', md: '160px' }, right: '0px', borderRadius: '4px', marginTop: '4px',
                                        padding: { xs: '4px', md: '10px' }, backgroundColor: Colors.white_80, zIndex: 1,
                                        boxShadow: '0px 4px 4px 0px #00000040', gap: { xs: '0px ', md: '4px' }, display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        {!profileImage && <Box sx={{
                                            padding: { xs: '0px 10px', md: '4px 10px' },
                                            cursor: 'pointer', borderRadius: '4px', color: Colors.black_90,
                                            '&:hover': { backgroundColor: Colors.primary_blue, color: Colors.white },
                                        }}>
                                            <TypographyNormal sx={{
                                                maxWidth: '98%', whiteSpace: 'nowrap', overflow: 'hidden',
                                                textOverflow: 'ellipsis', color: 'inherit'
                                            }} onClick={handleChangeImageClick}>
                                                Upload image
                                            </TypographyNormal>
                                        </Box>}


                                        {profileImage && <> <Box sx={{
                                            padding: { xs: '0px 10px', md: '4px 10px' },
                                            cursor: 'pointer', borderRadius: '4px', color: Colors.black_90,
                                            '&:hover': { backgroundColor: Colors.primary_blue, color: Colors.white },
                                        }}>
                                            <TypographyNormal sx={{
                                                maxWidth: '98%', whiteSpace: 'nowrap', overflow: 'hidden',
                                                textOverflow: 'ellipsis', color: 'inherit'
                                            }}
                                                onClick={handleChangeImageClick}
                                            >
                                                Change image
                                            </TypographyNormal>
                                        </Box>
                                            <Box sx={{
                                                padding: { xs: '0px 10px', md: '4px 10px' }, cursor: 'pointer',
                                                borderRadius: '4px', color: Colors.black_90,
                                                '&:hover': { backgroundColor: Colors.primary_blue, color: Colors.white },
                                            }} >
                                                <TypographyNormal sx={{
                                                    maxWidth: '98%', whiteSpace: 'nowrap', overflow: 'hidden',
                                                    textOverflow: 'ellipsis', color: 'inherit'
                                                }} onClick={handleRemoveImageClick}>
                                                    Remove image
                                                </TypographyNormal>
                                            </Box></>}
                                    </Box>)}

                                </Box>

                                <Box sx={{ gap: { xs: '8px', md: '20px' }, display: 'flex', flexDirection: 'column', paddingTop: { xs: '8px', md: '16px' }, width: '100%' }}>

                                    <Box sx={{ gap: { xs: '8px', md: '24px' }, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, width: '100%' }}>
                                        <Box sx={{ gap: '4px', width: '100%', flexDirection: 'column', display: 'flex' }}>
                                            <TypographyNormal sx={{ fontSize: { xs: '10px', md: '14px' }, color: Colors.black_80, }}>business name*</TypographyNormal>
                                            <TextfieldNormal placeholder="Enter your business name"
                                                value={businessName}
                                                onChange={handleBusinessNameChange} error={!!businessNameError}></TextfieldNormal>
                                            {businessNameError && <TypographyError sx={{ width: '100%' }}>{businessNameError}</TypographyError>}
                                        </Box>
                                        <Box sx={{ gap: '4px', width: '100%', flexDirection: 'column', display: 'flex' }}>
                                            <TypographyNormal sx={{ fontSize: { xs: '10px', md: '14px' }, color: Colors.black_80, }}>Contact person<span style={{ color: Colors.black_60 }}>(Optional)</span> </TypographyNormal>
                                            <TextfieldNormal placeholder="Enter your contact person name"
                                                value={contactPerson}
                                                onChange={handlecontactPersonChange} ></TextfieldNormal>
                                        </Box>



                                    </Box>

                                    <Box sx={{ gap: { xs: '8px', md: '24px' }, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, width: '100%' }}>
                                        <Box sx={{ gap: '4px', width: '100%', flexDirection: 'column', display: 'flex' }}>
                                            <TypographyNormal sx={{ fontSize: { xs: '10px', md: '14px' }, color: Colors.black_80, }}>Language<span style={{ color: Colors.black_60 }}>(Optional)</span> </TypographyNormal>
                                            <TextfieldNormal placeholder="Enter languages you know"
                                                value={languages}
                                                onChange={handleLanguageChange} ></TextfieldNormal>
                                        </Box>

                                        <Box sx={{ gap: '4px', width: '100%', flexDirection: 'column', display: 'flex' }}>
                                            <TypographyNormal sx={{ fontSize: { xs: '10px', md: '14px' }, color: Colors.black_80, }}>Email<span style={{ color: Colors.black_60 }}>(Optional)</span> </TypographyNormal>
                                            <TextfieldNormal placeholder="Enter your email"
                                                value={email}
                                                onChange={handleEmailChange} ></TextfieldNormal>
                                        </Box>




                                    </Box>

                                    <Box sx={{ gap: { xs: '8px', md: '24px' }, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, width: '100%' }}>

                                        <Box sx={{ gap: '4px', width: '100%', flexDirection: 'column', display: 'flex' }}>
                                            <TypographyNormal sx={{ fontSize: { xs: '10px', md: '14px' }, color: Colors.black_80, }}>Website<span style={{ color: Colors.black_60 }}>(Optional)</span> </TypographyNormal>
                                            <TextfieldNormal placeholder="Enter your website"
                                                value={website}
                                                onChange={handleWebsiteChange} ></TextfieldNormal>
                                        </Box>

                                        <Box sx={{ gap: '24px', display: 'flex', flexDirection: 'column', width: '100%' }}>

                                            <Box sx={{ gap: { xs: '8px', md: '16px' }, display: 'flex', flexDirection: 'row', width: '100%' }}>
                                                <Box sx={{ gap: '4px', width: '30%', flexDirection: 'column', display: 'flex' }}>
                                                    <TypographyNormal sx={{ fontSize: { xs: '10px', md: '14px' }, color: Colors.black_80, }}>Zip code*</TypographyNormal>
                                                    <TextfieldNormal placeholder="Zip code"
                                                        value={zipCode}
                                                        onChange={handleZipCodeChange} error={!!zipCodeError}></TextfieldNormal>
                                                </Box>
                                                <Box sx={{ gap: '4px', width: '70%', flexDirection: 'column', display: 'flex', position: 'relative' }}>
                                                    <TypographyNormal sx={{ fontSize: { xs: '10px', md: '14px' }, color: Colors.black_80, }}>Province*</TypographyNormal>
                                                    <TextfieldNormal placeholder="Enter your province"
                                                        value={province}
                                                        onChange={handleProvinceChange} error={!!provinceError}></TextfieldNormal>

                                                    {filterDropDown && (
                                                        <Box ref={dropDownRef} sx={{
                                                            position: 'absolute', top: '100%', width: '94%', padding: '24px', right: '0px',
                                                            borderRadius: '4px', marginTop: '4px', padding: '8px', backgroundColor: Colors.white_80, overflowY: 'auto',
                                                            zIndex: 1, boxShadow: '0px 4px 4px 0px #00000040', gap: '0px', display: 'flex', flexDirection: 'column',
                                                            maxHeight: '180px', '&::-webkit-scrollbar': {
                                                                width: '8px', height: '16px', borderRadius: '16px',
                                                            },
                                                            '&::-webkit-scrollbar-thumb': {
                                                                backgroundColor: Colors.grey_30, borderRadius: '16px', border: '2px solid white',
                                                            },
                                                            '&::-webkit-scrollbar-track': {
                                                                borderRadius: '16px', backgroundColor: Colors.white,
                                                            },
                                                        }}>

                                                            {filteredProvinces.map((province, index) => (
                                                                <Box key={index}
                                                                    sx={{
                                                                        padding: '6px 10px', cursor: 'pointer', borderRadius: '4px', color: Colors.black_90,
                                                                        '&:hover': { backgroundColor: Colors.primary_blue, color: Colors.white },
                                                                    }}
                                                                    onClick={() => { handleFilterDrpDownClick(province.province); }}
                                                                >
                                                                    <TypographyNormal sx={{
                                                                        maxWidth: '98%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                                                                        color: 'inherit', fontSize: '12px',
                                                                    }}>{province.province}</TypographyNormal>
                                                                </Box>
                                                            ))}
                                                        </Box>
                                                    )}
                                                </Box>
                                            </Box>
                                            {zipCodeError && !provinceError && <TypographyError sx={{ width: '100%', marginTop: '-20px' }}>{zipCodeError}</TypographyError>}
                                            {!zipCodeError && provinceError && <TypographyError sx={{ width: '100%', paddingLeft: '33%', marginTop: '-20px' }}>{provinceError}</TypographyError>}
                                            {zipCodeError && provinceError && <TypographyError sx={{ width: '100%', marginTop: '-20px', }}>
                                                <span>{zipCodeError}</span> and  <span>{provinceError}</span></TypographyError>}
                                        </Box>


                                    </Box>

                                    <Box sx={{ gap: { xs: '8px', md: '24px' }, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, width: '100%' }}>
                                        <Box sx={{ gap: '24px', display: 'flex', flexDirection: 'column', width: '100%' }}>
                                            <Box sx={{ gap: { xs: '8px', md: '16px' }, display: 'flex', flexDirection: 'row', width: '100%' }}>
                                                <Box sx={{ gap: '4px', width: '30%', flexDirection: 'column', display: 'flex' }}>
                                                    <TypographyNormal sx={{ fontSize: { xs: '10px', md: '14px' }, color: Colors.black_80, }}>Country code*</TypographyNormal>
                                                    <TextfieldNormal
                                                        onKeyDown={handleKeyDownCountryCode}
                                                        inputProps={{
                                                            maxLength: 3,
                                                            inputMode: 'numeric',
                                                            pattern: '[0-9]*'
                                                        }}
                                                        sx={{
                                                            fontSize: { md: '16px', sm: '16px', xs: '14px' },
                                                            '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
                                                                '-webkit-appearance': 'none',
                                                                margin: 0
                                                            },
                                                            '& input[type=number]': {
                                                                '-moz-appearance': 'textfield'
                                                            }
                                                        }}
                                                        value={countryCode}
                                                        onChange={handleCountryCodeChange} error={!!countryCodeError}
                                                    />


                                                </Box>
                                                <Box sx={{ gap: '4px', width: '70%', flexDirection: 'column', display: 'flex' }}>
                                                    <TypographyNormal sx={{ fontSize: { xs: '10px', md: '14px' }, color: Colors.black_80, }}>Phone number*</TypographyNormal>
                                                    <TextfieldNormal
                                                        value={phoneNumber}
                                                        error={!!phoneNumberError} onKeyDown={handleKeyDownPhone}
                                                        inputProps={{
                                                            maxLength: 10,
                                                            inputMode: 'numeric',
                                                            pattern: '[0-9]*'
                                                        }}
                                                        sx={{
                                                            '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
                                                                '-webkit-appearance': 'none',
                                                                margin: 0
                                                            },
                                                            '& input[type=number]': {
                                                                '-moz-appearance': 'textfield'
                                                            }
                                                        }}
                                                        onChange={handlePhoneNumberChange}>
                                                    </TextfieldNormal>
                                                </Box>
                                            </Box>
                                            {countryCodeError && <TypographyError sx={{ width: '100%', marginTop: '-20px' }}>{countryCodeError}</TypographyError>}
                                            {phoneNumberError && <TypographyError sx={{ width: '100%', paddingLeft: '33%', marginTop: '-20px' }}>{phoneNumberError}</TypographyError>}
                                        </Box>

                                        <Box sx={{ gap: '4px', width: '100%', flexDirection: 'column', display: 'flex' }}>
                                            <TypographyNormal sx={{ fontSize: { xs: '10px', md: '14px' }, color: Colors.black_80, }}>Country*</TypographyNormal>
                                            <TextfieldNormal placeholder="Enter your country"
                                                value={country}
                                                onChange={handleCountryChange} error={!!countryError}></TextfieldNormal>

                                            {countryError && <TypographyError sx={{ paddingTop: '4px' }} >{countryError}</TypographyError>}
                                        </Box>
                                    </Box>


                                    <Box sx={{ gap: { xs: '8px', md: '24px' }, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, width: '100%' }}>
                                        <Box sx={{ gap: '4px', width: '100%', flexDirection: 'column', display: 'flex' }}>
                                            <TypographyNormal sx={{ fontSize: { xs: '10px', md: '14px' }, color: Colors.black_80, }}>About*</TypographyNormal>
                                            <TextField value={aboutUs} autoComplete="off"
                                                placeholder='Add description about your skills & experience'
                                                sx={{
                                                    height: '92px',
                                                    width: '100%',
                                                    padding: 0,
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            border: '1px solid', height: '92px',
                                                            borderColor: aboutUsError ? Colors.red : Colors.black_60,
                                                            transition: 'border-color 0.3s ease',
                                                        },
                                                        '&:hover fieldset': {
                                                            borderColor: aboutUsError ? Colors.red : Colors.black_80,
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: aboutUsError ? Colors.red : Colors.black_80,
                                                        },
                                                        '& .MuiInputBase-input::placeholder': {
                                                            fontFamily: 'Roboto',
                                                            fontSize: { xs: '10px', md: '14px' },
                                                            fontWeight: 400,
                                                            color: Colors.black_70,
                                                        },
                                                        '& .MuiInputBase-input': {
                                                            padding: { xs: '8px 12px', md: '10px 16px' },
                                                            fontSize: { xs: '10px', md: '14px' },
                                                            fontFamily: 'Roboto',
                                                            fontWeight: 400,
                                                            color: Colors.black_80,
                                                        },
                                                    },
                                                }}
                                                onChange={handleAboutUsChange} />
                                            {aboutUsError && <TypographyError>{aboutUsError}</TypographyError>}
                                        </Box>

                                    </Box>







                                    <Box sx={{
                                        width: '100%', flexDirection: 'row', justifyContent: 'right', display: 'flex',
                                        alignItems: 'center', gap: { xs: '8px', md: '16px' }, paddingTop: { xs: '16px', md: '32px' }
                                    }}>
                                        <Box sx={{
                                            width: { xs: '60%', md: '30%' }, flexDirection: 'row', justifyContent: 'center', display: 'flex',
                                            alignItems: 'center', gap: { xs: '8px', md: '16px' }
                                        }}>
                                            <ButtonWhite onClick={handleCancelButtonClick}>Cancel</ButtonWhite>
                                            <ButtonBlue onClick={handleSaveButtonClick} >Save</ButtonBlue>
                                        </Box>
                                    </Box>




                                </Box>

                            </Box>
                        </Box>

                    </Box>
                </Box>
            </Box>

            <Footer></Footer>
            {isChangePassword && (
                <SpChangePassword isChangePassword={isChangePassword} setIsChangePassword={setIsChangePassword} />
            )}


        </Grid>
    )
}
export default AssociateProfile;