
import React, { useEffect, useState, useRef } from 'react';
import { Grid, Box, Avatar, Button, IconButton, InputAdornment, Breadcrumbs, TextField, Chip, Checkbox, Link } from "@mui/material";
import TypographySubHeading from "../Components/TypographySubHeading";
import TypographyHeading from "../Components/TypographyHeading";
import TypographyNormal from "../Components/TypographyNormal";
import Colors from '../Components/Colors';
import TextfieldNormal from '../Components/TextfieldNormal';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ButtonBlue from '../Components/ButtonBlue';
import TypographyError from '../Components/TypographyError';
import axios from "axios";
import { API_URL } from '../Components/Constants';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import ButtonWhite from '../Components/ButtonWhite';
import uploadCamera from '../Images/uploadCamera.png'
import { IMG_URL } from '../Components/Constants';
import SpSidebar from '../Components/SpSidebar';
import { useLocation } from 'react-router-dom';
import SelectComponent from '../Components/SelectComponent';
import AddIcon from '@mui/icons-material/Add';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import CloseIcon from '@mui/icons-material/Close';
import AddService from '../Images/AddService.svg'





const colors = ['#503259', '#4C50AE', '#FF7D6A', '#B07CC6', '#20C925'];

function stringToColor(string) {
    let hash = 0;
    for (let i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    const colorIndex = Math.abs(hash) % colors.length;
    return colors[colorIndex];
}

function stringAvatar(name = '') {
    const initial = name.charAt(0).toUpperCase();

    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: initial || '',
    };
}



function AddSpService() {

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const isEdit = params.get('edit') === 'true';
    console.log(isEdit);


    const [isCompleteProfile, setIsCompleteProfile] = useState(true);
    const [isEditProfile, setIsEditProfile] = useState(false); 
    const [serviceProviderDtls, setServiceProviderDtls] = useState([]);
    const [messageDetails, setMessageDetails] = useState([]);
    const [unreadMessageCount, setUnreadMessageCount] = useState(0);
    const [serviceDetailsDataToShow, setServiceDetailsDataToShow] = useState([]);
    const [serviceDetails, setServiceDetails] = useState([]);
    const [allService, setAllService] = useState([]);
    const [isTypeShow, setIsTypeShow] = useState(false);
    const [typeNames, setTypeNames] = useState([]);
    const [typeIds, setTypeIds] = useState([]);
    const [allTypes, setAllTypes] = useState([]);
    const [typesError, setTypesError] = useState("");
    const [multipleServiceName, setMultipleServiceName] = useState([]);
    const [multipleServiceId, setMultipleServiceId] = useState([]);
    const [multipleProfessionalName, setMultipleProfessionalName] = useState([]);
    const [multipleTypesName, setMultipleTypesName] = useState([]);
    const [multipleTypesId, setMultipleTypesId] = useState([]);
    const [multipleCertification, setMultipleCertification] = useState([]);
    const [multipleLisence, setMultipleLisence] = useState([]);
    const [multipleFranchise, setMultipleFranchise] = useState([]);
    const [multipleOfficeAddress, setMultipleOfficeAddress] = useState([]);
    const [isloading, setIsLoading] = useState(true);
    const [service, setService] = useState([]);
    const [serviceName, setServiceName] = useState('');
    const [serviceProfessionName, setServiceProfessionName] = useState('');
    const [serviceId, setServiceId] = useState('');
    const [serviceError, setServiceError] = useState("");
    const [certifications, setCertifications] = useState('');
    const [isCertificationsAdd, setIsCertificationsAdd] = useState(false);
    const [selectedCertifications, setSelectedCertifications] = useState([]);
    const [editingIndex, setEditingIndex] = useState(-1);
    const [isCertficationIcon, setIsCertficationIcon] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [license, setLicense] = useState('');
    const [isLicenseAdd, setIsLicenseAdd] = useState(false);
    const [selectedLicense, setSelectedLicense] = useState([]);
    const [isLisenceIcon, setIsLisenceIcon] = useState(false);
    const [franchise, setFranchise] = useState('');
    const [isFranchiseAdd, setIsFranchiseAdd] = useState(false);
    const [selectedFranchise, setSelectedFranchise] = useState([]);
    const [isFranchiseIcon, setIsFranchiseIcon] = useState(false);
    const [OfficeAddress, setOfficeAddress] = useState('');
    const [isOfficeAddressAdd, setIsOfficeAddressAdd] = useState(false);
    const [selectedOfficeAddress, setSelectedOfficeAddress] = useState([]);
    const [isOfficeAddressIcon, setIsOfficeAddressIcon] = useState(false);
    const [isAddServicePopUp, setisAddServicePopUp] = useState(false);
    const [isEditService, setIsEditService] = useState(false);
    const [images, setImages] = useState([]);
    const [uploadImages, setUploadImages] = useState([]);
    const [insertedImages, setInsertedImages] = useState([]);
    const [servicesWithTypes, setServicesWithTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isDeleteServicePopUp, setIsDeleteServicePopUp] = useState(false);
    const [deleteServiceId, setDeleteServiceId] = useState(null);
    const [certificationError, setCertificationError] = useState('');
    const [liseceError, setLiseceError] = useState('');
    const [franchiseError, setFranchiseError] = useState('');
    const [officeAddrError, setOfficeAddrError] = useState('');
    const [inProgressImageId, setInProgressImageId] = useState(null);

    useEffect(() => {
        const savedImageId = localStorage.getItem("inProgressImageId");
        if (savedImageId) {
            setInProgressImageId(savedImageId);
        }
    }, []);

    async function fetchServiceProviderDetails() {
        try {
            const response = await axios.post(`${API_URL}/select_sp_details_with_tocken`, {
                spToken: localStorage.getItem("spToken")
            });
            console.log(response.data.result.service_details)
            if (response.data.success) {
                setServiceProviderDtls(response.data.result)
                setServiceDetails(response.data.result.service_details);
                const services = response.data.result.service_details;
                const extractedServices = services.map(service => ({
                    service: service.services,
                    service_id: service.services_id
                }));
                setAllService(extractedServices);
                setMessageDetails(response.data.result.message_details)
                const unreadCount = response.data.result.message_details.reduce((acc, message) => {
                    if (message.read === 'Unread') {
                        return acc + 1;
                    }
                    return acc;
                }, 0);
                setUnreadMessageCount(unreadCount);

            }
            else {
                console.error('Error:', response.data.error);
            }
        }
        catch (error) {
            console.error('Error:', error.message);
        }
        finally {
            setLoading(false); 
        }
    }

    useEffect(() => {
        fetchServiceProviderDetails();
    }, []);


    const fetchServices = async () => {
        try {
            const response = await axios.get(`${API_URL}/select_all_services_with_types`);
            console.log(response)
            const allservicewithitsTypes = response.data.servicesWithTypes
            setServicesWithTypes(response.data.servicesWithTypes)
            const allServices = allservicewithitsTypes.map(service => {
                return {
                    service_id: service.service_id,
                    service_name: service.service_name,
                    professional_name: service.professional_name
                };
            });
            setService(allServices);
            console.log(allServices)
        } catch (error) {
            console.error('Error fetching service categories:', error);
        }
    };

    useEffect(() => {
        fetchServices();
    }, []);

    const handleServiceChange = async (value) => {
        const selectedValue = value;
        const selectedOption = service.find(option => option.service_id === selectedValue);
        setServiceName(selectedOption.service_name);
        setServiceId(selectedOption.service_id);
        setServiceProfessionName(selectedOption.professional_name)
        const selectedService = servicesWithTypes.find(service => service.service_id === selectedValue);
        setAllTypes(selectedService.types);
        console.log(selectedService)
        setServiceError('');
        setTypeNames([])
        setTypeIds([])
        setSelectedFranchise([])
        setSelectedCertifications([])
        setSelectedLicense([])
        setSelectedOfficeAddress([])
        setIsEditProfile(true)

    };

    const handleShowTypes = async () => {
        // fetchTypes()
        setIsTypeShow(true)
        setTypesError('')
    };

    const searchRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setIsTypeShow(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [searchRef]);



    const handleCheckboxChange = (event, typeId) => {
        const typeName = event.target.value;
        setTypeNames((prevSelectedNames) => {
            if (event.target.checked) {
                return [...prevSelectedNames, event.target.value];
            } else {
                return prevSelectedNames.filter((name) => name !== typeName);
            }
        });

        setTypeIds((prevSelectedIds) => {
            if (event.target.checked) {
                return [...prevSelectedIds, typeId];
            } else {
                return prevSelectedIds.filter((id) => id !== typeId);
            }
        });

        setIsEditProfile(true)

    };


    const handleInputCertificationsChange = (event) => {
        const certficteValue = event.target.value;
        setCertifications(event.target.value);
        setIsCertficationIcon(true)
        setIsEditProfile(true)
        setCertificationError("")
    };

    const handleCertificationOnBlur = (event) => {
        if (selectedCertifications.length <= 5) {
            handleAddCertification()
        }
    }

    // const handleAddCertification = () => {
    //     setIsCertficationIcon(false)

    //     const trimmedCertifications = certifications.trim();

    //     if (trimmedCertifications !== '') {
    //         setSelectedCertifications((prevCertifications) => {
    //             const updatedCertifications = [...prevCertifications];
    //             if (editingIndex !== -1) {
    //                 updatedCertifications[editingIndex] = {
    //                     certifications: trimmedCertifications,
    //                 };
    //                 setEditingIndex(-1);
    //             } else {
    //                 if (selectedCertifications.length < 5) {
    //                     updatedCertifications.push({
    //                         certifications: trimmedCertifications,
    //                     });
    //                 }
    //             }

    //             return updatedCertifications;
    //         });
    //         setCertifications('');
    //         setIsCertificationsAdd(true);
    //     }


    // };

    const handleAddCertification = () => {
        setIsCertficationIcon(false);
    
        const trimmedCertifications = certifications.trim();
    
        if (trimmedCertifications !== '') {
            // Check if the certification already exists
            const exists = selectedCertifications.some(
                (cert) => cert.certifications.toLowerCase() === trimmedCertifications.toLowerCase()
            );
    
            if (exists) {
               // alert("This certification has already been added."); // Or use a more user-friendly notification
                setCertificationError("This certification is already added.")
                return; // Exit if the certification already exists
            }
    
            setSelectedCertifications((prevCertifications) => {
                const updatedCertifications = [...prevCertifications];
                if (editingIndex !== -1) {
                    updatedCertifications[editingIndex] = {
                        certifications: trimmedCertifications,
                    };
                    setEditingIndex(-1);
                } else {
                    if (selectedCertifications.length < 5) {
                        updatedCertifications.push({
                            certifications: trimmedCertifications,
                        });
                    }
                }
    
                return updatedCertifications;
            });
    
            setCertifications('');
            setIsCertificationsAdd(true);
        }
    };
    

    const handleCertificationsShowButtonClick = async (certification, index) => {
        setCertifications(certification)
        setEditingIndex(index)

    }

    const handleCertificationsCloseButtonClick = (index) => {
        setIsEditProfile(true)
        const updatedCertifications = [...selectedCertifications];
        updatedCertifications.splice(index, 1);
        setSelectedCertifications(updatedCertifications);

    };

    const handleInputLicenseChange = (event) => {
        setIsEditProfile(true)
        setIsLisenceIcon(true)
        setLicense(event.target.value);
        if (selectedLicense.length < 5) {
            const licenselist = selectedLicense.map(lic => lic.license).concat(event.target.value); // Add event.target.value to licenselist
            const finalLicenselist = licenselist.join(', ');
        }
        setLiseceError("");
    };

    const handleLicenseOnBlur = (event) => {
        if (selectedLicense.length <= 5) {
            handleAddLicense()
        }
    }

    // const handleAddLicense = () => {
    //     setIsLisenceIcon(false)

    //     const trimmedLicense = license.trim();

    //     if (trimmedLicense !== '') {
    //         setSelectedLicense((prevLicense) => {
    //             const updatedLicense = [...prevLicense];

    //             if (editingIndex !== -1) {
    //                 updatedLicense[editingIndex] = {
    //                     license: trimmedLicense,

    //                 };
    //                 setEditingIndex(-1);
    //             } else {
    //                 if (selectedLicense.length < 5) {
    //                     updatedLicense.push({
    //                         license: trimmedLicense,

    //                     });
    //                 }
    //             }

    //             return updatedLicense;
    //         });

    //         setLicense('');
    //         setIsLicenseAdd(true);

    //     }
    // };

    const handleAddLicense = () => {
        setIsLisenceIcon(false);
    
        const trimmedLicense = license.trim();
    
        if (trimmedLicense !== '') {
            // Check if the license already exists
            const exists = selectedLicense.some(
                (lic) => lic.license.toLowerCase() === trimmedLicense.toLowerCase()
            );
    
            if (exists) {
                setLiseceError("This license is already added."); // Or use a more user-friendly notification
                return; // Exit if the license already exists
            }
    
            setSelectedLicense((prevLicense) => {
                const updatedLicense = [...prevLicense];
    
                if (editingIndex !== -1) {
                    updatedLicense[editingIndex] = {
                        license: trimmedLicense,
                    };
                    setEditingIndex(-1);
                } else {
                    if (selectedLicense.length < 5) {
                        updatedLicense.push({
                            license: trimmedLicense,
                        });
                    }
                }
    
                return updatedLicense;
            });
    
            setLicense('');
            setIsLicenseAdd(true);
        }
    };
    
    

    const handleLicenseShowButtonClick = async (license, index) => {
        setLicense(license)
        setEditingIndex(index)
    }

    const handleLicenseCloseButtonClick = (index) => {
        const updatedLicense = [...selectedLicense];
        updatedLicense.splice(index, 1);
        setSelectedLicense(updatedLicense);
        setIsEditProfile(true)

    };

    const handleInputFranchiseChange = (event) => {
        setIsEditProfile(true)
        setIsFranchiseIcon(true)
        setFranchise(event.target.value);
        if (selectedFranchise.length < 5) {
            let finalFranchiselist;
            const franchiselist = selectedFranchise.map(fr => fr.franchise).concat(event.target.value); // Add event.target.value to licenselist
            finalFranchiselist = franchiselist.join(', ');

        }
        setFranchiseError(""); 
    };

    const handleFranchiseOnBlur = (event) => {
        if (selectedFranchise.length <= 5) {
            handleAddFranchise()
        }
    }

    // const handleAddFranchise = () => {
    //     const trimmedFranchise = franchise.trim();
    //     setIsFranchiseIcon(false)
    //     if (trimmedFranchise !== '') {
    //         setSelectedFranchise((prevFranchise) => {
    //             const updatedFranchise = [...prevFranchise];

    //             if (editingIndex !== -1) {
    //                 updatedFranchise[editingIndex] = {
    //                     franchise: trimmedFranchise,
    //                 };
    //                 setEditingIndex(-1);
    //             } else {
    //                 if (selectedFranchise.length < 5) {
    //                     updatedFranchise.push({
    //                         franchise: trimmedFranchise,

    //                     });
    //                 }
    //             }

    //             return updatedFranchise;
    //         });

    //         setFranchise('');
    //         setIsFranchiseAdd(true);

    //     }
    // };


    const handleAddFranchise = () => {
        const trimmedFranchise = franchise.trim();
        setIsFranchiseIcon(false);
    
        if (trimmedFranchise !== '') {
            // Check if the franchise already exists
            const exists = selectedFranchise.some(
                (fran) => fran.franchise.toLowerCase() === trimmedFranchise.toLowerCase()
            );
    
            if (exists) {
                setFranchiseError("This franchise is already added."); // Or use a more user-friendly notification
                return; // Exit if the franchise already exists
            }
    
            setSelectedFranchise((prevFranchise) => {
                const updatedFranchise = [...prevFranchise];
    
                if (editingIndex !== -1) {
                    updatedFranchise[editingIndex] = {
                        franchise: trimmedFranchise,
                    };
                    setEditingIndex(-1);
                } else {
                    if (selectedFranchise.length < 5) {
                        updatedFranchise.push({
                            franchise: trimmedFranchise,
                        });
                    }
                }
    
                return updatedFranchise;
            });
    
            setFranchise('');
            setIsFranchiseAdd(true);
        }
    };
    

    const handleFranchiseShowButtonClick = async (franchise, index) => {
        setFranchise(franchise)
        setEditingIndex(index)
    }

    const handleFranchiseCloseButtonClick = (index) => {
        const updatedFranchise = [...selectedFranchise];
        updatedFranchise.splice(index, 1);
        setSelectedFranchise(updatedFranchise);
        setIsEditProfile(true)

    };

  
    const handleInputOfficeAddressChange = (event) => {
        setIsEditProfile(true)
        setIsOfficeAddressIcon(true)
        setOfficeAddress(event.target.value);
        if (selectedOfficeAddress.length < 5) {
            let finalOfficeAddresslist;
            const OfficeAddresslist = selectedOfficeAddress.map(fr => fr.OfficeAddress).concat(event.target.value); // Add event.target.value to licenselist
            finalOfficeAddresslist = OfficeAddresslist.join(', ');

        }
        setOfficeAddrError("");
    };

    const handleOfficeAddressOnBlur = (event) => {
        if (selectedOfficeAddress.length <= 5) {
            handleAddOfficeAddress()
        }
    }

    // const handleAddOfficeAddress = () => {
    //     const trimmedOfficeAddress = OfficeAddress.trim();
    //     setIsOfficeAddressIcon(false)
    //     if (trimmedOfficeAddress !== '') {
    //         setSelectedOfficeAddress((prevOfficeAddress) => {
    //             const updatedOfficeAddress = [...prevOfficeAddress];

    //             if (editingIndex !== -1) {
    //                 updatedOfficeAddress[editingIndex] = {
    //                     OfficeAddress: trimmedOfficeAddress,
    //                 };
    //                 setEditingIndex(-1);
    //             } else {
    //                 if (selectedOfficeAddress.length < 5) {
    //                     updatedOfficeAddress.push({
    //                         OfficeAddress: trimmedOfficeAddress,

    //                     });
    //                 }
    //             }

    //             return updatedOfficeAddress;
    //         });

    //         setOfficeAddress('');
    //         setIsOfficeAddressAdd(true);

    //     }
    // };

    const handleAddOfficeAddress = () => {
        const trimmedOfficeAddress = OfficeAddress.trim();
        setIsOfficeAddressIcon(false);
    
        if (trimmedOfficeAddress !== '') {
            // Check if the Office Address already exists
            const exists = selectedOfficeAddress.some(
                (address) => address.OfficeAddress.toLowerCase() === trimmedOfficeAddress.toLowerCase()
            );
    
            if (exists) {
                setOfficeAddrError("This office address is already added."); // Or use a more user-friendly notification
                return; // Exit if the office address already exists
            }
    
            setSelectedOfficeAddress((prevOfficeAddress) => {
                const updatedOfficeAddress = [...prevOfficeAddress];
    
                if (editingIndex !== -1) {
                    updatedOfficeAddress[editingIndex] = {
                        OfficeAddress: trimmedOfficeAddress,
                    };
                    setEditingIndex(-1);
                } else {
                    if (selectedOfficeAddress.length < 5) {
                        updatedOfficeAddress.push({
                            OfficeAddress: trimmedOfficeAddress,
                        });
                    }
                }
    
                return updatedOfficeAddress;
            });
    
            setOfficeAddress('');
            setIsOfficeAddressAdd(true);
        }
    };
    

    const handleOfficeAddressShowButtonClick = async (OfficeAddress, index) => {
        setOfficeAddress(OfficeAddress)
        setEditingIndex(index)
    }

    const handleOfficeAddressCloseButtonClick = (index) => {
        const updatedOfficeAddress = [...selectedOfficeAddress];
        updatedOfficeAddress.splice(index, 1);
        setSelectedOfficeAddress(updatedOfficeAddress);
        setIsEditProfile(true)

    };


    const handleEnterKeyPress = (event, field) => {
        if (event.key === 'Enter') {
            setIsFranchiseIcon(false)
           setIsOfficeAddressIcon(false)
            setIsCertficationIcon(false)
            setIsLisenceIcon(false)
            event.preventDefault();
            if (field === 'certifications' && selectedCertifications.length <= 5) {
                handleAddCertification();
            }
            else if (field === 'license' && selectedLicense.length <= 5) {
                handleAddLicense();
            }
            else if (field === 'franchise' && selectedFranchise.length <= 5) {
                handleAddFranchise();
            }
            else if (field === 'OfficeAddress' && selectedOfficeAddress.length <= 5) {
                handleAddOfficeAddress();
            }
        }
    };
   

    const handleSaveButtonClick = async () => {
        if (serviceName === "") {
            setServiceError("Please choose your service");
        } else if (typeNames.length === 0) {
            setTypesError("Please choose your types of service");
        } else {
            console.log(selectedOfficeAddress)
            const formData = new FormData();
            formData.append('service', serviceName);
            formData.append('serviceid', serviceId);
            formData.append('profesionalname', serviceProfessionName);
            formData.append('type', typeNames);
            formData.append('typesid', typeIds);
            formData.append('certifications', JSON.stringify(selectedCertifications));
            formData.append('license', JSON.stringify(selectedLicense));
            formData.append('franchise', JSON.stringify(selectedFranchise));
            formData.append('Officeaddress',JSON.stringify(selectedOfficeAddress));
            formData.append('spToken', localStorage.getItem("spToken"));
            formData.append('insertedImages', insertedImages);
            if (inProgressImageId) {
                formData.append('inProgressImageId', inProgressImageId); // Append to formData
            }

            if (uploadImages.length > 0) {              
                for (let i = 0; i < uploadImages.length; i++) {                   
                    const imageUrl = uploadImages[i];
                    console.log(uploadImages)
                    try {
                        console.log("hiii")
                        const response = await fetch(imageUrl);
                        console.log(response)
                        if (!response.ok) {
                            console.log("hiii")
                            throw new Error('Image fetch failed');
                        }

                        const blob = await response.blob();
                        const file = new File(
                            [blob], 
                            `image_${insertedImages.length > 0 ? insertedImages.length + i + 1 : i + 1}.jpg`, 
                            { type: blob.type }
                        );
                        
                        formData.append('images', file);
                        console.log(file)
                    } catch (error) {
                        console.error("Failed to convert blob URL to file:", error);
                        return;
                    }
                }
            }

            try {
                console.log("hiii")
                setLoading(false);
                const response = await axios.post(`${API_URL}/add_sp_skills`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (response.data.success) {
                    setisAddServicePopUp(false);
                    setLoading(true);
                    fetchServiceProviderDetails()

                } else {
                    setLoading(false);
                    console.error("An error occurred:", response.data.message);
                }
            } catch (error) {
                setLoading(false);
                console.error('Error:', error.message);
            }
        }
    };
  
    const handleAddServiceButtonClick = () => {
        setisAddServicePopUp(true)
        setIsEditService(false)
        setServiceName('')
        setServiceProfessionName('')
        setServiceId('')
        setTypeNames([])
        setTypeIds([])
        setSelectedFranchise([])
        setSelectedCertifications([])
        setSelectedLicense([])
        setSelectedOfficeAddress([])
        setImages([])
        setUploadImages([])
        setInsertedImages([])
        setServiceError("")
        setTypesError("")
        setLoading(true);
        const filteredServices = service.filter(serviceItem => (
            !allService.some(allServiceItem => (
                allServiceItem.service_id === serviceItem.service_id
            ))
        ));
        setService(filteredServices);
    }

    const handleCloseAddServicePopUp = () => {
        setisAddServicePopUp(false)
        fetchServiceProviderDetails()
    }

    const handleAddImages = (event) => {
        const newImages = Array.from(event.target.files).map((file) =>
            URL.createObjectURL(file)
        );

        if (images.length + newImages.length <= 10) {
            setImages([...images, ...newImages]);            
        } else {
            const allowedImages = newImages.slice(0, 10 - images.length);
            setImages([...images, ...allowedImages]);          
        }
        setUploadImages([...newImages]);
    };

    const handleRemoveImage = (index, image) => {             
        const updatedImages = images.filter((_, i) => i !== index);
        setImages(updatedImages);
        setInsertedImages(updatedImages)
        const updatedImagesforUpload = uploadImages.filter(img => img !== image);
        setUploadImages(updatedImagesforUpload);
    };

    const handleEditServiceLinkClick = (id, name, proname) => {
        setServiceError("")
        setTypesError("")
        setisAddServicePopUp(true)
        setIsEditService(true)
        setServiceName(name);
        setServiceProfessionName(proname)
        setServiceId(id);
        setUploadImages([])
        setLoading(true);
        const selectedServiceDetails = serviceDetails.filter(service => service.services_id === id);
        const selectedServiceData = selectedServiceDetails[0]
        const typeIdsArray = selectedServiceData.type_details.map(typeDetail => typeDetail.types_id);
        const typenameArray = selectedServiceData.type_details.map(typeDetail => typeDetail.types);
        const selectedService = servicesWithTypes.find(service => service.service_id === id);
        setAllTypes(selectedService.types);
        setTypeIds(typeIdsArray);
        setTypeNames(typenameArray);

        if (selectedServiceData) {
            if (selectedServiceData.certifications.trim() !== '') {
                const certificationsList = selectedServiceData.certifications.split(',').map(cert => ({ certifications: cert.trim() }));
                setSelectedCertifications(certificationsList);
                setIsCertificationsAdd(true);
            }
            if (selectedServiceData.license.trim() !== '') {
                const licenseList = selectedServiceData.license.split(',').map(lic => ({ license: lic.trim() }));
                setSelectedLicense(licenseList);
                setIsLicenseAdd(true);
            }
            console.log(selectedServiceData.franchise)
            if (selectedServiceData.franchise.trim() !== '') {
                const franchiseList = selectedServiceData.franchise.split(',').map(fran => ({ franchise: fran.trim() }));
                setSelectedFranchise(franchiseList);
                setIsFranchiseAdd(true);
            }
            console.log(selectedServiceData)
            if (selectedServiceData.office_address.trim() !== '') {
                const OfficeAddressList = selectedServiceData.office_address.split(',').map(off => ({ OfficeAddress: off.trim() }));
                setSelectedOfficeAddress(OfficeAddressList);
                setIsOfficeAddressAdd(true);
            }           
            const imageUrls = selectedServiceData.image_details.map(imageDetail => imageDetail.image_name);
            console.log(imageUrls)
            const fullImageUrls = imageUrls.map(imageUrl => IMG_URL + imageUrl);
            setImages(fullImageUrls);
            setInsertedImages(fullImageUrls)

        }
    };

    const handleDeleteServiceLinkClick = (id) => {
        setIsDeleteServicePopUp(true)
        setDeleteServiceId(id)
    };

    const handleCloseDeleteServicePopUp = () => {
        setIsDeleteServicePopUp(false)
    };

    const handleDeleteButtonClick = async () => {
        try {
            const response = await axios.post(`${API_URL}/delete_service`, {
                serviceId: deleteServiceId,
                spToken: localStorage.getItem("spToken")
            });
            console.log(response)
            if (response.data.success === true) {
                setIsDeleteServicePopUp(false)
                fetchServiceProviderDetails()

            }
        } catch (error) {
            console.error('Error:', error.message);
        }

    };

    const handleNextButtonClick = () => {
        const isEdit = false;
        window.location.href = `/service-provider-location?edit=${isEdit}`;  
    };

    const handleCancelButtonClick = () => {
        window.location.href = "/sp-landing"  
    };
    
    

    return (
        <Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px' }}>

            <Header></Header>

            <Box sx={{
                display: 'flex', flexDirection: 'column', width: '100%',
                justifyContent: 'center', height: '100%', alignItems: 'center'
            }}>

                <Box sx={{
                    display: 'flex', flexDirection: { xs: 'column ', md: 'row' }, width: '100%', paddingBottom: '2%', gap: { xs: '16px', md: '32px' },
                    justifyContent: 'center', height: '100%', alignItems: 'flex-start', maxWidth: { xs: '90% ', md: '94%' }, paddingTop: '2%',
                }}>

                   
                     <Box sx={{display: 'flex', flexDirection: 'row', width:{ xs: '100% ', md: '25%' } , 
                    justifyContent: 'flex-start', height: '100%', alignItems:'center'}}>
                            <SpSidebar currentPage={"Services"} isEdit={isEdit}></SpSidebar>
                    </Box>

                    <Box sx={{display: 'flex', flexDirection: 'column', width:{ xs: '100% ', md: 'calc(75% - 32px)' } , 
                     borderLeft:{ xs: '0px solid #E6E6E6', md:'1px solid #E6E6E6' },  minHeight:'650px',paddingBottom:'5%',
                    justifyContent: 'flex-start', height: '100%', alignItems:'center', paddingLeft: { xs: '0px ', md:'32px' },marginTop:{md:'3.5%', xs:0}
                    }}>

                        <Box sx={{
                            display: 'flex', flexDirection: 'column', width: '100%', gap: '8px',
                            justifyContent: 'center', height: '100%', alignItems: 'center',
                        }}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'column', width: '100%', gap: '32px', paddingTop: '0%',
                                height: '98%', justifyContent: 'center', alignItems: 'center', paddingBottom: '2%',
                                 minHeight: '450px', maxHeight:'98%'
                            }}>

                            {loading ? (
                            <Box sx={{ width: '70%', textAlign: 'center' }}>                            
                             <TypographySubHeading sx={{ mt: 2 }}>
                                                          
                            </TypographySubHeading>
                            </Box>
                            ) : serviceDetails.length > 0 ? (
                                <Box sx={{
                                    width: '100%', height: 'fit-content', justifyContent: 'center', alignItems: 'left',
                                    display: 'flex', flexDirection: 'column', gap: '16px',
                                }} >
                                    {serviceDetails.map((service, index) => (
                                        <Box
                                            key={index}
                                            sx={{
                                                width: '100%',
                                                height: 'fit-content',
                                                justifyContent: 'center',
                                                borderRadius: '4px',
                                                alignItems: 'center',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '16px',
                                                border: '1px solid',
                                                borderColor: Colors.black_60
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: { xs: '90%', md: '95%' },
                                                    paddingTop: { xs: '2.5%', md: '2.5%' },
                                                    paddingBottom: { xs: '2.5%', md: '2.5%' },
                                                    display: 'flex',
                                                    height: 'fit-content',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'left',
                                                    flexDirection: 'column',
                                                    gap: { xs: '8px', md: '16px' },
                                                    maxWidth: { xs: '90%', md: '95%' }, 
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        height: 'fit-content',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        gap: '4px',
                                                    }}
                                                >
                                                    <TypographyNormal sx={{ fontSize: { xs: '18px', md: '20px' }, fontWeight: 600 }}>
                                                        {service.professional_name}
                                                    </TypographyNormal>
                                                    <Box
                                                        sx={{
                                                            width: 'fit-content',
                                                            height: 'fit-content',
                                                            justifyContent: 'right',
                                                            alignItems: 'center',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            gap: { xs: '12px', md: '16px' },
                                                        }}
                                                    >
                                                        { serviceDetails.length > 1 &&  <TypographyNormal sx={{ fontSize: '16px', cursor: 'pointer', '&:hover':{fontWeight:600, color:Colors.black_80} }}
                                                            onClick={() => handleDeleteServiceLinkClick(service.services_id)}>
                                                            Delete</TypographyNormal>}
                                                        <TypographyNormal
                                                            sx={{ fontSize: '16px', color: Colors.blue_6, cursor: 'pointer', '&:hover':{fontWeight:600, color:'#2085BE'} }}
                                                            onClick={() => handleEditServiceLinkClick(service.services_id, service.services, service.professional_name)}>
                                                            Edit
                                                        </TypographyNormal>
                                                    </Box>
                                                </Box>

                                               
                                               <Box
                                                    sx={{
                                                        width: '100%',
                                                        height: 'fit-content',
                                                        justifyContent: 'center',
                                                        alignItems: 'left',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        gap: { xs: '16px', md: '12px' } ,
                                                    }}
                                                >
                                                    {[
                                                        { label: 'Type of Services', value: service.type_details.map(item => item.types).join(', ') },
                                                        { label: 'Certifications', value: service.certifications },
                                                        { label: 'License', value: service.license },
                                                        { label: 'Franchise', value: service.franchise },
                                                        { label: 'Office address', value: service.office_address }
                                                    ]
                                                        .filter(item => item.value)
                                                        .reduce((rows, item, index) => {
                                                            if (index % 3 === 0) rows.push([]);
                                                            rows[rows.length - 1].push(item);
                                                            return rows;
                                                        }, [])
                                                        .map((row, rowIndex) => (
                                                            <Box
                                                                key={rowIndex}
                                                                sx={{
                                                                    width: '100%',
                                                                    height: 'fit-content',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'left',
                                                                    display: 'flex',
                                                                    flexDirection: { xs: 'column', md: 'row' },
                                                                    gap:{ xs: '16px', md: '24px' }  
                                                                }}
                                                            >
                                                                {row.map((item, itemIndex) => (
                                                                    <Box 
                                                                        key={itemIndex} 
                                                                        sx={{
                                                                            width:{ xs: '100%', md: '50%' } ,
                                                                            height: 'fit-content',
                                                                            justifyContent: 'center',
                                                                            alignItems: 'left',
                                                                            display: 'flex',
                                                                            flexDirection: 'column',
                                                                            gap: '4px',
                                                                            borderRight:{ xs: itemIndex < row.length - 1 ? 'none' : 'none', md: itemIndex < row.length - 1 ? '1px solid #E6E6E6' : 'none' }  // 1px border between items
                                                                        }}
                                                                    >
                                                                        <TypographyNormal sx={{ fontSize: { xs: '14px', md: '16px' }, fontWeight: 600 }}>
                                                                            {item.label}
                                                                        </TypographyNormal>
                                                                        <TypographyNormal>{item.value}</TypographyNormal>
                                                                    </Box>
                                                                ))}
                                                               
                                                                {row.length < 3 && (
                                                                    <Box
                                                                        sx={{
                                                                            width: '50%',
                                                                            height: 'fit-content'
                                                                        }}
                                                                    />
                                                                )}
                                                            </Box>
                                                        ))}
                                                </Box>


                                                {service.image_details.length > 0 && (<>
                                                     <TypographyNormal sx={{ fontSize: { xs: '14px', md: '16px' }, fontWeight: 600 }}>
                                                        Projects
                                                        </TypographyNormal>
                                                          <Box sx={{
                                        display: 'flex', alignItems: 'center', overflowX: 'auto', whiteSpace: 'nowrap',
                                         gap: '12px', padding: '8px 0px', width: 'calc(100% )','&::-webkit-scrollbar': {
                                            width: '12px', height: '8px', borderRadius: '16px',
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                            backgroundColor: Colors.black_60, borderRadius: '16px', border: '2px solid white',
                                        },
                                        '&::-webkit-scrollbar-track': {
                                            borderRadius: '16px', backgroundColor: 'none',marginLeft:'8px', marginRight:'4px'
                                        },
                                    }}>
                                        
                                      {service.image_details.slice(0, 10).map((image, index) => (  // Show max 10 images
                                                                        <Box 
                                                                            key={index} 
                                                                            sx={{ 
                                                                                width:'80px',
                                                                                height: '80px',
                                                                                flexShrink: 0  // Prevent shrinking when scrolling
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={IMG_URL + image.image_name}
                                                                                alt="projects"
                                                                                style={{
                                                                                    width: '100%', height: '100%', objectFit: 'cover',
                                                                                    borderRadius: '4px', 
                                                                                    border: '1px solid',
                                                                                    borderColor: Colors.black_60, 
                                                                                    cursor: 'pointer'
                                                                                }}
                                                                                // onClick={() => handleImageClick(image.image_name, index)}
                                                                            />
                                                                        </Box>
                                                                    ))}

                                        
                                    </Box></>)}

                                            </Box>
                                        </Box>
                                    ))}

                                </Box>
                                ) : (
                                  <img src={AddService} alt="Add service" style={{ width:{xs:'40%', md:'20%'} , height: {xs:'40%', md:'20%'}}} />                               
                               )}
                                <ButtonWhite onClick={handleAddServiceButtonClick} sx={{ width:{xs:'36%', md:'20%'} ,borderColor: Colors.hovering_blue,
                                marginTop: serviceDetails.length === 0 ? { xs: '-7.5%', md: '-2.5%' } : { xs: '0px', md: '0px' }  }}>Add service</ButtonWhite>
                                {serviceDetails.length > 0 && <Box sx={{
                                    width: '100%', height: 'fit-content', justifyContent: 'right', alignItems: 'center',
                                    display: 'flex', flexDirection: 'row', gap: '16px',paddingTop:'24px' }} >
                                {!isEdit && <ButtonBlue onClick={handleNextButtonClick} sx={{ width: '20%' }}>Next : Location</ButtonBlue>}
                                {/* {isEdit && <ButtonBlue onClick={handleCancelButtonClick} sx={{ width: '20%' }}>Cancel</ButtonBlue>} */}
                                </Box>}
                            </Box>
                        </Box>

                    </Box>
                </Box>
            </Box>

        

           

            {isAddServicePopUp && <Box sx={{
                position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)',
                display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '2',maxWidth:'100%', 

            }}>
                <Box sx={{
                    width: '100%', height: '100%', gap: '5px',
                    justifyContent: 'center', alignItems: 'center', position: 'fixed',
                    display: 'flex', flexDirection: 'column',
                }}>
                    <Box sx={{
                        width: { md: '55%', sm: '55%', xs: '90%' }, height: '36px',
                        justifyContent: 'right', alignItems: 'right', display: 'flex',
                    }}>
                        <IconButton onClick={handleCloseAddServicePopUp} sx={{
                            position: 'fixed', width: '36px', height: '36px',
                        }} >
                            <CloseOutlinedIcon sx={{
                                width: '36px', height: '36px', color: Colors.black_60, '&:hover': {
                                    width: '40px', height: '40px', color: Colors.black_60, backgroundColor: 'none',
                                    background: 'none'
                                },
                            }} ></CloseOutlinedIcon>
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            width: { md: '55%', sm: '55%', xs: '90%' }, height: 'fit-content',
                            backgroundColor: Colors.white, borderRadius: '4px ', boxShadow: 'none',
                            zIndex: '3', alignItems: 'center', justifyContent: 'center', display: 'flex',
                        }}>

                        <Box sx={{
                            display: 'flex', flexDirection: 'column', width: '100%', gap: '16px',maxWidth:'90%',
                            justifyContent: 'center', alignItems: 'center', height: '90%', paddingTop:'5%' ,paddingBottom:'5%'
                        }}>
                            <Box sx={{
                            display: 'flex', flexDirection:{ xs:'column', md:'row'}, width: '100%', gap: '8px',
                            justifyContent: 'center', alignItems: 'center', height: '100%',  
                            }}>
                                <Box sx={{
                                display: 'flex', flexDirection: 'column', width: { xs: '100%', md: '50%' }, gap: {md:'8px', xs:'0px'},
                                justifyContent: 'center', alignItems: 'flex-start', height: '100%', maxWidth:{ xs: '100%', md: '50%' },
                                 
                                }}> 
                                <TypographyNormal>
                                            Services*
                                        </TypographyNormal>

                                        <SelectComponent placeholder="Add your services" 
                                            value={serviceId} disabled={isEditService} sx={{width:'100%' ,maxWidth:'100%' ,
                                                height: '100%',}}
                                            onChange={(event) => handleServiceChange(event.target.value, event.target.textContent)}
                                            options={service.map(result => ({ value: result.service_id, label: result.service_name }))}
                                            error={!!serviceError} >
                                        </SelectComponent>
                                        {serviceError &&
                                            <TypographyError>
                                                {serviceError}</TypographyError>}

                                </Box>                               
                                <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: { xs: '100%', md: '50%' },
                                    gap:  {md:'8px', xs:'0px'},
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                    height: '100%',
                                    maxWidth: { xs: '100%', md: '50%' },
                                    
                                    position: 'relative', // Set position relative here
                                }}>
                                <TypographyNormal>
                                    Type of services*
                                </TypographyNormal>
                                <TextfieldNormal
                                    disabled={!serviceName}
                                    variant="outlined"
                                    onClick={() => {
                                    if (serviceName) {
                                        handleShowTypes(); // Only call if the button is enabled
                                    }
                                    }}
                                    placeholder="Add your type of services"
                                    value={typeNames}
                                    error={!!typesError}                                   
                                    InputProps={{
                                        sx: {
                                            paddingRight: '0 !important', // Remove right padding
                                          },
                                    endAdornment: (
                                        <IconButton disabled={!serviceName}>
                                        {isTypeShow ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                        </IconButton>
                                    ),
                                    }}
                                />

                                {isTypeShow && (
                                    <Box
                                    ref={searchRef}
                                    sx={{
                                        width: { md: '95%', sm: '95%', xs: '90%' },
                                        display: 'flex',
                                        flexDirection: 'column', // Use column layout for dropdown items
                                        position: 'absolute', // Keep position absolute
                                        top: 'calc(100% + 8px)', // Adjust this value to position directly below the text field
                                        left: 0, // Align to the left
                                        zIndex: 5, // Ensure it stays on top
                                        padding: '8px',
                                        borderRadius: '4px',
                                        maxHeight: '168px',
                                        overflowY: 'auto',
                                        boxShadow: '0px 4px 4px 0px #00000025',
                                        border: '1px solid',
                                        borderColor: Colors.black_60,
                                        background: Colors.white,
                                        '&::-webkit-scrollbar': {
                                        width: '12px',
                                        height: '8px', // Added height for horizontal scrollbars
                                        borderRadius: '4px',
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                        backgroundColor: Colors.black_60,
                                        borderRadius: '4px',
                                        border: '2px solid white',
                                        },
                                        '&::-webkit-scrollbar-track': {
                                        borderRadius: '4px',
                                        backgroundColor: Colors.white,
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                        },
                                    }}
                                    >
                                    {allTypes.map((result, index) => (
                                        <Box
                                        key={index}
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            marginBottom: '4px',
                                        }}
                                        >
                                        <Box
                                            sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                            '&:hover': {
                                                backgroundColor: Colors.quinary,
                                            },
                                            }}
                                            onClick={() => handleCheckboxChange(
                                            { target: { checked: !typeNames.includes(result.type_name), value: result.type_name } },
                                            result.type_id
                                            )}
                                        >
                                            <Checkbox
                                            size="small"
                                            onClick={(event) => event.stopPropagation()}
                                            value={result.type_name}
                                            checked={typeNames.includes(result.type_name)}
                                            onChange={(event) => handleCheckboxChange(event, result.type_id)}
                                            sx={{
                                                '&.Mui-checked': {
                                                color: '#0026CC',
                                                },
                                                padding: '4px 8px',
                                                marginRight: '8px',
                                            }}
                                            />
                                            <TypographyNormal
                                            sx={{
                                                fontSize: { md: '14px', sm: '14px', xs: '12px' },
                                                color: Colors.black_70,
                                                whiteSpace: 'normal',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                            >
                                            {result.type_name}
                                            </TypographyNormal>
                                        </Box>
                                        </Box>
                                    ))}
                                    </Box>
                                )}
                                {typesError && (
                                    <TypographyError>
                                    {typesError}
                                    </TypographyError>
                                )}
                                </Box>

                            </Box>

                            <Box sx={{
                            display: 'flex', flexDirection:{ xs:'column', md:'row'}, width: '100%', gap: '8px',
                            justifyContent: 'center', alignItems: 'center', height: '100%',  
                            }}>
                               <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: { xs: '100%', md: '50%' },
                                    gap: {md:'8px', xs:'0px'},
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    height: '100%',
                                    maxWidth: { xs: '100%', md: '49.5%' },
                                    
                                }}
                                >
                                <div>
                                            <TypographyNormal sx={{ color: Colors.black_80 }}> Certifications
                                                <TypographyNormal sx={{ color: Colors.black_60, display: 'inline' }}> (Optional, maximum 5)
                                                </TypographyNormal>
                                            </TypographyNormal>
                                        </div>
                                        <TextfieldNormal
                                            variant="outlined"
                                            placeholder="Add your certifications"
                                            value={certifications} error={!!certificationError}
                                            onChange={(event) => handleInputCertificationsChange(event)}
                                            onBlur={handleCertificationOnBlur}
                                            onKeyDown={(event) => handleEnterKeyPress(event, 'certifications')}
                                            InputProps={{ sx: {
                                                paddingRight: '0 !important', // Remove right padding
                                              },
                                                endAdornment: (
                                                    <IconButton
                                                        disabled={selectedCertifications.length >= 5 || !isCertficationIcon}
                                                        onClick={() => handleAddCertification()}>
                                                        <AddIcon style={{ color: selectedCertifications.length >= 5 || !isCertficationIcon ? Colors.default : Colors.primary, fontSize:{md:'18px', xs:'22px'} }} />
                                                    </IconButton>
                                                ),
                                            }}
                                        />
                                        {isCertificationsAdd && (
                                            <Box
                                                sx={{
                                                    display: 'flex', flexDirection: 'row', gap: '8px', flexWrap: 'nowrap',
                                                     maxWidth: '99%',marginTop:{md:'0px',xs:'12px'},
                                                    '&::-webkit-scrollbar': {
                                                        display: 'none',
                                                    },
                                                }}
                                            >
                                                {selectedCertifications.map((certification, index) => (
                                                    <Chip
                                                        key={index}
                                                        label={certification.certifications}
                                                        onDelete={() => handleCertificationsCloseButtonClick(index)}
                                                        onClick={() => handleCertificationsShowButtonClick(certification.certifications, index)}
                                                        deleteIcon={<HighlightOffIcon />}
                                                        sx={{
                                                            backgroundColor: Colors.quinary,
                                                            borderRadius: '4px',
                                                            border: '1px solid #C5C5C5',
                                                            gap: '8px',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            cursor: 'pointer',
                                                            '&:hover': {
                                                                backgroundColor: Colors.quaternary,
                                                            },
                                                        }}
                                                    />
                                                ))}
                                            </Box>
                                        )}
                                        {certificationError && <TypographyError>{certificationError}</TypographyError>}

                                </Box>
                                <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: { xs: '100%', md: '50%' },
                                    gap: {md:'8px', xs:'0px'},
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    height: '100%',
                                    maxWidth: { xs: '100%', md: '49.5%' },
                                    
                                }}
                                >
                                <div>
                                            <TypographyNormal sx={{ color: Colors.black_80 }}> License
                                                <TypographyNormal sx={{ color: Colors.black_60, display: 'inline' }}> (Optional, maximum 5)
                                                </TypographyNormal>
                                            </TypographyNormal>
                                        </div>
                                        <TextfieldNormal
                                            variant="outlined"
                                            placeholder="Add your license"
                                            value={license} error={!!liseceError}
                                            onChange={(event) => handleInputLicenseChange(event)}
                                            onBlur={handleLicenseOnBlur}
                                            onKeyDown={(event) => handleEnterKeyPress(event, 'license')}

                                            InputProps={{
                                                sx: {
                                                    paddingRight: '0 !important', // Remove right padding
                                                  },
                                                endAdornment: (
                                                    <IconButton disabled={selectedLicense.length === 5 || !isLisenceIcon}
                                                        onClick={() => handleAddLicense()}>
                                                        <AddIcon style={{ color: selectedLicense.length === 5 || !isLisenceIcon ? Colors.default : Colors.primary, fontSize:{md:'18px', xs:'22px'} }} />
                                                    </IconButton>
                                                ),
                                            }}
                                        />
                                        {isLicenseAdd && (
                                            <Box
                                                sx={{
                                                    display: 'flex',marginTop:{md:'0px', xs:'12px'},
                                                    flexDirection: 'row',
                                                    gap: '8px',
                                                    flexWrap: 'nowrap', // Ensure chips do not wrap to the next line
                                                    overflowX: 'auto', // Allow horizontal scrolling if chips exceed container width
                                                    maxWidth: '100%', // Ensure the container does not overflow horizontally
                                                    '&::-webkit-scrollbar': {
                                                        display: 'none', // Hide scrollbar for cleaner appearance
                                                    },
                                                }}
                                            >
                                                {selectedLicense.map((license, index) => (
                                                    <Chip
                                                        key={index}
                                                        label={license.license}
                                                        onDelete={() => handleLicenseCloseButtonClick(index)}
                                                        onClick={() => handleLicenseShowButtonClick(license.license, index)}
                                                        deleteIcon={<HighlightOffIcon />}
                                                        sx={{
                                                            backgroundColor: Colors.quinary,
                                                            borderRadius: '4px',
                                                            border: '1px solid #C5C5C5',
                                                            gap: '8px',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            cursor: 'pointer',
                                                            '&:hover': {
                                                                backgroundColor: Colors.quaternary,
                                                            },
                                                        }}
                                                    />
                                                ))}
                                            </Box>
                                        )}
                                        {liseceError && <TypographyError>{liseceError}</TypographyError>}

                                </Box>

                            </Box>

                            <Box sx={{
                            display: 'flex', flexDirection:{ xs:'column', md:'row'}, width: '100%', gap: '8px',
                            justifyContent: 'center', alignItems: 'center', height: '100%',  
                            }}>
                               <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: { xs: '100%', md: '50%' },
                                    gap: {md:'8px', xs:'0px'},
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                    height: '100%',
                                    maxWidth: { xs: '100%', md: '49.5%' },
                                    
                                }}
                                >
                               <div>
                                            <TypographyNormal sx={{ color: Colors.black_80 }}> Franchise
                                                <TypographyNormal sx={{ color: Colors.black_60, display: 'inline' }}> (Optional, maximum 5)
                                                </TypographyNormal>
                                            </TypographyNormal>
                                        </div>
                                        <TextfieldNormal
                                            variant="outlined"
                                            placeholder="Add your franchise"
                                            value={franchise} error={!!franchiseError}
                                            onChange={(event) => handleInputFranchiseChange(event)}
                                            onBlur={handleFranchiseOnBlur}
                                            onKeyDown={(event) => handleEnterKeyPress(event, 'franchise')}

                                            InputProps={{
                                                sx: {
                                                    paddingRight: '0 !important', // Remove right padding
                                                  },
                                                endAdornment: (
                                                    <IconButton disabled={selectedFranchise.length === 5 || !isFranchiseIcon}
                                                        onClick={() => handleAddFranchise()}>
                                                        <AddIcon style={{ color: selectedFranchise.length === 5 || !isFranchiseIcon ? Colors.default : Colors.primary, fontSize:{md:'18px', xs:'22px'} }} />
                                                    </IconButton>
                                                ),
                                            }}
                                        />
                                        {isFranchiseAdd && (
                                            <Box
                                                sx={{
                                                    display: 'flex',marginTop:{md:'0px', xs:'12px'},
                                                    flexDirection: 'row',
                                                    gap: '8px',
                                                    flexWrap: 'nowrap', // Ensure chips do not wrap to the next line
                                                    overflowX: 'auto', // Allow horizontal scrolling if chips exceed container width
                                                    maxWidth: '100%', // Ensure the container does not overflow horizontally
                                                    '&::-webkit-scrollbar': {
                                                        display: 'none', // Hide scrollbar for cleaner appearance
                                                    },
                                                }}
                                            >
                                                {selectedFranchise.map((franchise, index) => (
                                                    <Chip
                                                        key={index}
                                                        label={franchise.franchise}
                                                        onDelete={() => handleFranchiseCloseButtonClick(index)}
                                                        onClick={() => handleFranchiseShowButtonClick(franchise.franchise, index)}
                                                        deleteIcon={<HighlightOffIcon />}
                                                        sx={{
                                                            backgroundColor: Colors.quinary,
                                                            borderRadius: '4px',
                                                            border: '1px solid #C5C5C5',
                                                            gap: '8px',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            cursor: 'pointer',
                                                            '&:hover': {
                                                                backgroundColor: Colors.quaternary,
                                                            },
                                                        }}
                                                    />
                                                ))}
                                            </Box>
                                        )}
                                         {franchiseError && <TypographyError>{franchiseError}</TypographyError>}

                                </Box>
                                <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: { xs: '100%', md: '50%' },
                                    gap: {md:'8px', xs:'0px'},
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    height: '100%',
                                    maxWidth: { xs: '100%', md: '49.5%' },
                                    
                                }}
                                >
                                <div>
                                            <TypographyNormal sx={{ color: Colors.black_80 }}> Office address
                                            <TypographyNormal sx={{ color: Colors.black_60, display: 'inline' }}> (Optional, maximum 5)
                                                </TypographyNormal>
                                            </TypographyNormal>
                                        </div>
                                        <TextfieldNormal
                                            variant="outlined"
                                            placeholder="Add your office address"
                                            value={OfficeAddress} error={!!officeAddrError}
                                            onChange={(event) => handleInputOfficeAddressChange(event)}
                                            onBlur={handleOfficeAddressOnBlur}
                                            onKeyDown={(event) => handleEnterKeyPress(event, 'OfficeAddress')}

                                            InputProps={{
                                                sx: {
                                                    paddingRight: '0 !important',
                                                  },
                                                endAdornment: (
                                                    <IconButton disabled={selectedOfficeAddress.length === 5 || !isOfficeAddressIcon}
                                                        onClick={() => handleAddOfficeAddress()}>
                                                        <AddIcon style={{ color: selectedOfficeAddress.length === 5 || !isOfficeAddressIcon ? Colors.default : Colors.primary, fontSize:{md:'18px', xs:'22px'} }} />
                                                    </IconButton>
                                                ),
                                            }}
                                        />
                                        {isOfficeAddressAdd && (
                                            <Box
                                                sx={{
                                                    display: 'flex',marginTop:{md:'0px', xs:'12px'},
                                                    flexDirection: 'row',
                                                    gap: '8px',
                                                    flexWrap: 'nowrap', // Ensure chips do not wrap to the next line
                                                    overflowX: 'auto', // Allow horizontal scrolling if chips exceed container width
                                                    maxWidth: '100%', // Ensure the container does not overflow horizontally
                                                    '&::-webkit-scrollbar': {
                                                        display: 'none', // Hide scrollbar for cleaner appearance
                                                    },
                                                }}
                                            >
                                                {selectedOfficeAddress.map((OfficeAddress, index) => (
                                                    <Chip
                                                        key={index}
                                                        label={OfficeAddress.OfficeAddress}
                                                        onDelete={() => handleOfficeAddressCloseButtonClick(index)}
                                                        onClick={() => handleOfficeAddressShowButtonClick(OfficeAddress.OfficeAddress, index)}
                                                        deleteIcon={<HighlightOffIcon />}
                                                        sx={{
                                                            backgroundColor: Colors.quinary,
                                                            borderRadius: '4px',
                                                            border: '1px solid #C5C5C5',
                                                            gap: '8px',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            cursor: 'pointer',
                                                            '&:hover': {
                                                                backgroundColor: Colors.quaternary,
                                                            },
                                                        }}
                                                    />
                                                ))}
                                            </Box>
                                        )}
                                        {officeAddrError && <TypographyError>{officeAddrError}</TypographyError>}

                                </Box>

                            </Box>

                            <Box sx={{
                            display: 'flex', flexDirection:'column', width: '100%', gap: {md:'8px', xs:'0px'},
                            justifyContent: 'center', alignItems: 'flex-start', height: '100%',  
                            }}>
                              <TypographyNormal > Add your works<span style={{ color: Colors.black_60 }}>(Optional, maximum 10)</span>
                                    </TypographyNormal>

                                    <Box sx={{
                                        display: 'flex', alignItems: 'center', overflowX: 'auto', whiteSpace: 'nowrap',borderRadius:'4px',
                                        border: '1px solid #ccc', gap: '11.8px', padding: '8px', width: 'calc(100% - 18px)',
                                        '&::-webkit-scrollbar': {
                                            width: '12px', height: '8px', borderRadius: '16px',
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                            backgroundColor: Colors.black_60, borderRadius: '16px', border: '2px solid white',
                                        },
                                        '&::-webkit-scrollbar-track': {
                                            borderRadius: '16px', backgroundColor: 'none',
                                        },
                                    }}>
                                        {images.map((image, index) => (
                                            <Box key={index} sx={{ position: 'relative', width: '58px', height: '58px', flexShrink: 0 }}>
                                                <img src={image}
                                                    alt={`uploaded-${index}`}
                                                    style={{
                                                        width: '100%', height: '100%', objectFit: 'cover', borderRadius: '4px',
                                                        border: '1px solid', borderColor: Colors.black_60
                                                    }} />
                                                <IconButton
                                                    size="small"
                                                    sx={{
                                                        position: 'absolute', top: '-5px', right: '-5px',
                                                        backgroundColor: Colors.black_60, borderRadius: '50%', width: '16px', height: '16px',
                                                        '&:hover': { backgroundColor: Colors.black_60, borderRadius: '50%', width: '16px', height: '16px', }
                                                    }}
                                                    onClick={() => handleRemoveImage(index, image)}
                                                >
                                                    <CloseIcon sx={{ fontSize: '12px' }} />
                                                </IconButton>
                                            </Box>
                                        ))}

                                        {images.length < 10 && (
                                            <IconButton
                                                component="label"
                                                sx={{
                                                    width: '58px', height: '58px', border: '1px solid', display: 'flex',
                                                    alignItems: 'center', justifyContent: 'center', borderRadius: '8px', borderRadius: '4px',
                                                    flexShrink: 0, background: Colors.grey_30, borderColor: Colors.grey_30,
                                                }} >
                                                <AddPhotoAlternateIcon fontSize="large" />
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    multiple
                                                    hidden
                                                    onChange={handleAddImages} />
                                            </IconButton>
                                        )}
                                    </Box>

                            </Box>

                            <Box sx={{
                            display: 'flex', flexDirection:'column', width: '100%', gap: '8px',
                            justifyContent: 'center', alignItems: 'flex-end', height: '100%',  
                            }}>
                              <Box sx={{
                                        width: { xs: '60%', md: '30%' }, flexDirection: 'row', justifyContent: 'center', display: 'flex',
                                        alignItems: 'center', gap: { xs: '8px', md: '16px' }
                                    }}>
                                        <ButtonWhite disabled={!loading} onClick={handleCloseAddServicePopUp} >Cancel</ButtonWhite>
                                        <ButtonBlue disabled={!loading} onClick={handleSaveButtonClick} >Save</ButtonBlue>
                                    </Box>

                            </Box>
                       
                            
                        </Box>
                    </Box>
                </Box>
            </Box>}

            {isDeleteServicePopUp && 
            <Box sx={{
                position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)',
                display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '2',

            }}>
                <Box sx={{
                    width: '100%', height: '100%', gap: '5px',
                    justifyContent: 'center', alignItems: 'center', position: 'fixed',
                    display: 'flex', flexDirection: 'column',
                }}>
                    <Box sx={{
                        width: { md: '20%', sm: '25%', xs: '85%' }, height: '36px',
                        justifyContent: 'right', alignItems: 'right', display: 'flex',
                    }}>
                        <IconButton onClick={handleCloseDeleteServicePopUp} sx={{
                            position: 'fixed', width: '36px', height: '36px',
                        }} >
                            <CloseOutlinedIcon sx={{
                                width: '36px', height: '36px', color: Colors.black_60, '&:hover': {
                                    width: '40px', height: '40px', color: Colors.black_60, backgroundColor: 'none',
                                    background: 'none'
                                },
                            }} ></CloseOutlinedIcon>
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            width: { md: '20%', sm: '25%', xs: '85%' }, height:{ xs: '25%', md: '30%' } ,
                            backgroundColor: Colors.white, border: '1px solid', padding:{ xs: '15%', md: '2%' } ,
                            borderColor: Colors.white, borderRadius: '4px ', boxShadow: 'none',
                            boxSizing: 'border-box', zIndex: '3', alignItems: 'center',
                        }}>

                        <Box sx={{
                            display: 'flex', flexDirection: 'column', width: '100%', gap: '16px',
                            justifyContent: 'center', alignItems: 'center', height: '100%',
                        }}>

                            <Box sx={{
                                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                                width: '100%', height: '100%', gap: '16px'
                            }}>
                                {/* <TypographySubHeading sx={{fontSize:'18px',textAlign: 'center' }}>
                                Delete
                                </TypographySubHeading> */}
                                <TypographyNormal sx={{ textAlign: 'center' }}>
                                    Are you sure do you want to delete this service?
                                </TypographyNormal>


                                <Box sx={{
                                    width: '100%', flexDirection: 'row', justifyContent: 'right', display: 'flex',
                                    alignItems: 'center', gap: { xs: '8px', md: '16px' }, paddingTop: { xs: '8px', md: '16px' }
                                }}>
                                    <Box sx={{
                                        width: '100%', flexDirection: 'row', justifyContent: 'center', display: 'flex',
                                        alignItems: 'center', gap: { xs: '8px', md: '16px' }
                                    }}>
                                        <ButtonWhite onClick={handleCloseDeleteServicePopUp} >Cancel</ButtonWhite>
                                        <ButtonBlue onClick={handleDeleteButtonClick} >Delete</ButtonBlue>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            }

        </Grid>
    )
}
export default AddSpService;