import React from 'react';
import { Navigate } from 'react-router-dom';

const PrintersAssociateProtectedRoute = ({ element, ...rest }) => {
  const printersAssociateToken = localStorage.getItem('printersAssociateToken');

  return printersAssociateToken ? (
    element
  ) : (
    <Navigate to="/printers-associate-login" replace />
  );
};

export default PrintersAssociateProtectedRoute;
