
import React, { useEffect, useState, useRef } from 'react';
import { Grid, Box, Avatar, Button, IconButton, InputAdornment, Breadcrumbs, TextField } from "@mui/material";
import TypographySubHeading from "../Components/TypographySubHeading";
import TypographyHeading from "../Components/TypographyHeading";
import TypographyNormal from "../Components/TypographyNormal";
import Colors from '../Components/Colors';
import TextfieldNormal from '../Components/TextfieldNormal';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ButtonBlue from '../Components/ButtonBlue';
import TypographyError from '../Components/TypographyError';
import axios from "axios";
import { API_URL } from '../Components/Constants';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import ButtonWhite from '../Components/ButtonWhite';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { IMG_URL } from '../Components/Constants';
import SpSidebar from '../Components/SpSidebar';
import { useLocation } from 'react-router-dom';
import { GoogleMap, useLoadScript, MarkerF, Circle, Autocomplete } from '@react-google-maps/api';
import AssociateHeader from '../Components/AssociateHeader';
import AssociateSidebar from '../Components/AssociateSidebar';
import PrintersAssociateHeader from '../Components/PrintersAssociateHeader';





const libraries = ['places'];

const mapContainerStyle = {
  width: '100%',
  height: '100%',
};



function AssociateLocation() {

  const locationurl = useLocation();
  const params = new URLSearchParams(locationurl.search);
  const isEdit = params.get('edit') === 'true';
  console.log(isEdit);
  const associateToken = localStorage.getItem('associateToken')
  const paToken = localStorage.getItem('printersAssociateToken')
  const [location, setLocation] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedLatLng, setSelectedLatLng] = useState([43.732506485371125, -79.76642535889613]);
  const [selectedLatLngFromDb, setSelectedLatLngFromDb] = useState([43.732506485371125, -79.76642535889613]);
  const [autocomplete, setAutocomplete] = useState(null);
  const [isPlaceEdit, setIsPlaceEdit] = useState(false);
  const [isMapReady, setIsMapReady] = useState(false);
  const [choosedLocationmap, setChoosedLocationmap] = useState(null);
  const [lastSelectedLocation, setLastSelectedLocation] = useState(null);
  const [choosedLocationpopup, setChoosedLocationpopup] = useState(null);
  const [choosedLocation, setChoosedLocation] = useState(null);
  const [serviceProviderDetails, setServiceProviderDetails] = useState([]);
  const [zipCode, setZipCode] = useState("");
  const [province, setProvince] = useState("");
  console.log(choosedLocationmap);
  console.log(lastSelectedLocation);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyAv9_19m7eWD1EwkYrdG5VoBCIXQ2jUmDs',
    libraries,
  });

  const fetchSpData = async () => {
    try {
      const response = await axios.post(`${API_URL}/select_sp_details_with_spId`, {
        spId: localStorage.getItem("ASPID")
      });
      console.log(response.data.result.result)
      setServiceProviderDetails(response.data.result.result)
      console.log(response.data.result.result.city)
      if (response.data.result.result.city) {
        setLocation(response.data.result.result.city);
        setSelectedLocation(response.data.result.result.city);
        setLastSelectedLocation(response.data.result.result.city)
        console.log(response.data.result.result.city)
        const newLatLng = [response.data.result.result.latitude, response.data.result.result.longitude];
        console.log(newLatLng)
        setSelectedLatLng(newLatLng);
        setSelectedLatLngFromDb(newLatLng);
        localStorage.setItem("SpSelectedLat", response.data.result.result.latitude);
        localStorage.setItem("SpSelectedLng", response.data.result.result.longitude);

      } else {
        const fetchLocation = async () => {
          try {
            const response = await fetch('https://ipapi.co/json/');
            const data = await response.json();
            const city = data.city;
            const province = data.region;
            const zipCode = data.postal;
            setLocation(city);
            setZipCode(data.postal)
            setProvince(data.region)
            const latitude = data.latitude;
            const longitude = data.longitude;
            setSelectedLatLng([data.latitude, data.longitude]);
            setSelectedLocation(data.city)
            setSelectedLatLngFromDb([data.latitude, data.longitude]);
            localStorage.setItem("SpSelectedLat", latitude);
            localStorage.setItem("SpSelectedLng", longitude);
          } catch (error) {
            console.error('Error fetching location:', error);
          }
        };
        fetchLocation();
      }

    } catch (error) {
      console.error('Error fetching data:', error);

    }
    setIsMapReady(true);
  };


  useEffect(() => {
    if (localStorage.getItem("ASPID")) {
      fetchSpData();
    }
  }, []);


  const onLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const AutocompleteOptions = {
    componentRestrictions: { country: 'CA' },
  };

  const getCityNameFromLatLng = async (lat, lng) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyAv9_19m7eWD1EwkYrdG5VoBCIXQ2jUmDs`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.status === 'OK' && data.results.length > 0) {
        for (const result of data.results) {
          for (const component of result.address_components) {
            if (component.types.includes('locality')) {
              return component.long_name;
            }
          }
        }

        for (const result of data.results) {
          for (const component of result.address_components) {
            if (component.types.includes('administrative_area_level_1')) {
              return component.long_name;
            }
          }
        }
      } else {
        console.error('Error fetching city name:', data.status, data.error_message || '');
        return null;
      }
    } catch (error) {
      console.error('Error fetching city name:', error);
      return null;
    }
  };

  useEffect(() => {
    // Handle logic for back button press
    const handleBackButton = (event) => {
      console.log('Back button pressed');
      handleCancelButtonClick(); // Your custom function
    };

    // Add a listener for the popstate event
    window.addEventListener('popstate', handleBackButton);

    // Manipulate the history stack to enable popstate behavior
    window.history.pushState(null, '', window.location.href);

    return () => {
      // Cleanup: remove the listener when the component is unmounted
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [isEdit, selectedLatLngFromDb]);



  const onPlaceChanged = async () => {
    if (autocomplete !== null) {
      let city = 'Location not found';
      let province = '';
      let zipCode = '';

      const place = autocomplete.getPlace();

      if (place.geometry) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        const newLatLng = [lat, lng];
        setSelectedLatLng(newLatLng);
        setIsPlaceEdit(true);
        localStorage.setItem("SpSelectedLat", lat);
        localStorage.setItem("SpSelectedLng", lng);

        let cityFound = false;

        place.address_components.forEach(component => {
          if (component.types.includes('locality')) {
            city = component.long_name;
            cityFound = true;
          }
          if (component.types.includes('administrative_area_level_1')) {
            province = component.long_name;
          }
          if (component.types.includes('postal_code')) {
            zipCode = component.long_name;
          }
        });

        if (!cityFound) {
          city = await getCityNameFromLatLng(lat, lng);
        }

        // Fetch detailed data if postal code is missing
        if (!zipCode) {
          const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyAv9_19m7eWD1EwkYrdG5VoBCIXQ2jUmDs`;
          const response = await fetch(url);
          const data = await response.json();

          if (data.status === 'OK' && data.results.length > 0) {
            const addressComponents = data.results[0].address_components;
            const postalCodeComponent = addressComponents.find(component => component.types.includes('postal_code'));
            if (postalCodeComponent) {
              zipCode = postalCodeComponent.long_name;
            }
          }
        }

        setChoosedLocationpopup(city);
        setSelectedLocation(city);
        setLastSelectedLocation(city);
        setChoosedLocationmap(city);
        setChoosedLocation(choosedLocationmap);

        setLocation(city)
        setProvince(province)
        setZipCode(zipCode)
        localStorage.setItem("SpSelectedProvince", province);
        localStorage.setItem("SpSelectedZipCode", zipCode);
      } else {
        console.log('No geometry found for the selected place.');
      }
    } else {
      console.log('Autocomplete is null');
    }
  };


  const findNearestCity = async (location) => {
    const url = `https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${location.lat},${location.lng}&radius=5000&type=locality&key=AIzaSyAv9_19m7eWD1EwkYrdG5VoBCIXQ2jUmDs`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.status === 'OK' && data.results.length > 0) {
        return data.results[0].name;
      } else {
        console.error('Error fetching nearest city:', data.status);
        return null;
      }
    } catch (error) {
      console.error('Error fetching nearest city:', error);
      return null;
    }
  };


  const handleMapClick = async (event) => {
    try {
      const newLatLng = [event.latLng.lat(), event.latLng.lng()];
      setSelectedLatLng(newLatLng);
      setChoosedLocationmap(event.latLng);
      setIsPlaceEdit(true);

      const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${event.latLng.lat()},${event.latLng.lng()}&key=AIzaSyAv9_19m7eWD1EwkYrdG5VoBCIXQ2jUmDs`;

      const response = await fetch(url);
      const data = await response.json();

      if (data.status === 'OK' && data.results.length > 0) {
        let city = 'Location not found';
        let province = '';
        let zipCode = '';

        const addressComponents = data.results[0].address_components;

        const locality = addressComponents.find(
          (component) => component.types.includes('locality')
        );
        const administrativeArea = addressComponents.find(
          (component) => component.types.includes('administrative_area_level_1')
        );
        const postalCode = addressComponents.find(
          (component) => component.types.includes('postal_code')
        );

        if (locality) {
          city = locality.long_name;
        } else {
          const nearestCity = await findNearestCity(data.results[0].geometry.location);
          if (nearestCity) {
            city = nearestCity;
          }
        }

        if (administrativeArea) {
          province = administrativeArea.long_name;
        }

        if (postalCode) {
          zipCode = postalCode.long_name;
        }

        const lat = event.latLng.lat();
        const lng = event.latLng.lng();

        setLastSelectedLocation(city);
        setChoosedLocationpopup(city);
        setSelectedLocation(city);
        setProvince(province)
        setZipCode(zipCode)
        setLocation(city)
        localStorage.setItem("SpSelectedProvince", province);
        localStorage.setItem("SpSelectedZipCode", zipCode);
      } else {
        console.error('Error fetching location details:', data.error_message || 'No results found');
      }
    } catch (error) {
      console.error('Error in handleMapClick:', error.message);
    }
  };


  useEffect(() => {
    const storedLat = parseFloat(localStorage.getItem("SpSelectedLat"));
    const storedLng = parseFloat(localStorage.getItem("SpSelectedLng"));

    if (!isNaN(storedLat) && !isNaN(storedLng)) {
      setSelectedLatLng([storedLat, storedLng]);
    }



  }, []);
  useEffect(() => {
    // Ensure the map only renders after the page is fully loaded
    if (isLoaded) {
      setIsMapReady(true);
    }
    // window.location.reload()
  }, [isLoaded]);


  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading map...</div>;
  }

  const handleSaveButtonClick = async () => {
    const spid = localStorage.getItem("ASPID");
    const printersAssociateToken = localStorage.getItem("printersAssociateToken");
    const associateToken = localStorage.getItem("associateToken");
    console.log("spid,patocken", spid, printersAssociateToken)
    console.log(localStorage.getItem("ASPID"))
    try {
      const response = await axios.post(`${API_URL}/add_location_associate`, {
        city: selectedLocation,
        latitude: selectedLatLng[0],
        longitude: selectedLatLng[1],
        spId: spid,
        tocken: associateToken,
        patocken: printersAssociateToken
      });
      console.log("response from location", response)
      if (response.data.success === true) {
        if (isEdit) {
          if (localStorage.getItem("printersAssociateToken")) {
            window.location.href = `/printers-associate-landing`;
          } else if (localStorage.getItem("associateToken")) {
            window.location.href = `/associate-landing`;
          } else {
            window.location.href = `/`;  // Redirect to login if neither token is available
          }

        } else {
          const isEdit = false;
          console.log()
          window.location.href = `/associate-service?edit=${isEdit}`;
        }
      }
    } catch (error) {
      console.error('Error:', error.message);
    }

  };






  const handleCancelButtonClick = () => {
    if (isEdit) {
      if (localStorage.getItem("printersAssociateToken")) {
        window.location.href = `/printers-associate-landing`;
      } else if (localStorage.getItem("associateToken")) {
        window.location.href = `/associate-landing`;
      } else {
        window.location.href = `/`;  // Redirect to login if neither token is available
      }
      localStorage.setItem("SpSelectedLat", selectedLatLngFromDb[0]);
      localStorage.setItem("SpSelectedLng", selectedLatLngFromDb[1]);
      setSelectedLatLng(selectedLatLngFromDb)
    }
    else {
      fetchSpData()
    }
  };




  return (
    <Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px' }}>

      {associateToken ? <AssociateHeader /> : paToken ? <PrintersAssociateHeader /> : null}
      <Box sx={{
        display: 'flex', flexDirection: 'column', width: '100%',
        justifyContent: 'center', height: '100%', alignItems: 'center'
      }}>

        <Box sx={{
          display: 'flex', flexDirection: { xs: 'column ', md: 'row' }, width: '100%', paddingBottom: '2%', gap: '32px',
          justifyContent: 'center', height: '100%', alignItems: 'flex-start', maxWidth: { xs: '90% ', md: '94%' }, paddingTop: '2%',
        }}>

          <Box sx={{
            display: 'flex', flexDirection: 'row', width: { xs: '100% ', md: '25%' },
            justifyContent: 'flex-start', height: '100%', alignItems: 'center'
          }}>
            <AssociateSidebar currentPage={"Location"}></AssociateSidebar>
          </Box>

          <Box sx={{
            display: 'flex', flexDirection: 'column', width: { xs: '100% ', md: 'calc(75% - 32px)' },
            borderLeft: { xs: '0px solid #E6E6E6', md: '1px solid #E6E6E6' }, minHeight: '650px', paddingBottom: '5%',
            justifyContent: 'flex-start', height: '100%', alignItems: 'center', paddingLeft: { xs: '0px ', md: '32px' },
          }}>

            <Box sx={{
              display: 'flex', flexDirection: 'column', width: '100%', gap: '8px',
              justifyContent: 'center', height: '100%', alignItems: 'center', background: Colors.white_80
            }}>
              <Box sx={{
                display: 'flex', flexDirection: 'column', width: '100%', gap: '24px',
                paddingTop: '4%', justifyContent: 'center', alignItems: 'center',
                paddingBottom: '4%', height: '564px', maxWidth: '92%'
              }}>


                <Box sx={{
                  display: 'flex', flexDirection: { xs: 'column', md: 'row' }, width: '100%', gap: '16px',
                  justifyContent: 'space-between', alignItems: 'center', height: 'fit-content',
                }}>

                  <Box sx={{
                    display: 'flex', flexDirection: 'row', width: { xs: '100%', md: '45%' }, gap: '16px',
                    justifyContent: 'left', alignItems: 'center', height: '100%',
                  }}>
                    <TypographySubHeading sx={{ fontSize: '16px' }}>Your location :</TypographySubHeading>
                    <TypographyNormal>{selectedLocation}</TypographyNormal>
                  </Box>

                  <Box sx={{
                    display: 'flex', flexDirection: 'column', width: { xs: '100% ', md: '55%' }, gap: '16px',
                    height: '100%',
                  }}>
                    <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}
                      options={AutocompleteOptions} style={{ width: '100% !important' }}  >
                      <TextField placeholder="Search here"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <SearchOutlinedIcon />
                            </InputAdornment>),
                        }}
                        sx={{
                          height: '38px', borderRadius: '4px', border: '1px solid', borderColor: Colors.black_60,
                          '& .MuiOutlinedInput-root': {
                            borderColor: Colors.black_80, height: '100%', width: { xs: '82vw', md: '33vw' },
                            '&:hover fieldset': {
                              borderColor: Colors.black_80,
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: Colors.black_80,
                            },
                          },
                        }}
                      />

                    </Autocomplete>
                  </Box>



                </Box>
                {isMapReady && (<GoogleMap
                  mapContainerStyle={{ ...mapContainerStyle, cursor: 'pointer !important' }}
                  zoom={12}
                  center={{ lat: selectedLatLng[0], lng: selectedLatLng[1] }}
                  onClick={handleMapClick}>
                  {selectedLatLng && isLoaded && (
                    <MarkerF position={{ lat: selectedLatLng[0], lng: selectedLatLng[1] }} />
                  )}
                </GoogleMap>)}


              </Box>
            </Box>

            <Box sx={{
              width: '100%', flexDirection: 'row', justifyContent: 'right', display: 'flex',
              alignItems: 'center', gap: { xs: '8px', md: '16px' }, paddingTop: { xs: '16px', md: '32px' }
            }}>
              <Box sx={{
                width: { xs: '60%', md: '30%' }, flexDirection: 'row', justifyContent: 'center', display: 'flex',
                alignItems: 'center', gap: { xs: '8px', md: '16px' }
              }}>
                <ButtonWhite onClick={handleCancelButtonClick} >Cancel</ButtonWhite>
                <ButtonBlue disabled={selectedLocation === 'Location not found'} onClick={handleSaveButtonClick}  >Save</ButtonBlue>
              </Box>
            </Box>

          </Box>
        </Box>
      </Box>

      <Footer></Footer>

    </Grid>
  )
}
export default AssociateLocation;