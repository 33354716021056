import React from 'react';
import { Navigate } from 'react-router-dom';

const AssociateProtectedRoute = ({ element, ...rest }) => {
  const associateToken = localStorage.getItem('associateToken');
  const paToken=localStorage.getItem('printersAssociateToken')

  return associateToken || paToken ? (
    element
  ) : (
    <Navigate to="/associate-login" replace />
  );
};

export default AssociateProtectedRoute;
