

import { Avatar, Box, IconButton, InputAdornment, Tabs, Tab, } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import TypographyBlue from '../Components/TypographyNormal';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import ImageIcon from '@mui/icons-material/Image';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import FlipCameraIosOutlinedIcon from '@mui/icons-material/FlipCameraIosOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { API_URL } from '../Components/Constants';
import axios from "axios";
import logo from '../Images/logo.png'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import OTPInput from '../Components/OTPInput';
import TextfieldNormal from '../Components/TextfieldNormal';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import TypographyError from '../Components/TypographyError';
import ButtonBlue from '../Components/ButtonBlue';


const colors = ['#503259', '#4C50AE', '#FF7D6A', '#B07CC6', '#20C925'];

function getAvatarColor(index) {
    return colors[index % colors.length];
}

function stringAvatar(name = '', index) {
    const initials = name[0]?.toUpperCase() || '';
    const bgColor = getAvatarColor(index);
    return {
        sx: {
            bgcolor: bgColor,
            width: '64px',
            height: '64px',
            fontSize: '56px',
            color: '#fff',
        },
        children: initials,
    };
}


function ImageCaptureModal({ imageId }) {
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const fileInputRef = useRef(null);
    const trackRef = useRef(null);
    const [flashlightOn, setFlashlightOn] = useState(false);
    const [isRetake, setIsRetake] = useState(false);
    const [isUpload, setIsUpload] = useState(false);
    const [capturedImages, setCapturedImages] = useState([]);
    const [facingMode, setFacingMode] = useState('environment');
    const [currentImage, setCurrentImage] = useState(null);
    const [spDetails, setSpDetails] = useState([]);
    const [spDetailsSuggestion, setSpDetailsSuggestion] = useState([]);
    const [isOldOrNew, setIsOldOrNew] = useState(false);
    const [oldData, setOldData] = useState([]);
    const [newData, setNewData] = useState([]);
    const [isOTP, setIsOTp] = useState(false);
    const [isPassword, setIsPassword] = useState(false);
    const [isOtpSuccess, setIsOtpSuccess] = useState(false);
    const [isYes, setIsYes] = useState('new');
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordError, setNewPasswordError] = useState("");
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [forgotError, setForgotError] = useState("");
    const [loginError, setLoginError] = useState("");
    const [succssInsert, setSuccessInsert] = useState(false);

    const [selectedServiceIndex, setSelectedServiceIndex] = useState(0);

    // Handle tab change
    const handleTabChange = (event, newIndex) => {
        setSelectedServiceIndex(newIndex);
    };

    const getCamera = async () => {
        try {
            // Stop any existing video tracks before switching
            if (videoRef.current && videoRef.current.srcObject) {
                const stream = videoRef.current.srcObject;
                const tracks = stream.getTracks();
                tracks.forEach(track => track.stop());
            }

            const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode } });
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
            }
        } catch (error) {
            console.error('Error accessing the camera:', error);
        }
    };

    // Re-initialize the camera stream whenever the facingMode changes
    useEffect(() => {
        getCamera();
    }, [facingMode]);

    // Toggle between front and back camera
    const toggleCamera = () => {
        setFacingMode(facingMode === 'environment' ? 'user' : 'environment');
    };
    const handleCancel = () => {
        setCapturedImages([]);  // Clears captured images
    };

    const toggleFlashlight = async () => {
        setLoginError("")
        if (!flashlightOn) {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
                const track = stream.getVideoTracks()[0];
                trackRef.current = track; // Save track in ref

                if (track.getCapabilities().torch) {
                    await track.applyConstraints({ advanced: [{ torch: true }] });
                    setFlashlightOn(true);
                } else {
                    console.error('Torch not supported on this device.');
                }
            } catch (error) {
                console.error('Error accessing the camera or flashlight:', error);
            }
        } else {
            const track = trackRef.current;
            if (track) {
                try {
                    await track.applyConstraints({ advanced: [{ torch: false }] });
                    track.stop(); // Stop the camera
                    setFlashlightOn(false);
                } catch (error) {
                    console.error('Error turning off the flashlight:', error);
                }
            }
        }
    };

    const captureImage = () => {
        setLoginError("")
        setIsRetake(true);
        if (videoRef.current) {
            const video = videoRef.current;
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            context.drawImage(video, 0, 0, canvas.width, canvas.height);
            const imageDataUrl = canvas.toDataURL('image/png');
            setCapturedImages(prevImages => [...prevImages, imageDataUrl]);
        }
    };

    const removeImage = (index) => {
        setCapturedImages(prevImages => {
            const updatedImages = prevImages.filter((_, i) => i !== index);
            // Remove the image from view if it's currently displayed
            if (currentImage === prevImages[index]) {
                setCurrentImage(null);
            }
            return updatedImages;
        });
        setLoginError("")
    };
    const imageUpload = async () => {
        setSuccessInsert(false)
        setLoginError("")
        setIsUpload(true);
        const promotionalToken = localStorage.getItem("promotionalToken")
        const formData = new FormData();
        formData.append("imageId", imageId);

        if (capturedImages.length > 0) {
            for (let i = 0; i < capturedImages.length; i++) {
                const imageUrl = capturedImages[i];
                try {
                    const response = await fetch(imageUrl);

                    if (!response.ok) {
                        throw new Error('Image fetch failed');
                    }

                    const blob = await response.blob();
                    const file = new File(
                        [blob],
                        `image_${i + 1}.jpg`, // Use index for naming
                        { type: blob.type }
                    );

                    formData.append('images', file); // Append each file to formData
                } catch (error) {
                    console.error("Failed to convert blob URL to file:", error);
                    return; // Exit on error
                }
            }
        }

        try {
            // Send the images to your server
            const response = await axios.post(`${API_URL}/update_s3_image_urls`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    // 'Authorization': `Bearer ${promotionalToken}`
                },
            });
            //  console.log('response',response.data.status)
            if (response.data.status === 'success') {
                let newData = typeof response.data.newData === 'string'
                    ? JSON.parse(response.data.newData)
                    : response.data.newData;

                if (typeof newData === 'string') {
                    newData = JSON.parse(newData);
                }

                // console.log("Parsed newData:", newData);
                // console.log("Business Name:", newData.business_name);

                const oldData = typeof response.data.oldData === 'string'
                    ? JSON.parse(response.data.oldData)
                    : response.data.oldData;

                // console.log("oldDataDB", response.data.oldData);
                // console.log("oldData", oldData);
                // console.log("newData", newData);
                // console.log("Business Name:", newData.business_name);

                if (response.data.oldData !== null) {
                    // setOldData(oldData);
                    setNewData(newData);
                } else {
                    setNewData(newData);
                    setOldData([]);
                }
            }
            else if (response.data.status === 'failed') {
                setLoginError("One profile already exists with this phone number.");
            }
            else if (response.data.status === 'error') {
                setLoginError("Something went wrong");
            }
            else {
                console.error("An error occurred:", response.data.message);
            }

        } catch (error) {
            console.error('Error:', error.message);
        }

        // Stop the upload effect after 3 seconds
        setTimeout(() => {
            setIsUpload(false);
        }, 3000);
    };


    useEffect(() => {
        const fetchInterval = setInterval(() => {
            if (oldData.length > 0) {
                setSpDetails(newData);
                setSpDetailsSuggestion(oldData);
            } else {
                setSpDetails(newData);
                setSpDetailsSuggestion([]);
            }
            // console.log(spDetails)

        }, 500);
        return () => clearInterval(fetchInterval);

    }, [oldData, newData]);



    const openGallery = () => {
        fileInputRef.current.click();
    };

    // Handle adding images to the array
    const handleAddImages = (event) => {
        if (event.target && event.target.files) {
            const newImages = Array.from(event.target.files).map((file) =>
                URL.createObjectURL(file)
            );
            setCapturedImages((prevImages) => [...prevImages, ...newImages]);
        } else {
            console.error("No files selected or event target is undefined.");
        }
    };

    const joinBrohandy = async () => {
        try {
            console.log("insert data", newData)
            const response = await axios.post(`${API_URL}/insert_sp_details`, { data: newData });
            console.log(response.data)
            if (response.data.message === 'Service provider details saved successfully!') {
                setSuccessInsert(true)
            }
        } catch (error) {
            console.error('Error inserting user data:', error);
        }
    };


    const handleCancelOldOrNewPopup = () => {
        setIsOldOrNew(false)
    }

    const handleCancelOTPPopup = () => {
        setIsOTp(false)
    }

    // const handleYesOldOrNew = async () => {
    //     setIsYes('New')
    //     try {          
    //         const response = await axios.post(`${API_URL}/send-otp`, {
    //             country_code: newData.country_code,
    //             phone: newData.phone_number });

    //         if (response.data.status === 'success') {
    //            setIsOTp(true)
    //         }
    //     }
    //     catch (error) {
    //         console.log('Error updating password. Please try again later.');
    //     }

    // };

    // useEffect(() => {
    //     if (isOtpSuccess) {  
    //         setIsOTp(false)
    //         const insertSpData = async () => {
    //             try {                    
    //                 let response;
    //                 if (isYes === 'New') {
    //                   response = await axios.post(`${API_URL}/insert_sp_details`, { data: newData });
    //                 } else if (isYes === 'Old') {
    //                   response = await axios.post(`${API_URL}/insert_sp_details`, { data: oldData });
    //                 }
    //                 console.log(response.data)
    //                 if (response.data.status === 'success') {
    //                     setIsPassword(true)  
    //                   }
    //             } catch (error) {
    //                 console.error('Error inserting user data:', error);
    //             }

    //         };

    //         insertSpData();  
    //     }
    // }, [isOtpSuccess]);




    const handleNoOldOrNew = async () => {
        setIsYes('Old')
        try {
            const response = await axios.post(`${API_URL}/send-otp`, {
                country_code: oldData.country_code,
                phone: oldData.phone_number
            });

            if (response.data.status === 'success') {
                setIsOTp(true)
            }
        }
        catch (error) {
            console.log('Error updating password. Please try again later.');
        }

    };


    const handleNewPasswordChange = (event) => {
        setNewPassword(event.target.value);
        setNewPasswordError("");
        setForgotError("")
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
        setConfirmPasswordError("");
        setForgotError("")
    };

    const savedData = JSON.parse(localStorage.getItem('AssosiateSetPassword'));

    const handleSaveButtonClick = async () => {


        if (newPassword.trim() === "") {
            setNewPasswordError("Please enter your password");
        }
        else if (confirmPassword.trim() === "") {
            setConfirmPasswordError("Please enter your password");
        }
        else if (newPassword.trim() !== confirmPassword.trim()) {
            setForgotError("The new password and confirm password do not match.")
        }

        else {

            // try {                    
            //     const response = await axios.post(`${API_URL}/insert_password_associate`, {                    
            //         countryCode: savedData.countryCode,
            //         phoneNumber: savedData.phoneNumber,                
            //         password: confirmPassword,
            //         spId: savedData.spid

            //     });
            //     console.log('User data inserted successfully:', response.data);
            //     setForgotError(response.data.error)
            //     if(response.data.success === true){
            //             localStorage.removeItem('AssosiateSetPassword')
            //             localStorage.removeItem('SPCode')
            //             window.location.href = `/`;

            //     }
            // } catch (error) {
            //     console.error('Error inserting user data:', error);
            // }

        }
    };

    const toggleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };


    const handleCloseChangePasswordPopUp = () => {
        setIsPassword(false);
    }

    return (
        <Box sx={{ padding: 0, margin: 0, width: '100%', height: '100%' }}>
            <Box sx={{
                display: 'flex', flexDirection: 'row', width: '100%', height: '96%',
                justifyContent: 'center', alignItems: 'center', paddingTop: '2%', paddingBottom: '2%'
            }}>

                <Box sx={{
                    display: 'flex', flexDirection: 'column',
                    width: '100%', height: '100%', gap: '8px'
                }}>

                    <Box sx={{
                        display: 'flex', flexDirection: 'column', width: '100%', height: '50%',
                        justifyContent: 'center', alignItems: 'center', gap: '8px'
                    }}>

                        <Box sx={{
                            display: 'flex', flexDirection: 'row', width: '100%', height: '48px',
                            justifyContent: 'space-between', alignItems: 'center', padding: 0
                        }}>
                            <TypographyBlue sx={{ fontWeight: 500, fontSize: '16px' }}>

                                Capture your business card
                            </TypographyBlue>

                        </Box>

                        <Box sx={{
                            display: 'flex', flexDirection: 'column', width: '100%', height: { xs: '72px', md: '100px' },
                            paddingRight: '16px', justifyContent: 'center', alignItems: 'flex-end', background: '#2A2483',
                            borderRadius: '4px'
                        }}>
                            <FlashOnIcon sx={{ color: 'white', cursor: 'pointer' }} onClick={toggleFlashlight} />
                        </Box>

                        <Box sx={{
                            display: 'flex', flexDirection: 'column', width: '100%', height: '70%',
                            justifyContent: 'center', alignItems: 'center', gap: '16px', position: 'relative'
                        }}>
                            <video ref={videoRef} autoPlay playsInline style={{
                                width: '100%', height: '100%',
                                objectFit: 'fill', overflowClipMargin: 'content-box', overflow: 'clip', borderRadius: '4px'
                            }} />
                            <canvas ref={canvasRef} style={{
                                display: 'none', width: '100%', height: '100%',
                                borderRadius: '4px'
                            }} />
                            <Box sx={{
                                position: 'absolute', top: '50%', left: '50%',
                                width: '100%', height: '100%',
                                transform: 'translate(-50%, -50%)',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}>
                                {/* Top-left corner */}
                                <Box sx={{
                                    position: 'absolute', top: '-4px', left: '-4px',
                                    width: '40px', height: '40px',
                                    borderLeft: '4px solid white',
                                    borderTop: '4px solid white',
                                }} />
                                {/* Top-right corner */}
                                <Box sx={{
                                    position: 'absolute', top: '-4px', right: '-4px',
                                    width: '40px', height: '40px',
                                    borderRight: '4px solid white',
                                    borderTop: '4px solid white',
                                }} />
                                {/* Bottom-left corner */}
                                <Box sx={{
                                    position: 'absolute', bottom: '-4px', left: '-4px',
                                    width: '40px', height: '40px',
                                    borderLeft: '4px solid white',
                                    borderBottom: '4px solid white',
                                }} />
                                {/* Bottom-right corner */}
                                <Box sx={{
                                    position: 'absolute', bottom: '-4px', right: '-4px',
                                    width: '40px', height: '40px',
                                    borderRight: '4px solid white',
                                    borderBottom: '4px solid white',
                                }} />
                            </Box>

                            {isUpload && (
                                <>
                                    {capturedImages.length > 0 && (
                                        <Box
                                            sx={{
                                                position: 'absolute', top: 0, left: 0, right: 0, bottom: 0,
                                                display: 'flex', flexDirection: 'column', width: '100%', height: '100%',
                                                justifyContent: 'center', alignItems: 'center', overflow: 'hidden', boxShadow: '#61616140'
                                            }}
                                        >
                                            <img
                                                src={capturedImages[0]}
                                                alt="Scanning"
                                                style={{
                                                    width: '100%', height: '100%',
                                                    position: 'absolute', overflow: 'hidden'
                                                }}
                                            />
                                            <Box sx={{
                                                position: 'absolute', top: 0, left: 0, right: 0, bottom: 0,
                                                display: 'flex', justifyContent: 'center', alignItems: 'center',
                                                overflow: 'hidden',
                                                animation: 'scan 3s linear infinite',
                                                zIndex: 1, // Ensure it appears above the image
                                                '&::before': {
                                                    content: '""',
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: '50%',
                                                    width: '4px',
                                                    height: '94%',
                                                    backgroundColor: '#FF7171',
                                                    animation: 'scan 2s linear infinite',
                                                    transform: 'translateX(-50%)',
                                                    boxShadow: '#61616140'
                                                },
                                                '@keyframes scan': {
                                                    '0%': {
                                                        transform: 'translateX(-50%) translateX(-100%)',
                                                    },
                                                    '50%': {
                                                        transform: 'translateX(-50%) translateX(100%)',
                                                    },
                                                    '100%': {
                                                        transform: 'translateX(-50%) translateX(-100%)',
                                                    },
                                                },
                                            }} />
                                        </Box>
                                    )}
                                </>
                            )}

                            {currentImage && (
                                <>
                                    <img
                                        src={currentImage}
                                        alt="Current Capture"
                                        style={{ width: '100%', height: '100%', position: 'absolute', border: '2px solid black' }}
                                    />
                                    <IconButton
                                        size="small"
                                        sx={{
                                            position: 'absolute', top: 0, right: 0, color: '#000000',
                                            backgroundColor: '#C5C5C5', padding: '2px', '&:hover': { backgroundColor: '#C5C5C5' }
                                        }}
                                        onClick={() => setCurrentImage(null)}
                                    >
                                        <CloseRoundedIcon sx={{ fontSize: '24px' }} />
                                    </IconButton></>
                            )}

                        </Box>

                        <Box sx={{
                            display: 'flex', flexDirection: 'row', width: 'calc(100% - 24px)', height: { xs: '120px', md: '120px' },
                            justifyContent: 'center', alignItems: 'center', background: '#2A2483',
                            borderRadius: '4px', padding: '12px'
                        }}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'column', width: '20%', height: '100%',
                                justifyContent: 'flex-end', alignItems: 'center', borderRadius: '4px',
                            }}>
                                <ImageIcon sx={{ color: 'white', fontSize: '38px', cursor: 'pointer' }} onClick={openGallery} />
                                <TypographyBlue sx={{ fontSize: '10px', color: 'white' }}>Gallery</TypographyBlue>
                                <input
                                    type="file"
                                    multiple
                                    accept="image/*"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleAddImages}
                                />
                            </Box>
                            <Box sx={{
                                display: 'flex', flexDirection: 'column', width: '60%', height: '100%',
                                justifyContent: 'center', alignItems: 'center', borderRadius: '4px',
                            }}>
                                <TypographyBlue sx={{ fontSize: '12px', color: 'white', cursor: 'pointer' }}>Capture</TypographyBlue>
                                <CircleOutlinedIcon sx={{ color: 'white', fontSize: '72px' }} onClick={captureImage} />
                            </Box>
                            <Box sx={{
                                display: 'flex', flexDirection: 'column', width: '20%', height: '100%',
                                justifyContent: 'flex-end', alignItems: 'center', borderRadius: '4px',
                            }}>
                                <FlipCameraIosOutlinedIcon sx={{ color: 'white', fontSize: '38px', cursor: 'pointer' }} onClick={toggleCamera} />
                                <TypographyBlue sx={{ fontSize: '10px', color: 'white' }}>Flip</TypographyBlue>
                            </Box>
                        </Box>

                        <Box sx={{
                            display: 'flex', flexDirection: 'row', width: '100% ', height: { xs: '140px', md: '140px' },
                            justifyContent: 'flex-start', alignItems: 'center', gap: '8px',
                            borderRadius: '4px', overflowY: 'none', overflowX: 'auto', '&::-webkit-scrollbar': {
                                width: '12px', height: '8px', borderRadius: '16px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: '#C5C5C5', borderRadius: '16px', border: '2px solid white',
                            },
                            '&::-webkit-scrollbar-track': {
                                borderRadius: '16px', backgroundColor: 'none', marginLeft: '8px', marginRight: '4px'
                            },

                        }}>
                            {capturedImages.length > 0 && (
                                <Box sx={{
                                    marginTop: '16px', display: 'flex', flexDirection: 'row', gap: '10px',
                                    flexWrap: 'nowrap'
                                }}>
                                    {capturedImages.map((img, index) => (
                                        <Box key={index} sx={{ position: 'relative', width: '100px', height: '100px', flexShrink: 0 }}>
                                            <img src={img} alt={`Captured ${index + 1}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                onClick={() => setCurrentImage(img)} />
                                            <IconButton
                                                size="small"
                                                sx={{
                                                    position: 'absolute', top: '-8px', right: '-8px', color: '#000000',
                                                    backgroundColor: '#C5C5C5', padding: '2px', '&:hover': { backgroundColor: '#C5C5C5' }
                                                }}
                                                onClick={() => removeImage(index)}
                                            >
                                                <CloseRoundedIcon sx={{ fontSize: '14px' }} />
                                            </IconButton>
                                        </Box>
                                    ))}
                                </Box>
                            )}
                        </Box>

                        <Box
  sx={{
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap', // Prevents wrapping
    alignItems: 'center',
    justifyContent: 'flex-end', // Aligns buttons to the right
    width: '100%',
    gap: 2, // Adds space between buttons
  }}
>
  {capturedImages.length > 0 && (
    <>
      {/* Cancel Button */}
      <ButtonBlue
        onClick={handleCancel}
        sx={{
          height: '52px',
          minWidth: '120px',
          backgroundColor: 'gray',
          '&:hover': { backgroundColor: 'darkgray' },
        }}
      >
        Cancel
      </ButtonBlue>

      {/* Save Button */}
      <ButtonBlue
        onClick={imageUpload}
        sx={{
          height: '52px',
          minWidth: '120px',
        }}
      >
        Save
      </ButtonBlue>
    </>
  )}

  {loginError && (
    <TypographyBlue sx={{ color: 'red' }}>{loginError}</TypographyBlue>
  )}
</Box>


                        <Box sx={{
                            marginTop: '16px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                            width: '100%'
                        }}>

                            {/* { spDetailsSuggestion.services &&
                        <>
                        <TypographyBlue sx={{ fontWeight: 500, fontSize: '16px' }}>Your Existing Bro Handy Business Card:</TypographyBlue>
                       
                       <Box sx={{
                           width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '4px ',
                           flexDirection: 'column', border: { xs: '1px solid #E6E6E6', md: '1px solid  #E6E6E6' },
                           borderColor: '#E6E6E6', gap: '16px', marginTop:'16px'
                       }}>
                           <Box sx={{
                               width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '4px ',
                               flexDirection: 'column', background: { xs: 'linear-gradient(to bottom, #E6E6E6 120px, white 8%)', md: 'linear-gradient(to bottom, #E6E6E6 140px, white 10%)' }, gap: '16px',
                           }}>
                               <Box sx={{
                                   width: '100%', height: '94%', justifyContent: 'center', alignItems: 'center', display: 'flex',
                                   flexDirection: 'column', gap: { xs: '8px', md: '24px' }, paddingBottom: '5%',
                               }}>
                                   <Box sx={{
                                       width: '100%', height: 'fit-content', justifyContent: 'center', paddingTop: '5%',
                                       alignItems: 'center', display: 'flex', flexDirection: 'column', paddingBottom: { xs: '16px', md: '0px' },
                                       borderRadius: '4px',
                                   }}>


                                       <Box sx={{
                                           width: '100%', height: '100%', justifyContent: 'left', alignItems: 'flex-start', display: 'flex',
                                           flexDirection: 'row', gap: '32px', maxWidth: { xs: '90%', md: '95%' },
                                       }}>


                                           <Box sx={{
                                               height: '100%', width: { xs: '70px', md: '120px' }, justifyContent: 'center', alignItems: 'flex-start',
                                               display: 'flex', flexDirection: 'row', marginTop: { xs: '13%', md: '0px' }
                                           }}>
                                               <Box sx={{ position: 'relative', display: 'inline-block', }}>
                                                 
                                                   
                                                       <Avatar  {...stringAvatar(spDetailsSuggestion.name)}
                                                           sx={{ width: { xs: '70px', md: '120px' }, height: { xs: '70px', md: '120px' }, fontSize: { xs: '32px', md: '58px' }, bgcolor: '#503259' }} />

                                                 
                                                         
                                                     
                                               </Box>

                                           </Box>
                                           <Box sx={{
                                               height: '100%', width: { xs: 'calc(100% - 90px)', md: 'calc(100% - 120px)' }, justifyContent: 'center',
                                               alignItems: 'left', display: 'flex', flexDirection: 'column', gap: '8px'
                                           }}>
                                               <Box sx={{
                                                   display: 'flex', flexDirection: 'row', width: '100%', height: '24px', justifyContent: 'right',
                                                   alignItems: 'center'
                                               }}>
                                                   <img src={logo} alt="Logo" style={{ width: { xs: '75px', md: '124px' }, height: '24px', }} />
                                               </Box>

                                               <Box sx={{
                                                   width: '100%', height: '100%', justifyContent: 'flex-start', alignItems: 'left', display: 'flex',
                                                   flexDirection: 'column', gap: { xs: '4px', md: '0px' }
                                               }}>

                                                   <TypographyBlue sx={{
                                                       fontSize: { xs: '20px', md:  '24px' }, fontWeight: 500, whiteSpace: 'nowrap', overflow: 'hidden',
                                                       textOverflow: 'ellipsis', maxWidth: '95%'
                                                   }}>{spDetailsSuggestion.name}</TypographyBlue>
                                                    <TypographyBlue sx={{
                                                       fontSize: { xs: '14px', md: '16px' }, lineHeight: { xs: '16px', md: '24px' },
                                                       color: '#313131', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '95%'
                                                   }}> {spDetailsSuggestion.city}, {spDetailsSuggestion.province}, {spDetailsSuggestion.country}</TypographyBlue>
                                                   
                                               </Box>


                                           </Box>
                                       </Box>

                                       <Box sx={{
                                           width: '100%', height: '100%', justifyContent: 'left', alignItems: 'flex-start', display: 'flex',
                                           flexDirection: 'row', gap: '32px', maxWidth: { xs: '90%', md: '95%' },
                                       }}>
                                         


                                           <Box sx={{
                                               height: '100%', width: 'calc(100% )',justifyContent: 'center',
                                               alignItems: 'left', display: 'flex', flexDirection: 'column', gap: '8px',
                                           }}>
                                               <Box sx={{
                                                   width: '100%', height: 'fit-content', justifyContent: 'space-between',
                                                   alignItems: 'flex-start', display: 'flex',
                                                   flexDirection: 'column', gap: '8px',
                                                   marginTop: { xs: '8px', md: '16px' }
                                               }}>
                                                   <Box
                                                       sx={{
                                                           width: '100%', height: 'fit-content', justifyContent: 'flex-start',
                                                           alignItems: 'flex-start', display: 'flex', flexDirection: { xs: 'column', md: 'column' },
                                                           flexWrap: 'wrap', gap: '6px'
                                                       }}>
                                                       {[
                                                           { label: 'Address', value: `${spDetailsSuggestion.city}, ${spDetailsSuggestion.province}, ${spDetailsSuggestion.country}` },
                                                           { label: 'Contact', value: spDetailsSuggestion.contact_person },
                                                           { label: 'Phone number', value: `${spDetailsSuggestion.country_code} ${spDetailsSuggestion.phone_number}` },
                                                           { label: 'Email id', value: spDetailsSuggestion.email },
                                                           { label: 'Website', value: spDetailsSuggestion.website },
                                                           { label: 'Language', value: spDetailsSuggestion.languages },

                                                       ]
                                                           .filter(item => item.value)
                                                           .map((item, index, arr) => (
                                                               <Box
                                                                   key={index}
                                                                   sx={{
                                                                       display: 'flex', alignItems: 'center', gap: { xs: '4px', md: '12px' }, flexDirection: 'row',
                                                                       width: arr.length === 1 ? '100%' : { xs: '100%', md: '100%' },
                                                                       justifyContent: 'flex-start'
                                                                   }}>
                                                                   <TypographyBlue sx={{ minWidth: '12ch', fontWeight: 600, fontSize: { xs: '12px', md: '14px' } }}>{item.label}</TypographyBlue>
                                                                   <TypographyBlue>:</TypographyBlue>
                                                                   <TypographyBlue
                                                                       sx={{ maxWidth: { xs: '98%', md: '95%' }, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: { xs: '12px', md: '14px' } }}>
                                                                       {item.value}
                                                                   </TypographyBlue>
                                                               </Box>
                                                           ))}
                                                   </Box>
                                               </Box>


                                           </Box>

                                       </Box>
                                   </Box>




                               </Box>
                           </Box>

                       </Box>
                        </>
                        } */}

                            {spDetails.phone_number &&
                                <>
                                    <TypographyBlue sx={{ fontWeight: 500, fontSize: '16px' }}>Your New Bro Handy Business Card:</TypographyBlue>

                                    <Box sx={{
                                        width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '4px ',
                                        flexDirection: 'column', border: { xs: '1px solid #E6E6E6', md: '1px solid  #E6E6E6' },
                                        borderColor: '#E6E6E6', gap: '16px', marginTop: '16px'
                                    }}>
                                        <Box sx={{
                                            width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '4px ',
                                            flexDirection: 'column', background: { xs: 'linear-gradient(to bottom, #E6E6E6 120px, white 8%)', md: 'linear-gradient(to bottom, #E6E6E6 140px, white 10%)' }, gap: '16px',
                                        }}>
                                            <Box sx={{
                                                width: '100%', height: '94%', justifyContent: 'center', alignItems: 'center', display: 'flex',
                                                flexDirection: 'column', gap: { xs: '8px', md: '24px' }, paddingBottom: '5%',
                                            }}>
                                                <Box sx={{
                                                    width: '100%', height: 'fit-content', justifyContent: 'center', paddingTop: '5%',
                                                    alignItems: 'center', display: 'flex', flexDirection: 'column', paddingBottom: { xs: '16px', md: '0px' },
                                                    borderRadius: '4px',
                                                }}>


                                                    <Box sx={{
                                                        width: '100%', height: '100%', justifyContent: 'left', alignItems: 'flex-start', display: 'flex',
                                                        flexDirection: 'row', gap: '32px', maxWidth: { xs: '90%', md: '95%' },
                                                    }}>


                                                        <Box sx={{
                                                            height: '100%', width: { xs: '70px', md: '120px' }, justifyContent: 'center', alignItems: 'flex-start',
                                                            display: 'flex', flexDirection: 'row', marginTop: { xs: '13%', md: '0px' }
                                                        }}>
                                                            <Box sx={{ position: 'relative', display: 'inline-block', }}>


                                                                <Avatar  {...stringAvatar(spDetails.business_name)}
                                                                    sx={{ width: { xs: '70px', md: '120px' }, height: { xs: '70px', md: '120px' }, fontSize: { xs: '32px', md: '58px' }, bgcolor: '#B07CC6' }} />




                                                            </Box>

                                                        </Box>
                                                        <Box sx={{
                                                            height: '100%', width: { xs: 'calc(100% - 90px)', md: 'calc(100% - 160px)' }, justifyContent: 'center',
                                                            alignItems: 'left', display: 'flex', flexDirection: 'column', gap: '8px'
                                                        }}>
                                                            <Box sx={{
                                                                display: 'flex', flexDirection: 'row', width: '100%', height: '24px', justifyContent: 'right',
                                                                alignItems: 'center',
                                                            }}>
                                                                <img src={logo} alt="Logo" style={{ width: { xs: '75px', md: '124px' }, height: '24px', }} />
                                                            </Box>

                                                            <Box sx={{
                                                                width: '100%', height: '100%', justifyContent: 'flex-start', alignItems: 'left', display: 'flex',
                                                                flexDirection: 'column', gap: { xs: '4px', md: '0px' }
                                                            }}>

                                                                <TypographyBlue sx={{
                                                                    fontSize: { xs: '20px', md: '24px' }, fontWeight: 500, whiteSpace: 'nowrap', overflow: 'hidden',
                                                                    textOverflow: 'ellipsis', maxWidth: '95%'
                                                                }}>{spDetails.business_name}</TypographyBlue>
                                                                <TypographyBlue sx={{
                                                                    fontSize: { xs: '14px', md: '16px' }, lineHeight: { xs: '16px', md: '24px' },
                                                                    color: '#313131', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '95%'
                                                                }}> {spDetails.city}, {spDetails.province}, {spDetails.country}</TypographyBlue>
                                                                {/* <TypographyBlue sx={{
                                                        fontSize: { xs: '14px', md: '16px' }, lineHeight: { xs: '16px', md: '24px' },
                                                        color: '#313131', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '95%'
                                                    }}> {spDetails.service}</TypographyBlue>
                                                    <TypographyBlue sx={{
                                                        fontSize: { xs: '14px', md: '16px' }, lineHeight: { xs: '16px', md: '24px' },
                                                        color: '#313131', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '95%'
                                                    }}> {spDetails.type_of_service}</TypographyBlue>
                                                     */}
                                                            </Box>


                                                        </Box>
                                                    </Box>

                                                    <Box sx={{
                                                        width: '100%', height: '100%', justifyContent: 'left', alignItems: 'flex-start', display: 'flex',
                                                        flexDirection: 'row', gap: '32px', maxWidth: { xs: '90%', md: '95%' },
                                                    }}>



                                                        <Box sx={{
                                                            height: '100%', width: 'calc(100% )', justifyContent: 'center',
                                                            alignItems: 'left', display: 'flex', flexDirection: 'column', gap: '8px',
                                                        }}>
                                                            <Box sx={{
                                                                width: '100%', height: 'fit-content', justifyContent: 'space-between',
                                                                alignItems: 'flex-start', display: 'flex',
                                                                flexDirection: 'column', gap: '8px',
                                                                marginTop: { xs: '8px', md: '16px' }
                                                            }}>
                                                                <Box
                                                                    sx={{
                                                                        width: '100%', height: 'fit-content', justifyContent: 'flex-start',
                                                                        alignItems: 'flex-start', display: 'flex', flexDirection: { xs: 'column', md: 'column' },
                                                                        flexWrap: 'wrap', gap: '6px',
                                                                    }}>
                                                                    <TypographyBlue sx={{ minWidth: '12ch', fontWeight: 600, fontSize: { xs: '18px', md: '20px' }, textDecoration: 'underline', textUnderlineOffset: '5px' }}>Service Details</TypographyBlue>
                                                                    {/* {newData.service_details.map((service, index) => (
                                                                <Box key={index}  sx={{
                                                                    width: '100%', height: 'fit-content', justifyContent: 'flex-start',
                                                                    alignItems: 'flex-start', display: 'flex',
                                                                }}>
                                                                     <TypographyBlue sx={{ minWidth: '12ch', fontWeight: 600, fontSize: { xs: '12px', md: '14px' } }}>{service.service}</TypographyBlue>
                                                                    <TypographyBlue> : </TypographyBlue>
                                                                    <TypographyBlue
                                                                        sx={{ maxWidth: { xs: '98%', md: '95%' }, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: { xs: '12px', md: '14px' } }}>
                                                                        { service.type_of_service }
                                                                    </TypographyBlue>                                                              
                                                                </Box>
                                                            ))} */}
                                                                    <Box sx={{ width: '100%' }}>
                                                                        {/* Tabs for each service */}
                                                                        <Tabs
                                                                            value={selectedServiceIndex}
                                                                            onChange={handleTabChange}
                                                                            variant="scrollable"
                                                                            scrollButtons="auto"
                                                                            aria-label="Service tabs"
                                                                            sx={{ mb: 2 }}
                                                                        >
                                                                            {newData.service_details.map((service, index) => (
                                                                                <Tab key={index} label={service.service_name} />
                                                                            ))}
                                                                        </Tabs>

                                                                        {/* Display the types of the selected service */}
                                                                        <Box sx={{ padding: 2 }}>
                                                                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 1 }}>
                                                                                {newData.service_details[selectedServiceIndex].types.map((type, typeIndex) => (
                                                                                    <Box key={typeIndex} sx={{ display: 'flex', alignItems: 'center' }}>
                                                                                        <TypographyBlue sx={{ fontSize: { xs: '12px', md: '14px' } }}>
                                                                                            - {type?.type_name || "N/A"} {/* Ensure that type_name exists */}
                                                                                        </TypographyBlue>
                                                                                    </Box>
                                                                                ))}
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>

                                                                    <TypographyBlue sx={{ minWidth: '12ch', fontWeight: 600, fontSize: { xs: '18px', md: '20px' }, textDecoration: 'underline', textUnderlineOffset: '5px', marginTop: '12px' }}>Personal Details</TypographyBlue>

                                                                    {[
                                                                        { label: 'Address', value: `${spDetails.city}, ${spDetails.province}, ${spDetails.country}` },
                                                                        { label: 'Contact', value: spDetails.contact_person },
                                                                        { label: 'Phone number', value: `${spDetails.country_code} ${spDetails.phone_number}` },
                                                                        { label: 'Email id', value: spDetails.email },
                                                                        { label: 'Website', value: spDetails.website },
                                                                        { label: 'Language', value: spDetails.languages },
                                                                        { label: 'About us', value: spDetails.about_us },

                                                                    ]
                                                                        .filter(item => item.value)
                                                                        .map((item, index, arr) => (
                                                                            <Box
                                                                                key={index}
                                                                                sx={{
                                                                                    display: 'flex', alignItems: 'center', gap: { xs: '4px', md: '12px' }, flexDirection: 'row',
                                                                                    width: arr.length === 1 ? '100%' : { xs: '100%', md: '100%' },
                                                                                    justifyContent: 'flex-start'
                                                                                }}>
                                                                                <TypographyBlue sx={{ minWidth: '12ch', fontWeight: 600, fontSize: { xs: '12px', md: '14px' } }}>{item.label}</TypographyBlue>
                                                                                <TypographyBlue>:</TypographyBlue>
                                                                                <TypographyBlue
                                                                                    sx={{ maxWidth: { xs: '98%', md: '95%' }, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: { xs: '12px', md: '14px' } }}>
                                                                                    {item.value}
                                                                                </TypographyBlue>
                                                                            </Box>
                                                                        ))}
                                                                </Box>
                                                            </Box>


                                                        </Box>

                                                    </Box>
                                                </Box>




                                            </Box>
                                        </Box>

                                    </Box>
                                </>
                            }
                            {spDetails.phone_number && !succssInsert && <Box sx={{
                                marginTop: '16px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                                width: '100%'
                            }}>
                                <ButtonBlue onClick={joinBrohandy}
                                    sx={{ background: '#5D5FEF', height: '52px', border: '1px solid #5D5FEF !important' }}>Join Bro Handy
                                </ButtonBlue>

                            </Box>}

                            {succssInsert &&
                                <Box sx={{
                                    marginTop: '16px', display: 'flex', flexDirection: 'column',
                                    width: '100%'
                                }}>
                                    <TypographyBlue sx={{ fontWeight: 600, fontSize: { xs: '18px', md: '20px' }, lineHeight: { xs: '42px', md: '72px' }, textAlign: 'center' }}>Data saved successfully!</TypographyBlue>
                                    <ButtonBlue onClick={() => window.location.reload()}
                                        sx={{ background: '#5D5FEF', height: '52px', border: '1px solid #5D5FEF !important' }}>Back
                                    </ButtonBlue>

                                </Box>
                            }







                        </Box>

                    </Box>
                </Box>
            </Box>


            {isOldOrNew && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 2,
                    }}
                >
                    <Box sx={{
                        width: '100%', height: '100%', gap: '5px',
                        justifyContent: 'center', alignItems: 'center', position: 'fixed',
                        display: 'flex', flexDirection: 'column',
                    }}>
                        <Box sx={{
                            width: { md: '375px', sm: '380px', xs: '85%' }, height: '36px',
                            justifyContent: 'right', alignItems: 'right', display: 'flex',
                        }}>
                            <IconButton onClick={handleCancelOldOrNewPopup} sx={{
                                width: '36px', height: '36px',
                            }} >
                                <CloseOutlinedIcon sx={{
                                    width: '36px', height: '36px', color: '#C5C5C5', '&:hover': {
                                        width: '40px', height: '40px', color: '#C5C5C5', backgroundColor: 'none',
                                        background: 'none'
                                    },
                                }} >
                                </CloseOutlinedIcon>
                            </IconButton>
                        </Box>
                        <Box
                            sx={{

                                width: { md: '375px', sm: '380px', xs: '85%' },
                                height: 'fit-content',
                                backgroundColor: '#fff',
                                border: '1px solid #E6E6E6',
                                borderRadius: '4px ',
                                boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                                boxSizing: 'border-box',
                                zIndex: 3,
                                padding: '35px',
                                alignItems: 'center',
                                overflow: 'auto',
                                '&::-webkit-scrollbar': {
                                    width: '12px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#7f7f7f',
                                    borderRadius: '28px',
                                },
                                '&::-webkit-scrollbar-track': {
                                    backgroundColor: '#e0e0e0',
                                    borderRadius: '28px',
                                },
                            }}
                        >

                            <Box sx={{
                                display: 'flex', flexDirection: 'column', width: '100%', gap: '16px', alignItems: 'center',
                                justifyContent: 'center', alignContent: 'center', height: '100%'
                            }}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                                    alignContent: 'center', width: '100%', gap: '16px'
                                }}>

                                    <TypographyBlue sx={{ fontSize: '18px', color: '#000000', fontWeight: 600 }}>
                                        Business card</TypographyBlue>

                                    <TypographyBlue sx={{ fontSize: '16px', color: '#616161' }}>
                                        Are you going to move forward with the new business card?</TypographyBlue>

                                </Box>

                                <Box sx={{
                                    display: 'flex', flexDirection: 'row', gap: '16px', marginTop: '16px', justifyContent: 'center',
                                    alignContent: 'center', alignItems: 'center', width: '100%'
                                }}>

                                    <ButtonBlue sx={{ background: 'white', border: '1px solid black', color: 'black' }} onClick={handleNoOldOrNew}>No</ButtonBlue>
                                    {/* <ButtonBlue onClick={handleYesOldOrNew}>Yes</ButtonBlue> */}

                                </Box>


                            </Box>


                        </Box>
                    </Box>
                </Box>)}

            {isOTP && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 2,
                    }}
                >
                    <Box sx={{
                        width: '100%', height: '100%', gap: '5px',
                        justifyContent: 'center', alignItems: 'center', position: 'fixed',
                        display: 'flex', flexDirection: 'column',
                    }}>
                        <Box sx={{
                            width: { md: '375px', sm: '380px', xs: '85%' }, height: '36px',
                            justifyContent: 'right', alignItems: 'right', display: 'flex',
                        }}>
                            <IconButton onClick={handleCancelOTPPopup} sx={{
                                width: '36px', height: '36px',
                            }} >
                                <CloseOutlinedIcon sx={{
                                    width: '36px', height: '36px', color: '#C5C5C5', '&:hover': {
                                        width: '40px', height: '40px', color: '#C5C5C5', backgroundColor: 'none',
                                        background: 'none'
                                    },
                                }} >
                                </CloseOutlinedIcon>
                            </IconButton>
                        </Box>
                        <Box
                            sx={{

                                width: { md: '375px', sm: '380px', xs: '85%' },
                                height: 'fit-content',
                                backgroundColor: '#fff',
                                border: '1px solid #E6E6E6',
                                borderRadius: '4px ',
                                boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                                boxSizing: 'border-box',
                                zIndex: 3,
                                padding: '35px',
                                alignItems: 'center',
                                overflow: 'auto',
                                '&::-webkit-scrollbar': {
                                    width: '12px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#7f7f7f',
                                    borderRadius: '28px',
                                },
                                '&::-webkit-scrollbar-track': {
                                    backgroundColor: '#e0e0e0',
                                    borderRadius: '28px',
                                },
                            }}
                        >

                            <OTPInput phoneNumber={newData.phone_number} countryCode={newData.country_code} setIsOtpSuccess={setIsOtpSuccess}></OTPInput>

                        </Box>
                    </Box>
                </Box>)}

            {isPassword && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 2,
                    }}
                >
                    <Box sx={{
                        width: '100%', height: '100%', gap: '5px',
                        justifyContent: 'center', alignItems: 'center', position: 'fixed',
                        display: 'flex', flexDirection: 'column',
                    }}>
                        <Box sx={{
                            width: { md: '375px', sm: '380px', xs: '85%' }, height: '36px',
                            justifyContent: 'right', alignItems: 'right', display: 'flex',
                        }}>
                            <IconButton onClick={handleCancelOTPPopup} sx={{
                                width: '36px', height: '36px',
                            }} >
                                <CloseOutlinedIcon sx={{
                                    width: '36px', height: '36px', color: '#C5C5C5', '&:hover': {
                                        width: '40px', height: '40px', color: '#C5C5C5', backgroundColor: 'none',
                                        background: 'none'
                                    },
                                }} >
                                </CloseOutlinedIcon>
                            </IconButton>
                        </Box>
                        <Box
                            sx={{

                                width: { md: '375px', sm: '380px', xs: '85%' },
                                height: 'fit-content',
                                backgroundColor: '#fff',
                                border: '1px solid #E6E6E6',
                                borderRadius: '4px ',
                                boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                                boxSizing: 'border-box',
                                zIndex: 3,
                                padding: '35px',
                                alignItems: 'center',
                                overflow: 'auto',
                                '&::-webkit-scrollbar': {
                                    width: '12px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#7f7f7f',
                                    borderRadius: '28px',
                                },
                                '&::-webkit-scrollbar-track': {
                                    backgroundColor: '#e0e0e0',
                                    borderRadius: '28px',
                                },
                            }}
                        >

                            <Box sx={{
                                position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '2',

                            }}>
                                <Box sx={{
                                    width: '100%', height: '100%', gap: '5px',
                                    justifyContent: 'center', alignItems: 'center', position: 'fixed',
                                    display: 'flex', flexDirection: 'column',
                                }}>
                                    <Box sx={{
                                        width: { md: '30%', sm: '30%', xs: '85%' }, height: '36px',
                                        justifyContent: 'right', alignItems: 'right', display: 'flex',
                                    }}>
                                        <IconButton onClick={handleCloseChangePasswordPopUp} sx={{
                                            position: 'fixed', width: '36px', height: '36px',
                                        }} >
                                            <CloseOutlinedIcon sx={{
                                                width: '36px', height: '36px', color: '#C5C5C5', '&:hover': {
                                                    width: '40px', height: '40px', color: '#C5C5C5', backgroundColor: 'none',
                                                    background: 'none'
                                                },
                                            }} ></CloseOutlinedIcon>
                                        </IconButton>
                                    </Box>
                                    <Box sx={{
                                        width: { md: '30%', sm: '30%', xs: '85%' }, height: 'fit-content',
                                        backgroundColor: 'white', border: '1px solid',
                                        borderColor: 'white', borderRadius: '4px ', boxShadow: 'none',
                                        zIndex: '3', alignItems: 'center',
                                    }}>


                                        <Box sx={{
                                            display: 'flex', flexDirection: 'row', width: '100%',
                                            justifyContent: 'center', alignContent: 'center', height: '100%'
                                        }}>
                                            <Box sx={{
                                                display: 'flex', flexDirection: 'column', width: '100%', gap: '8px',
                                                justifyContent: 'center', height: '100%', alignItems: 'center',
                                            }}>
                                                <Box sx={{
                                                    display: 'flex', flexDirection: 'column', width: '100%', gap: '8px', paddingTop: '10%',
                                                    height: '100%', justifyContent: 'center', maxWidth: '88%', alignItems: 'center', paddingBottom: '10%'
                                                }}>

                                                    <Box sx={{
                                                        gap: '8px', display: 'flex', flexDirection: 'column', justifyContent: 'center',
                                                        alignItems: 'left', width: '100%'
                                                    }}>
                                                        <TypographyBlue sx={{ fontSize: '24px', color: '#313131', }}>Create new  password</TypographyBlue>
                                                        <TypographyBlue >Account created successfully! Please set your password to finish setting up your account and secure it.</TypographyBlue>
                                                    </Box>
                                                    <Box sx={{ gap: { xs: '8px', md: '16px' }, display: 'flex', flexDirection: 'column', paddingTop: { xs: '8px', md: '16px' }, width: '100%' }}>


                                                        <Box sx={{ gap: '8px', width: '100%', flexDirection: 'column', }}>
                                                            <TypographyBlue sx={{ fontSize: { xs: '10px', md: '14px' }, color: '#313131', }}>New password*</TypographyBlue>
                                                            <TextfieldNormal placeholder="Enter your password"
                                                                type={showNewPassword ? 'text' : 'password'}
                                                                value={newPassword}
                                                                onChange={handleNewPasswordChange} error={!!newPasswordError}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                onClick={toggleNewPasswordVisibility}
                                                                                edge="end"
                                                                                aria-label="toggle password visibility"
                                                                                color="primary">
                                                                                {showNewPassword ? (
                                                                                    <VisibilityOutlinedIcon sx={{ color: '#C5C5C5', fontSize: { xs: '20px', md: '24px' } }} />
                                                                                ) : (
                                                                                    <VisibilityOffOutlinedIcon sx={{ color: '#C5C5C5', fontSize: { xs: '20px', md: '24px' } }} />
                                                                                )}
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}></TextfieldNormal>
                                                            {newPasswordError && <TypographyError sx={{ paddingTop: { xs: '4px', md: '8px' } }}>{newPasswordError}</TypographyError>}
                                                        </Box>

                                                        <Box sx={{ gap: '8px', width: '100%', flexDirection: 'column', }}>
                                                            <TypographyBlue sx={{ fontSize: { xs: '10px', md: '14px' }, color: '#313131', }}>Confirm password*</TypographyBlue>
                                                            <TextfieldNormal placeholder="Enter your password"
                                                                type={showConfirmPassword ? 'text' : 'password'}
                                                                value={confirmPassword}
                                                                onChange={handleConfirmPasswordChange} error={!!confirmPasswordError}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                onClick={toggleConfirmPasswordVisibility}
                                                                                edge="end"
                                                                                aria-label="toggle password visibility"
                                                                                color="primary">
                                                                                {showConfirmPassword ? (
                                                                                    <VisibilityOutlinedIcon sx={{ color: '#C5C5C5', fontSize: { xs: '20px', md: '24px' } }} />
                                                                                ) : (
                                                                                    <VisibilityOffOutlinedIcon sx={{ color: '#C5C5C5', fontSize: { xs: '20px', md: '24px' } }} />
                                                                                )}
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}></TextfieldNormal>
                                                            {confirmPasswordError && <TypographyError sx={{ paddingTop: { xs: '4px', md: '8px' } }}>{confirmPasswordError}</TypographyError>}
                                                        </Box>



                                                        {forgotError && <TypographyError>{forgotError}</TypographyError>}
                                                        <Box sx={{
                                                            width: '100%', flexDirection: 'column', justifyContent: 'center', display: 'flex',
                                                            alignItems: 'center', alignContent: 'center', gap: '8px', paddingTop: '12px'
                                                        }}>
                                                            <ButtonBlue onClick={handleSaveButtonClick}>Save</ButtonBlue>

                                                        </Box>


                                                    </Box>

                                                </Box>
                                            </Box>
                                        </Box>

                                    </Box>
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                </Box>)}



        </Box>
    );
}

export default ImageCaptureModal;
