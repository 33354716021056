import React from 'react';
import { Navigate } from 'react-router-dom';

const PromotionalProtectedRoute = ({ element, ...rest }) => {
  const promotionalToken = localStorage.getItem('promotionalToken');

  return promotionalToken ? (
    element
  ) : (
    <Navigate to="/promotional-login" replace />
  );
};

export default PromotionalProtectedRoute;
