

import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import { useState, useEffect, useRef } from 'react';
import Colors from '../Components/Colors';
import TypographyNormal from '../Components/TypographyNormal';
import axios from 'axios';
import { API_URL } from '../Components/Constants';
import logo from '../Images/logo.png'
import { Avatar, Grid } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ButtonWhite from './ButtonWhite';
import ButtonBlue from './ButtonBlue';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';


function PrintersAssociateHeader() {

  const [showDropdown, setShowDropdown] = useState(false);
  const [isSignOutPopUp, setIsSignOutPopUp] = useState(false);
  const [assossiateName, setAssossiateName] = useState("");
  const [searchGroupChar, setSearchGroupChar] = useState('');
  const [assossiates, setAssossiates] = useState([]);

  const colors = ['#503259', '#FF7D6A', '#B07CC6', '#20C925', '#4C50AE'];

  function getAvatarColor(index) {
    return colors[index % colors.length];
  }

  function stringAvatar(name = '', index) {
    const initials = name.length > 4 ? name.substring(0, 3) : name || '';
    const bgColor = getAvatarColor(index);
    return {
      sx: {
        bgcolor: bgColor,
        width: '64px',
        height: '64px',
        fontSize: '56px',
        color: '#fff',
      },
      children: initials,
    };
  }

  const handleSignOutMenuItemClick = (value) => {
    if (value === "signout") {
      setIsSignOutPopUp(true);
    }
    setShowDropdown(false);
  }


  const handleSignOutPopUpOkButtonClick = async () => {
    try {
      const response = await axios.post(`${API_URL}/status_remove_pa`,
        {
          paTocken: localStorage.getItem("printersAssociateToken"),
        });
      console.log(response.data.success)
      if (response.data.success === true) {
        localStorage.removeItem('printersAssociateToken');
        window.location.href = '/printers-associate-login'
      }

    }
    catch (error) {
      console.error("Request failed:", error);
    }
  };

  const handleSignOutPopUpCancelButtonClick = () => {
    setIsSignOutPopUp(false);

  };

  const profilePicRef = useRef(null);
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      profilePicRef.current &&
      !profilePicRef.current.contains(event.target)
    ) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropdown]);


  const fetchAssossiateDetails = async () => {
    const paTocken = localStorage.getItem("printersAssociateToken")
    try {
      const response = await axios.post(`${API_URL}/select_pa_details_with_token`,
        { paTocken: localStorage.getItem("printersAssociateToken") });
      console.log(response.data.result)
      setAssossiateName(response.data.result.name);

    } catch (error) {
      console.error('Error fetching service categories:', error);
    }
  };

  useEffect(() => {
    fetchAssossiateDetails();

  }, []);

  useEffect(() => {

    const fetchOnlineAssociates = async () => {
      try {
        const response = await axios.get(`${API_URL}/select_all_pa`);
        console.log(response.data.result)
        setAssossiates(response.data.result); // Set the fetched data in state

      } catch (error) {
        console.error('Error fetching online associates:', error);

      }
    };

    // Fetch online associates every second (1000ms)
    const intervalId = setInterval(() => {
      fetchOnlineAssociates();
    }, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);


  let timeout;

  // Function to mark the associate as "offline"
  const setStatusOffline = async () => {
    try {
      await axios.post(`${API_URL}/status_remove_pa`, { paTocken: localStorage.getItem("printersAssociateToken"), });
      console.log("User set to offline");

    } catch (error) {
      console.error('Failed to update status to offline:', error);
    }
  };

  // Function to mark the associate as "online"
  const setStatusOnline = async () => {
    try {
      await axios.post(`${API_URL}/status_update_pa`, { paTocken: localStorage.getItem("printersAssociateToken"), });
      console.log("User set to online");
    }
    catch (error) {
      console.error("Request failed:", error);
    }
  };

  // Function to reset the inactivity timer
  const resetInactivityTimeout = () => {
    clearTimeout(timeout);
    timeout = setTimeout(setStatusOffline, 10 * 60 * 1000); // 10 minutes of inactivity
  };
  return (
    <Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px', }}>
      <AppBar position="static" sx={{
        height: { xs: '48px', md: '86px' },
        justifyContent: 'center',
        boxShadow: 'none',
        background: 'white',
        borderBottom: '1px solid',
        borderColor: Colors.grey_30
      }}>
        <Container maxWidth="100%" sx={{
          marginLeft: '0px',
          height: { xs: '48px', md: '86px' },
          paddingX: { xs: '8px', md: '24px' }
        }}>
          <Toolbar disableGutters sx={{
            height: '100%',
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: { xs: '10px', md: '54px' }
          }}>
            <Box sx={{
              width: { xs: '100%', md: '70%' },
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              alignItems: { xs: 'center', md: 'flex-start' }
            }}>
              <Box sx={{
                display: "flex",
                width: { xs: '85%', md: '90%' },
                flexDirection: 'row',
                justifyContent: 'left',
                alignItems: 'center',
                gap: { xs: '8px', md: '12px' },
              }}>
                <img src={logo} alt="Logo" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
              </Box>
            </Box>
            {/* <Box sx={{
                        width: '25%', display: 'flex' , height: '100%', justifyContent: 'center',
                        alignItems: 'center',flexDirection:'column', gap:'8px', 
                    }}>
                        

             </Box> */}

            {/* { assossiates &&
              <Box display="flex" gap="8px">
              {assossiates.map((associate, index) => (               
            <Avatar 
                alt="associate" 
                sx={{
                    ...stringAvatar(associate.associate_name, index).sx,
                    width: '32px', height: '32px', fontSize: '12px',}}>
                {stringAvatar(associate.associate_name, index).children}
            </Avatar>
              ))}
            </Box>} */}

            <Box sx={{
              display: "flex", width: { xs: '15%', md: '10' }, flexDirection: 'row',
              justifyContent: 'center', alignItems: 'center', gap: '12px',
            }}>
              <TypographyNormal onClick={() => setShowDropdown(!showDropdown)} sx={{ fontSize: '14px', color: Colors.black_80, }}>
                {assossiateName}
              </TypographyNormal>

              {showDropdown ? (
                < KeyboardArrowUpIcon
                  sx={{ display: 'flex', width: '24px', color: Colors.black_80 }}
                  onClick={() => setShowDropdown(!showDropdown)}

                />
              ) : (
                <KeyboardArrowDownIcon
                  sx={{ display: 'flex', width: '24px', color: Colors.black_80 }}
                  onClick={() => setShowDropdown(!showDropdown)}
                />
              )}


            </Box>

            {showDropdown && (

              <Box ref={dropdownRef} sx={{
                position: 'absolute',
                right: { xs: '50%', md: '0px' },
                top: { xs: '100px', md: '65px' },
                transform: { xs: 'translateX(50%)', md: 'none' },
                width: '170px',
                background: Colors.white,
                borderRadius: '8px',
                boxShadow: '0px 4px 4px 0px #00000040',
                zIndex: 1,
                padding: '8px',
                border: '1px solid',
                borderColor: Colors.grey_30
              }}>

                <style>
                  {`
     .dropdown__menu .MuiMenuItem-root:hover {
       background-color: #415FE2;
       color: #FFFFFF !important;
     }
     .dropdown-menu__triangle {
      position: absolute;
      top: -10px;
      right: 10px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid white;
      border-top: 10px solid ${Colors.grey_30};
      box-shadow: 0px 4px 4px 0px #00000040;
    }
   
   `}
                </style>

                <MenuItem onClick={() => handleSignOutMenuItemClick("signout")} value="option2" style={{
                  fontFamily: 'Roboto', fontSize: '16px', color: Colors.black_80,
                  fontWeight: '400', lineHeight: '20px', minHeight: '20px', borderRadius: '4px'
                }}>
                  Sign out
                </MenuItem>
              </Box>

            )}
            {isSignOutPopUp &&
              <Box sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '2',

              }}
              >
                <Box sx={{
                  width: '100%', height: '100%', gap: '5px',
                  justifyContent: 'center', alignItems: 'center', position: 'fixed',
                  display: 'flex', flexDirection: 'column',
                }}>
                  <Box sx={{
                    width: { md: '375px', sm: '380px', xs: '85%' }, height: '36px',
                    justifyContent: 'right', alignItems: 'right', display: 'flex',
                  }}>
                    <IconButton onClick={handleSignOutPopUpCancelButtonClick} sx={{
                      width: '36px', height: '36px',

                    }} >
                      <CloseOutlinedIcon sx={{
                        width: '36px', height: '36px', color: Colors.black_60, '&:hover': {
                          width: '40px', height: '40px', color: Colors.black_60, backgroundColor: 'none',
                          background: 'none'
                        },
                      }} ></CloseOutlinedIcon>
                    </IconButton>
                  </Box>
                  <Box
                    sx={{

                      width: { md: '375px', sm: '380px', xs: '85%' },
                      height: 'fit-content',
                      backgroundColor: '#FFFFFF',
                      border: '1px solid #FFFFFF',
                      borderRadius: '4px ',
                      boxShadow: 'none',
                      boxSizing: 'border-box',
                      zIndex: 999,
                      padding: '35px',
                      alignItems: 'center',

                    }}
                  >

                    <Box sx={{
                      display: 'flex', flexDirection: 'column', width: '100%', gap: '16px', alignItems: 'center',
                      justifyContent: 'center', alignContent: 'center', height: '100%'
                    }}>
                      <Box sx={{
                        display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                        alignContent: 'center', width: '100%', gap: '16px'
                      }}>
                        <TypographyNormal sx={{ fontSize: '18px', color: '#000000', fontWeight: 600 }}>
                          Sign out</TypographyNormal>

                        <TypographyNormal sx={{ fontSize: '16px', color: Colors.black_70 }}>
                          Are you sure you would like to sign out your account?</TypographyNormal>

                      </Box>


                      <Box sx={{
                        display: 'flex', flexDirection: 'row', gap: '16px', marginTop: '16px', justifyContent: 'center',
                        alignContent: 'center', alignItems: 'center', width: '100%'
                      }}>
                        <ButtonWhite onClick={handleSignOutPopUpCancelButtonClick}>  Cancel </ButtonWhite>
                        <ButtonBlue onClick={handleSignOutPopUpOkButtonClick}> Sign out </ButtonBlue>


                      </Box>


                    </Box>
                  </Box>
                </Box>
              </Box>}

          </Toolbar>
        </Container>
      </AppBar>

    </Grid>
  )
} export default PrintersAssociateHeader